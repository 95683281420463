import i18n from "@/plugins/i18n.js";

const state = () => ({
  user: {
    id: '',
    studentInfo: {},
    role: ''
  },
  language: null
})

const actions = {}

const mutations = {
  setUserId(state, payload) {
    state.user.id = payload
  },
  setUserRole(state, payload) {
    state.user.role = payload
  },
  setStudentInfo(state, payload) {
    state.user.studentInfo = payload
  },
  setLanguageFromSelect(state, payload) {
    state.language = payload
    i18n.locale = payload
    localStorage.setItem('lang', payload)
  },
  setLanguageLoadPage(state) {
    const localStorageLang = localStorage.getItem('lang')
    if (localStorageLang) {
      state.language = localStorageLang
      i18n.locale = localStorageLang
    } else {
      const navigatorLanguage = navigator.languages ? navigator.language.slice(0, 2) : ''

      switch (navigatorLanguage) {
        case 'ru':
          i18n.locale = 'ru'
          state.language = 'ru'
          localStorage.setItem('lang', 'ru')
          break
        case 'tr':
          i18n.locale = 'tr'
          state.language = 'tr'
          localStorage.setItem('lang', 'tr')
          break
        case 'id':
          i18n.locale = 'in'
          state.language = 'in'
          localStorage.setItem('lang', 'in')
          break
        case 'es':
          i18n.locale = 'es'
          state.language = 'es'
          localStorage.setItem('lang', 'es')
          break
        case 'it':
          i18n.locale = 'it'
          state.language = 'it'
          localStorage.setItem('lang', 'it')
          break
        case 'pl':
          i18n.locale = 'pl'
          state.language = 'pl'
          localStorage.setItem('lang', 'pl')
          break
        default:
          i18n.locale = 'en'
          state.language = 'en'
          localStorage.setItem('lang', 'en')
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
};