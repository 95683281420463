<template>
    <div class="task-link-container">
      <div class="task-link">
        <header class="task-link-header">
          <span class="tag task-link__tag">{{kind}}</span>
          <div class="task-link-score">
            <inline-svg class="task-link-score__diamond" :src="require('@/assets/svg/diamond.svg')"/>
            <span>{{minGrade}}/{{maxGrade}}</span>
          </div>
          <span class="task-link-deadline">{{$t('pageTask.deadline.deadlineFirstWord')}} <span class="task-link-deadline__date">{{$t('pageTask.deadline.deadlineLastWord')}} 21.09.2019 00:00</span></span>
        </header>
        <div class="task-link-content">
          <inline-svg class="task-link__robot" :src="require('@/assets/svg/robot-task-link.svg')"/>
          <div class="task-link-description">
            <span class="task-link__name">{{title}}</span>
            <span class="task-link__text">{{text}}</span>
            <a href="#" class="btn btn--secondary btn--fullwidth btn--small">{{this.$t('pageTask.windowTask.link.button')}}</a>
          </div>
        </div>
        <TaskLike
         :taskId="taskId"
         :borderRadius="true"
        />
      </div>
    </div>
</template>

<script>
import TaskLike from '@/components/Task/TaskLike'

export default {
  name: 'TaskLink',
  components: {
    TaskLike
  },
  props: {
    taskId: {
      type: Number
    },
    kind: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    maxGrade: {
      type: Number
    },
    minGrade: {
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.task-link {
  background: #FFFFFF;
  border-radius: 8px;
  width: 624px;
  &-container {
    background-image: url('/images/bg-task-link.png'), linear-gradient(81.18deg, #1931A6 0.99%, #4638ED 111.07%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 30px;
    border-bottom: 1px solid #d9d9d9;
  }
  &__tag {
    margin-right: 16px;
  }
  &-score {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "InterSemiBold";
    margin-right: 16px;
    color: #4C5261;
    &__diamond {
      margin-right: 4px;
      width: 11px;
    }
  }
  &-deadline {
    color: #4C5261;
    font-size: 14px;
    font-family: "InterMedium";
    &__date {
      font-family: "InterSemiBold";
      color: $primary;
    }
  }
  &-content {
    padding: 32px;
    display: grid;
    grid-template-columns: 243px 1fr;
    column-gap: 34px;
  }
  &__name {
    display: block;
    color: $primary;
    margin-bottom: 16px;
    font-family: "JetBrainsMonoMedium";
    font-size: 32px;
  }
  &__text {
    display: block;
    margin-bottom: 24px;
  }
}
</style>