<template>
  <div class="settings">
    <span class="settings-title">{{$t('createTask.modal.title')}}</span>
    <div class="settings-edit">
      <span class=" settings-subtitle">{{$t('createTask.modal.name')}}</span>
      <input class="settings-edit_input" v-model="taskName" :placeholder="$t('createTask.modal.placeholders.name')" type="text">
    </div>
    <div class="settings-edit">
      <span class=" settings-subtitle">{{$t('createTask.modal.number')}}</span>
      <input class="settings-edit_input" v-model="numberOfTask" :placeholder="$t('createTask.modal.placeholders.number')" type="number">
    </div>
    <div class="settings-edit">
      <span class=" settings-subtitle">{{$t('createTask.modal.type')}}</span>
      <div class="settings-edit_type">
        <label class="radio-container settings-edit_radio" @click="choiseTypeTask('Theoretical')">
          {{$t('createTask.typeTask.theoretical')}}
          <input type="radio" :checked="typeTask === 'Theoretical'" name="radio">
          <span class="radio-checkmark" />
        </label>
         <label class="radio-container settings-edit_radio" @click="choiseTypeTask('Practice')">{{$t('createTask.typeTask.practical')}}
          <input type="radio" :checked="typeTask === 'Practice'" name="radio">
          <span class="radio-checkmark" />
        </label>
      </div>
    </div>
    <div class="settings-edit settings-score">
       <span class="switch-text">{{$t('createTask.modal.score')}}</span> 
      <label class="switch" >
        <input type="checkbox" @click="toggleGradeSwitch">
        <span class="slider round" />
      </label>
    </div>
    <div class="settings-edit settings-score_input" v-show="showScoreInput" >
    <input class="settings-edit_input" v-model="maxScore" :placeholder="$t('createTask.modal.placeholders.maxScore')" type="number">
    </div>
    <div class="settings-buttons">
      <button @click="saveTaskSettings" class="btn btn--primary settings-button">{{$t('createTask.modal.buttons.save')}}</button>
      <button @click="cancelModalTask" class="btn btn--primary btn--outline settings-button">{{$t('createTask.modal.buttons.cancel')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      taskName: '',
      numberOfTask: null,
      teacherScore: '',
      comment: '',
      maxRateMistake: false,
      minRateMistake: false,
      showScoreInput: false,
      maxScore: '',
      typeTask: false,
    }
  },
  props: {
    taskTitle: {
      type: String,
      required: false,
    },
    taskNumber: {
      type: Number,
      required: false
    },
    taskMaxGrade: {
      type: Number, String,
      required: false
    },
    typeOfTask: {
      type: String,
      required: true,
      default: 'Practice',
    },
    callClearModal: {
      type: Boolean,
      required: false,
    },
  },
  mounted() {
    this.typeTask = this.typeOfTask
    this.taskName = this.taskTitle ?? ''
    this.numberOfTask = this.taskNumber ?? ''
    this.maxScore = this.taskMaxGrade ?? ''
  },
  methods: {
    choiseTypeTask(type) {
      this.typeTask = type
    },
    toggleGradeSwitch(){
      this.showScoreInput = !this.showScoreInput
      this.$emit('toggleGradeSwitch', this.showScoreInput)
    },
    saveTaskSettings() {
      this.$emit('saveTaskSettings', {
        maxScore: this.maxScore, 
        typeTask: this.typeTask,
        taskName: this.taskName,
        taskNumber: this.numberOfTask
      })
    },
    cancelModalTask() {
      this.$emit('cancelModalTask')
    },
  },  
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.settings{
  &-title{
    font-family: "InterBold";
    display: block;
    font-size: 24px;
    margin-bottom: 8px;
  }
  &-name{
    color: #3860ED;
  }
  &-subtitle{
    font-family: "InterBold";
  }
  &-edit{
    margin: 24px 0;
    display: flex;
    flex-direction: column;

    & > span,input{
      margin-bottom: 8px;
    }
    &-subtitle {
      margin-bottom: 8px;
    }
    &_input {
      padding: 8px;
      border: 1px solid #D9D9D9;
      border-radius: 8px;
      height: 33px;
      outline: none;
      font-size: 14px;
      opacity: 0.5;
      &:focus {
        border: 1px solid $primary;
      }
    }
    &_type{
      display: flex;
      @media(max-width: 400px) {
        flex-direction: column;
      }
    }
    &_radio{
      &:first-child{
        margin-right: 20px;
      }
    }
  }
  &-score{
    flex-direction: row;
  }
  &-comment{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &_subtitle{
      margin-bottom: 9px;
    }
    &_area{
      resize: none;
      min-height: 182px;
      padding: 8px;
      outline: none;
      font-size: 14px;
      opacity: 0.5;
      &::placeholder {
        font-family: "InterRegular";
        font-size: 14px;
      }
      &:focus {
        border: 1px solid $primary;
      }
    }
  }
  &-buttons{
    display: flex;
  }
  &-button{
    width: 112px;
    &:first-child{
      margin-right: 16px;
    }
  }
}
.mb-none{
  margin-bottom: 0!important;
}
  @media(max-width: 767px) {
    .settings{
      &-title{
        font-size: 18px;
      }
      &-comment_area{
        min-height: 90px;
        height: 105px;
      }
    }
  }
</style>