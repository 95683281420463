import { Notifications } from "@/services/HttpService";
export default {
  async getUnreadCount(userId) {
      const response = await Notifications.get("/notifications_count", {
        params: {
          student_id: userId,
        },
      });
      return response.data.count;
  },

  async getNotifications(userId, page) {
      const response = await Notifications.get("/notifications", {
        params: {
          student_id: userId,
          page,
        },
      });
      return response.data.notifications;
  },

  async setNotificationRead(notificationId) {
      await Notifications.patch(`/notifications/${notificationId}`);
    return true;
  },

 async setNotification(studentId, taskId) {
    try {
      await Notifications.post(`/notifications?student_id=${studentId}`, {
        notification_type: "check_task",
        task_id: taskId
      });
    } catch (err) {
      console.log(err.response);
    }
  },
};

