import { HTTP } from "@/services/HttpService"
import store from '../store'
export default {
  getUserId() {
    return store.state.user.user.id
  },
  async getLessonsTasks(lessonId) {
    try {
      const response = await HTTP.get(`/lessons/${lessonId}/tasks/`)
      return response.data
    } catch (err) {
      console.log(err.response);
    }
  },
  // async getStudentProgress(taskId) {
  //   try {
  //     const response = await HTTP.get(`/progress/`, {params: {
  //       user_id: this.getUserId(),
  //       task_id: taskId
  //     }
  //     })
  //     return response.data
  //   } catch (err) {
  //       console.log(err);
  //   }
  // },
  async getLessonFeedback(lessonId) {
    try {
      await HTTP.get(`/lesson_feedbacks/?lesson=${lessonId}&user=${this.getUserId()}`)
    } catch (err) {
      if (err.response.status === 404) {
        return err.response.status
      }
      console.log(err.response);
    }
  },
  async postLessonFeefback(params) {
    try {
      await HTTP.post('/lesson_feedbacks/', params)
    } catch (err) {
      console.log(err.response);
    }
  }
}