<template>
  <div class="breadcrumbs" v-click-outside="closeDropdown">
    <template v-if="breadcrumbsLocal.length">
      <div v-for="(element, i) in breadcrumbsLocal" :key="i" class="breadcrumbs-element">
        <div v-if="element.type === 'dropdown' && element.list.length">
          <div class="breadcrumbs-steps">
            <div class="breadcrumbs-steps-selected" @click="toggleOpenDropdown(element.id)">
              {{element.list.find(el => el.current).name}}
              <inline-svg
                :class="['breadcrumbs-steps-selected__arrow',
                {'breadcrumbs-steps-selected__arrow--open': element.open}]"
                :src="require('@/assets/svg/breadcrumb-arrow.svg')"/>
            </div>
            <div class="breadcrumbs-steps-list" v-if="element.open">
              <span
                v-for="(elementDropdown, elementDropdownIndex) in element.list"
                :key="elementDropdown.id">
                <a
                  v-if="elementDropdown.staticLink"
                  :class="['breadcrumbs-steps-list__element',
                  {'breadcrumbs-steps-list__element--selected': elementDropdown.current}]"
                  :href="elementDropdown.link">{{elementDropdown.name}}</a>
                <span
                  v-else
                  :class="['breadcrumbs-steps-list__element',
                  {'breadcrumbs-steps-list__element--selected': elementDropdown.current}]"
                  @click="goToStep(element.id, elementDropdown.id, elementDropdown.number, elementDropdownIndex)">{{elementDropdown.name}}</span>
              </span>
            </div>
          </div>
        </div>
        <div v-if="element.type === 'link'" class="breadcrumbs-element-flex">
          <router-link
            class="breadcrumbs-element__link"
            :to="element.link">{{element.name}}</router-link>
          <inline-svg
            class="breadcrumbs-element__arrow"
            :src="require('@/assets/svg/breadcrumb-arrow.svg')"/>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="link of teacherBreadcrumbs" :key="link.title">
        <a
          :class="['breadcrumbs-teacher',
          {'breadcrumbs-teacher--active': !link.url}]"
          :href="link.url ? `https://backoffice.kodland.org${link.url}` : '#'">
          {{link.title}}
        </a> 
        <span v-if="link.url" class="breadcrumbs-line">/</span>
      </div>
    </template>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  name: 'breadcrumbs',
  directives: {
    ClickOutside
  },
  props: {
    paginatedTask: Number,
    breadcrumbs: {
      type: Array,
      required: true,
      default: () => []
    },
    teacherBreadcrumbs: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  data() {
    return {
      breadcrumbsLocal: []
    }
  },
  methods: {
    breadcrumbsName(elem) {
    elem.list.find(el => el.current).name
    },
    toggleOpenDropdown(id) {
      this.breadcrumbsLocal.forEach(element => {
        if (element.open && element.id !== id) {
          element.open = false
        }
      })
      const index = this.breadcrumbsLocal.findIndex(el => el.id === id)
      this.breadcrumbsLocal[index].open = !this.breadcrumbsLocal[index].open
    },
    closeDropdown() {
      this.breadcrumbsLocal.forEach(element => {
        if (element.open) {
          element.open = false
        }
      })
    },
    goToStep(elementId, dropdownId, elementNumber, elementDropdownIndex) {
      this.$emit('goToStep', {
          id: elementId,
          dropdownId:dropdownId,
          number: elementNumber,
          pagintedNumber: ++elementDropdownIndex
        }
      )
      this.breadcrumbsLocal.forEach(element => {
        if (element.id === elementId) {
          element.list.forEach(dropdown => {
            dropdown.current = false
            if (dropdown.id === dropdownId) {
              dropdown.current = true
              this.$router.push(dropdown.link)
              this.closeDropdown()
            }
          })
          return
        }
      })
    }
  },
  watch: {
    breadcrumbs: function(value) {
      this.breadcrumbsLocal = [...value]
    }
  },
  mounted() {
    this.breadcrumbsLocal = [...this.breadcrumbs]
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  z-index: 50;
  &-line{
    margin: 0 1px;
  }
  &-teacher {
    color: #2E2C2C;
    &:hover {
      text-decoration: underline;
    }
    &--active {
      color: #3860ED
    }
  }
  @media(max-width: 1023px) {
    display: none;
  }
  &-element {
    margin-right: 30px;
    font-size: 12px;
    font-family: "JetBrainsMonoMedium";
    display: flex;
    align-items: center;
    &:last-child {
      .breadcrumbs-element__arrow {
        display: none;
      }
    }
    &-flex {
      display: flex;
      align-items: center;
    }
    &__link {
      color: #2E2C2C;
      position: relative;
      &:hover {
        text-decoration: underline;
      }
    }
    &__arrow {
      margin-left: 12px;
    }
  }
  &-steps {
    position: relative;
    user-select: none;
    &-selected {
      color: #2E2C2C;
      background: #F7F7F7;
      border-radius: 8px;
      font-size: 12px;
      padding: 8px 13px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &__arrow {
        margin-left: 12px;
        transition: 0.2s;
        &--open {
          transform: rotate(90deg);
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &-list {
      position: absolute;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 7px 0;
      top: 40px;
      &__element {
        display: block;
        white-space: nowrap;
        cursor: pointer;
        font-size: 12px;
        padding: 5px 13px;
        color: #2E2C2C;
        &--selected {
          background: rgba(56, 96, 237, .1);;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>