<template>
  <div class="lang">
    <div class="lang-selected" v-click-outside="hideSelectedLang">
      <span class="lang-selected__name" @click="toggleSelectedLang">
        {{selectedLang}}
        <inline-svg class="lang-selected__icon" :src="require('@/assets/svg/blue-arrow-bottom.svg')"/>
      </span>
      <div class="lang-list" v-if="openSelectLang" >
        <span
          :class="['lang-list-element', {'lang-list-element--active': lang.selected}]"
          @click="selectLang(lang)"
          v-for="lang in langs"
          :key="lang.value">
          {{lang.name}}
          <inline-svg v-if="lang.selected" class="lang-list-element__icon" :src="require('@/assets/svg/lang-selected.svg')"/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'SelectLang',
  directives: {
    ClickOutside
  },
  data() {
    return {
      openSelectLang: false,
      selectedLang: '',
      langs: [
        {
          value: 'ru',
          name: 'Русский',
          selected: true
        },
        {
          value: 'en',
          name: 'English',
          selected: false
        },
        {
          value: 'tr',
          name: 'Türk',
        },
        {
          value: 'es',
          name: 'Español'
        },
        {
          value: 'in',
          name: 'Indonesian',
        },
        {
          value: 'it',
          name: 'Italian',
        },
        {
          value: 'pl',
          name: 'Polski',
        }
      ]
    }
  },
  methods: {
    toggleSelectedLang() {
      this.openSelectLang = !this.openSelectLang;
    },
    hideSelectedLang() {
      setTimeout(() => {
        this.openSelectLang = false;
      }, 100);
      
    },
    changeLang(language) {
      this.langs.forEach(element => {
        if (element.value === language) {
          element.selected = true;
          this.selectedLang = element.name;
        } else {
          element.selected = false;
        }
      });
      this.openSelectLang = false
    },
    selectLang(lang) {
      this.changeLang(lang.value);
      this.$store.commit('user/setLanguageFromSelect', lang.value);
    },
  },
  created() {
    const lng = window.navigator.language || navigator.userLanguage;
    if (!lng.includes('ru') || !lng) {
      this.langs.splice(0, 1)
    }
  },
  mounted() {
    this.changeLang(this.$store.state.user.language)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.lang {
  position: relative;
  z-index: 1;
  &-selected {
    cursor: pointer;
    font-family: "InterSemiBold";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;
    &__icon {
      margin-left: 7px;
      display: block;
    }
    &__name {
      color: $primary;
      display: flex;
      align-items: center;
      &:hover {
        color: $primaryHover;
      }
    }
  }
  &-list {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 150px;
    padding: 8px 0;
    right: 0;
    top: 30px;
    &-element {
      display: flex;
      height: 40px;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      &:hover {
        color: $primaryHover;
      }
      &--active {
        background: rgba(56, 96, 237, 0.1);
        color: $primary;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
</style>