var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("taskRenderlessComponent",null,{"data":_vm.$data,"model":_vm.model,"computed":{
    isScratch: _vm.isScratch, 
    isBase: _vm.isBase, 
    isCloud: _vm.isCloud, 
    isUnity: _vm.isUnity, 
    isTurtle: _vm.isTurtle, 
    isPygame: _vm.isPygame, 
    isWebsite: _vm.isWebsite, 
    isChat: _vm.isChat, 
    isLink: _vm.isLink, 
    inPreviewMode: _vm.inPreviewMode, 
    inEditMode: _vm.inEditMode, 
    isNotSelectedFile: _vm.isNotSelectedFile, 
    inNotSupportedMode: _vm.inNotSupportedMode,
    showFileExplorer: _vm.showFileExplorer },"methods":{
    forceRateTask: _vm.forceRateTask,
    changeTaskFields: _vm.changeTaskFields,
    saveTaskSettings: _vm.saveTaskSettings,
    toggleGradeSwitch: _vm.toggleGradeSwitch,
    callSettingsModal: _vm.callSettingsModal, 
    cancelModalTask: _vm.cancelModalTask, 
    cancelDelete: _vm.cancelDelete, 
    deleteTask: _vm.deleteTask, 
    callModal: _vm.callModal, 
    callCheckModal: _vm.callCheckModal, 
    checkTask: _vm.checkTask, 
    closeModalTask: _vm.closeModalTask, 
    changeStepBreadcrumbs: _vm.changeStepBreadcrumbs, 
    clearSelectFile: _vm.clearSelectFile, 
    fileExplorerOpenProjectCode: _vm.fileExplorerOpenProjectCode, 
    reviewTask: _vm.reviewTask, 
    updateWidth: _vm.updateWidth,
    updateHeight: _vm.updateHeight, 
    onChangeCode: _vm.onChangeCode, 
    changeDecisionCode: _vm.changeDecisionCode,
    toggleShowTaskCheck: _vm.toggleShowTaskCheck, 
    readySplit: _vm.readySplit, 
    changeTaskNumber: _vm.changeTaskNumber, 
    openRereviewTask: _vm.openRereviewTask, 
    goToStep: _vm.goToStep, 
    openResetTask: _vm.openResetTask,
    closeRereviewTask: _vm.closeRereviewTask, 
    closeResetTask: _vm.closeResetTask, 
    closeModalReview: _vm.closeModalReview, 
    toggleOpenFiles: _vm.toggleOpenFiles, 
    resetProgressTask: _vm.resetProgressTask, 
    fileExplorerSelected: _vm.fileExplorerSelected,
    run: _vm.run, 
    save: _vm.save,
    transferTaskDescription: _vm.transferTaskDescription }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }