import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import calendar from "dayjs/plugin/calendar";
import localizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/ru";

export default class Dates {
  constructor(date) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(calendar);
    dayjs.extend(localizedFormat);
    // dayjs.locale("ru");

    dayjs.extend(updateLocale);

    dayjs.updateLocale("ru", {
      calendar: {
        lastDay: "[вчера в] LT",
        sameDay: "[сегодня в] LT",
        nextDay: "L [в] LT",
        lastWeek: "L [в] LT",
        nextWeek: "L [в] LT",
        sameElse: "L [в] LT"
      }
    });

    this.instance = dayjs.tz(date, "Europe/Moscow");
  }

  getNotificationFormat() {
    return (
      this.instance
        .locale("ru")
        .local()
        .calendar()
    );
  }
}
