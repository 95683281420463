<template>
  <div>
    <TaskGeneral>
      <template #taskRenderlessComponent="taskComponent">
        <Header
          :breadcrumbs="taskComponent.data.breadcrumbs"
          />
        <Loader v-if="taskComponent.data.loader" color="primary" position="center"/>
        <div v-else>
          <modal 
            ref="settingsModal"
            :showFooter="false"
            :size="'medium'"
            :showExpand="false"
            :outsideModalCloseIcon="true"
          >
            <template #content>
              <TaskSettings
                :typeOfTask="taskComponent.data.FieldsOfChangeTask.taskType"
                @cancelModalTask="taskComponent.methods.cancelModalTask"
                @saveTaskSettings="taskComponent.methods.saveTaskSettings"
              />
            </template>
          </modal>
          <modal 
            ref="deleteModal"
            :showFooter="false"
            :size="'small'"
            :showExpand="false"
            :outsideModalCloseIcon="true"
          >
            <template #content>
              <DeleteTask
                @deleteTask="taskComponent.methods.deleteTask"
                @cancelDelete="taskComponent.methods.cancelDelete"
              />
            </template>
          </modal>
          <TaskHeader 
            :isCreate="true"
            :isCreateTaskLink="true"
            :taskKind="'Ссылка'"
            :maxTaskScore="taskComponent.data.FieldsOfChangeTask.maxTaskScore"
            :typeOfTask="taskComponent.data.FieldsOfChangeTask.taskType"
            @callModal="taskComponent.methods.callModal">
            <button class="btn btn--header-small btn--primary task-header-button_settings btn--outline task-header-button"  @click="taskComponent.methods.callModal('settings')">
              <inline-svg class="task-header_icon--decision task-header_settings--icon" :src="require('@/assets/svg/setting.svg')"/>
            </button>
            <button class="btn btn--smal task-header-button_trash task-header-button" @click="taskComponent.methods.callModal('delete')">
              <inline-svg class="task-header_icon--decision task-header_trash--icon" :src="require('@/assets/svg/trash_Icon.svg')"/>
            </button>
            <div class="task-header_save-button">
              <button class="btn btn--primary btn--header-small task-header__handover"  @click="taskComponent.methods.changeTaskFields">
                <span span class="btn__text">{{$t('pageTask.save')}}</span>
                <inline-svg class="btn__mobile-icon" :src="require('@/assets/svg/save.svg')"/>
              </button>
              </div>
            </TaskHeader>
            <div class="create-task">
              <div class="create-task-wrapper">
                <div class="create-task_element">
                  <span class="create-task_element__title">{{$t('createTask.modal.name')}}</span>
                  <input 
                    type="text" 
                    v-model="taskComponent.model.taskData.taskName" 
                    class="input-text create-task_input-text" 
                    :placeholder="$t('createTask.modal.placeholders.name')">
                </div>
                <div class="create-task_element">
                  <span class="create-task_element__title">{{$t('createTask.taskDescription')}}</span>
                  <div class="create-task_text-editor">
                  <CreateTask
                    :key="updateKey"
                    ref="TextEditor"
                    @transferTaskDescription="taskComponent.methods.transferTaskDescription"
                  />
                  </div>
                </div>
                <div class="create-task_element">
                  <span class="create-task_element__title">{{$t('createTask.taskLink.buttonName')}}</span>
                  <input 
                    type="text" 
                    v-model="taskComponent.model.taskData.buttonName" 
                    class="input-text create-task_input-text" 
                    :placeholder="$t('createTask.placeholders.buttonName')">
                </div>
                <div class="create-task_element">
                  <span class="create-task_element__title">{{$t('createTask.taskLink.linkAddress')}}</span>
                  <input 
                    type="text"  
                    v-model="taskComponent.model.taskData.taskAddress" 
                    class="input-text create-task_input-text" 
                    :placeholder="$t('createTask.placeholders.linkAddress')">
                </div>
                <div class="mobile_task-button">
                  <button 
                  class="btn btn--small__square btn--primary task-header-button_settings btn--outline task-header-button"  
                  @click="taskComponent.methods.callModal('settings')">
                    <inline-svg class="task-header_icon--decision task-header_settings--icon" :src="require('@/assets/svg/setting.svg')"/>
                  </button>
                  <button class="btn btn--small__square task-header-button_trash task-header-button" @click="taskComponent.methods.callModal('delete')">
                    <inline-svg class="task-header_icon--decision task-header_trash--icon" :src="require('@/assets/svg/trash_Icon.svg')"/>
                  </button>
                    <button class="btn btn--primary btn--small task-header__save full-width"  @click="taskComponent.methods.changeTaskFields">
                      <span span class="btn__text">{{$t('pageTask.save')}}</span>
                    </button>
                </div>
              </div>
            </div>
        </div>
      </template>
    </TaskGeneral>
    </div>
</template>


<script>
import Loader from '@/components/Loader'
import Header from '@/components/Header/Header'
import TaskGeneral from '@/components/Task/TaskGeneral'
import TaskHeader from '@/components/Task/TaskHeader'
import Modal from '@/components/Modal'
import TaskSettings from '@/components/Task/TaskSettings'
import DeleteTask from '@/components/Task/TaskDelete'
import CreateTask from '@/components/Task/TaskCreate'
export default {
  name: 'Home',
  components: {
    Header,
    Loader,
    TaskHeader,
    Modal,
    TaskSettings,
    DeleteTask,
    CreateTask,
    TaskGeneral
  },
  computed: {
    lang() {
      return this.$store.state.user.language
    },
  },
  methods: {
    forceRerender() {
      this.updateKey++;
    }   
  },
  watch: {
    lang() {
      this.forceRerender()
    }
  }
}
</script>

<style lang="scss" scoped>

.create-task{
  padding: 32px 0;
  display: flex;
  justify-content: center;
  &-wrapper {
    margin: 0 16px;
  }
  @media(max-width: 560px) {
    margin: 0 20px;
    display: block;
  }
  &_text-editor {
    height: 170px;
    margin-top: 15px;
  }
  &_element{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 654px;
    width: 100%;
    &__title{
      color: #141F43;
      font-family: "JetBrainsMonoExtraBold";
      font-size: 20px;
    }
  }
  &_input-text{
    padding: 0;
  }
}
.mobile_task-button {
  display: none;
    @media(max-width: 900px) {
      display: flex;
    }
  margin-top: 100px;
  position: relative;
  &::before{
    position: absolute;
    content: '';
    top: -16px;
    width: 100%;
    height: 1px;
    background: #D9D9D9;
  }
}
</style>