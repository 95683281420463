<template>
  <div>
    <TaskGeneral>
    <!-- Принимаю все данные и методы из дочернего компонента  TaskGeneral и собираю их в один пропс : "taskComponent" -->
      <template #taskRenderlessComponent="taskComponent">
        <Header
          :breadcrumbs="taskComponent.data.breadcrumbs"
          :teacherBreadcrumbs="taskComponent.data.teacherBreadcrumbs"
          />
        <Loader v-if="taskComponent.data.loader" color="primary" position="center"/>
        <div v-else>
          <modal 
            ref="settingsModal"
            :showFooter="false"
            :size="'medium'"
            :showExpand="false"
            :outsideModalCloseIcon="true">
            <template #content>
              <TaskSettings
                :taskTitle="taskComponent.data.task.title"
                :taskNumber="taskComponent.data.task.number"
                :taskMaxGrade="taskComponent.data.task.max_grade || null"
                :typeOfTask="taskComponent.data.FieldsOfChangeTask.taskType"
                @saveTaskSettings="taskComponent.methods.saveTaskSettings"
                @toggleGradeSwitch="taskComponent.methods.toggleGradeSwitch"
                @cancelModalTask="taskComponent.methods.cancelModalTask"
              />
            </template>
          </modal>
          <modal 
            ref="deleteModal"
            :showFooter="false"
            :size="'small'"
            :showExpand="false"
            :outsideModalCloseIcon="true">
            <template #content>
              <DeleteTask
                @deleteTask="taskComponent.methods.deleteTask"
                @cancelDelete="taskComponent.methods.cancelDelete"
              />
            </template>
          </modal>
          <TaskHeader 
            @run="taskComponent.methods.run()"
            @save="taskComponent.methods.save()"
            @toggleTaskCheck="taskComponent.methods.toggleShowTaskCheck"
            @toggleOpenFiles="taskComponent.methods.toggleOpenFiles"
            @callCheckModal="taskComponent.methods.callCheckModal"
            :teacherCheck="false"
            :maxGrade="taskComponent.data.task.max_grade || null"
            :minGrade="taskComponent.data.task.min_grade || null"
            :maxTaskScore="taskComponent.data.task.max_grade"
            :taskKind="taskComponent.data.task.kind"
            :typeOfTask="taskComponent.data.FieldsOfChangeTask.taskType"
            :showTaskCheck="taskComponent.data.showTaskCheck"
            :isScratch="taskComponent.computed.isScratch"
            :isCreate="true">
            <button class="btn btn--small btn--primary task-header-button_settings btn--outline task-header-button"  @click="taskComponent.methods.callModal('settings')">
              <inline-svg class="task-header_icon--decision task-header_settings--icon" :src="require('@/assets/svg/setting.svg')"/>
            </button>
            <button class="btn btn--smal task-header-button_trash task-header-button" @click="taskComponent.methods.callModal('delete')">
              <inline-svg class="task-header_icon--decision task-header_trash--icon" :src="require('@/assets/svg/trash_Icon.svg')"/>
            </button>
            <div class="task-header_save-button">
              <button class="btn btn--primary btn--header-small task-header__save"  @click="taskComponent.methods.changeTaskFields()">
                <span span class="btn__text">{{$t('pageTask.save')}}</span>
                <inline-svg class="btn__mobile-icon" :src="require('@/assets/svg/save.svg')"/>
              </button>
            </div>
          </TaskHeader>
          <div class="task-content__scratch" v-if="taskComponent.computed.isScratch">
            <iframe 
              :src="taskComponent.data.scratchSrc"  
              width="100%" 
              height="100%" 
              ref="scratchIframe" 
              class="scratch-iframe" 
              allow="camera;microphone" 
              v-if="!taskComponent.data.isMobile" 
            />
            <h2 v-if="taskComponent.data.isMobile" class="scratch-resolution-error">{{$t('pageTask.sorryDevice')}} :(</h2>
          </div>
          <div v-if="!taskComponent.data.isMobile && !taskComponent.computed.isScratch">
            <div class="task-content" v-if="!taskComponent.data.isMobile">
              <FileExplorerContent 
                v-if="taskComponent.computed.showFileExplorer"
                :taskId="taskComponent.data.task.id"
                :kind="taskComponent.data.task.kind"
                :studentFiles="taskComponent.data.studentFiles"
                @fileSelected="taskComponent.methods.fileExplorerSelected"
                @openCode="taskComponent.methods.fileExplorerOpenProjectCode"
                @clearFileSelected="taskComponent.methods.clearSelectFile"
              />
              <splitpanes class="split-container" vertical @resize="taskComponent.methods.updateWidth" @ready="taskComponent.methods.readySplit">
                <pane :size="taskComponent.data.splitEditorWidth" >
                  <div :class="{'editor-wrapper': !taskComponent.computed.isChat}"> 
                    <TaskChat :minusHeight="250" v-if="createChat"/>
                    <splitpanes class="split-container" horizontal @resize="taskComponent.methods.updateWidth" @ready="taskComponent.methods.readySplit">
                      <pane ref="paneEditor" :size="taskComponent.data.splitEditorHeight">
                        <div class="code-wrapper">
                          <loader v-if="taskComponent.data.loadContent" position="center" color="primary" typePosition="absolute"/>
                          <div v-else>
                            <span class="code-wrapper-notselected" v-if="taskComponent.computed.isNotSelectedFile && (taskComponent.computed.isCloud || taskComponent.computed.isWebsite || taskComponent.computed.isUnity || taskComponent.computed.isPygame)">
                              {{$t('pageTask.fileExplorer.сhoiseFile')}}
                            </span>
                            <img v-if="taskComponent.computed.inPreviewMode" :src="taskComponent.data.previewUrl" width="500">
                            <code-editor 
                              v-if="taskComponent.computed.inEditMode"
                              :height="taskComponent.data.heightEditor" 
                              :code="taskComponent.data.task.solution"
                              v-on:code-change="taskComponent.methods.onChangeCode" 
                            />
                            <span class="not-supported-msg" v-if="taskComponent.computed.inNotSupportedMode">
                              {{$t('pageTask.fileExplorer.errors.NotSupported')}}
                            </span>
                          </div>
                        </div>
                      </pane>
                      <pane min-size="10" :size="100 - taskComponent.data.splitEditorHeight">
                        <textarea class="project-console" ref="console" v-model="taskComponent.model.result"/>
                      </pane>
                    </splitpanes>
                  </div>
                </pane>
                <pane min-size="10" 
                  :size="100 - taskComponent.data.splitEditorWidth" 
                  ref="TaskTabContent" 
                  v-if="taskComponent.data.showTaskCheck">
                  <aside class="sidebar">
                    <Tabs>
                      <Tab :title="$t('pageTask.tabs.task')"> 
                        <CreateTask 
                          :textTask="taskComponent.data.task.unescaped_text_of_task"
                          :key="updateKey"
                          ref="TextEditor"
                          @transferTaskDescription="taskComponent.methods.transferTaskDescription"
                        />
                      </Tab>
                      <Tab :title="$t('pageTask.tabs.decison')">
                        <code-editor 
                          :height="taskComponent.data.heightTabEditor" 
                          :code="taskComponent.data.task.solution" 
                          v-on:code-change="taskComponent.methods.changeDecisionCode" 
                          :themeDefault="'chrome'" 
                          :isDropdown="false" 
                        />
                      </Tab>
                    </Tabs>
                    <inline-svg
                    class="sidebar-close"
                    :src="require('@/assets/svg/task-close-comments.svg')"
                    @click="taskComponent.methods.toggleShowTaskCheck"/>
                  </aside>
                </pane>
              </splitpanes>
            </div>
          </div>
          <Tabs mobile="true" v-if="taskComponent.data.isMobile && !taskComponent.computed.isScratchisScratch">
            <Tab :title="$t('pageTask.toggleCreate')">
              <div class="text-editor">
                <CreateTask 
                  :textTask="taskComponent.data.task.unescaped_text_of_task"
                  :key="updateKey"
                  @transferTaskDescription="taskComponent.methods.transferTaskDescription"
                />
              </div>
            </Tab>
            <Tab :title="$t('pageTask.tabs.code')">
              <code-editor 
                v-if="taskComponent.computed.inEditMode && !taskComponent.computed.isChat"
                :height="taskComponent.data.mheight - 60"  
                :code="taskComponent.data.task.solution"
                v-on:code-change="taskComponent.methods.onChangeCode" 
              />
              <div class="mtask-btns">
                <div class='mtask-btns__left'>
                  <button class="btn btn--secondary btn--small task-header-start" @click="taskComponent.methods.run()">
                    <inline-svg class="task-header-start__icon" :src="require('@/assets/svg/start.svg')"/>
                  </button>
                </div>
                <div class="mtask-btns__right">
                  <button class="btn btn--small btn--primary task-header-button_settings btn--outline task-header-button"  @click="taskComponent.methods.callModal('settings')">
                    <inline-svg class="task-header_icon--decision task-header_settings--icon" :src="require('@/assets/svg/setting.svg')"/>
                  </button>
                  <button class="btn btn--small task-header-button_trash task-header-button" @click="taskComponent.methods.callModal('delete')">
                    <inline-svg class="task-header_icon--decision task-header_trash--icon" :src="require('@/assets/svg/trash_Icon.svg')"/>
                  </button>
                  <button  class="btn mtask-btn_check btn--primary btn--small task-header__handover" @click="taskComponent.methods.changeTaskFields()">
                    <span>{{$t('pageTask.save')}}</span>
                  </button>
                </div>
              </div>
            </Tab>
            <Tab :title="$t('pageTask.tabs.decison')">
              <code-editor :height="taskComponent.data.mheight" :code="taskComponent.data.task.solution" :themeDefault="'chrome'" />
            </Tab>
          </Tabs>
        </div>
      </template>
 </TaskGeneral>
  </div>
</template>

<script>
import TaskChat from '@/components/Task/TaskChat/TaskChat'
import TaskGeneral from '@/components/Task/TaskGeneral'
import Loader from '@/components/Loader'
import Header from '@/components/Header/Header'
import TaskHeader from '@/components/Task/TaskHeader'
import CodeEditor from '@/components/CodeEditor/CodeEditor'
import Modal from '@/components/Modal'
import Tabs from '@/components/Tabs/Tabs'
import Tab from '@/components/Tabs/Tab'
import CreateTask from '@/components/Task/TaskCreate'
import TaskSettings from '@/components/Task/TaskSettings'
import DeleteTask from '@/components/Task/TaskDelete'
import { Splitpanes, Pane } from 'splitpanes'
import FileExplorerContent from '@/components/FileExplorer/FileExplorerContent'
import 'splitpanes/dist/splitpanes.css'

export default {
  name: 'Home',
  components: {
    TaskGeneral,
    Header,
    Loader,
    TaskHeader,
    CodeEditor,
    Modal,
    Splitpanes,
    Pane,
    FileExplorerContent,
    TaskSettings,
    DeleteTask,
    CreateTask,
    Tab,
    Tabs,
    TaskChat
  },
  data() {
    return {
      updateKey: 0,
      createChat: false,
    }
  },
  methods: {
    forceRerender() {
      this.updateKey++;
    }   
  },
  computed: {
    lang() {
      return this.$store.state.user.language
    },
  },
  watch: {
    lang() {
      this.forceRerender()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/var.scss";
  .split-container {
    height: calc(100vh - 96px);
  }
  .text-editor {
    height: calc(100vh - 160px);
  }

  .task-header-rate__icon::v-deep path {
      min-width: 22px;
      fill:#141F43;
  }
</style>