<template>
  <div class="file-explorer-new" v-click-outside="closePopup">
    <div
      :class="['file-explorer-new-button', {'file-explorer-new-button--open': openPopup}]"
      @click="toggleOpenPopup">
      <inline-svg
        class="file-explorer-new-button__icon"
        :src="require('@/assets/svg/file-new.svg')"/>
      <inline-svg
        class="file-explorer-new-button__arrow"
        :src="require('@/assets/svg/file-buttons-arrow.svg')"/>
    </div>
    <div class="file-explorer-new-popup" v-if="openPopup">
      <span class="file-explorer-new-popup__element" @click="createFile">{{$t('pageTask.fileExplorer.file')}}</span>
      <span class="file-explorer-new-popup__element" @click="createFolder">{{$t('pageTask.fileExplorer.folder')}}</span>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },
  data() {
    return {
      openPopup: false
    }
  },
  methods: {
    toggleOpenPopup() {
      this.openPopup = !this.openPopup
    },
    closePopup() {
      if (this.openPopup) this.openPopup = false
    },
    createFolder() {
      this.$emit('clickFolder')
      this.openPopup = false;
    },
    createFile() {
      this.$emit('clickFile')
      this.openPopup = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.file-explorer-new {
  position: relative;
  width: 49%;
  &-button {
    position: relative;
    border: 1px solid $primary;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    &__arrow {
      position: absolute;
      right: 8px;
      transform: translateY(-50%);
      top: 50%;
    }
    &--open {
      background: $primary;
      & .file-explorer-new-button__icon::v-deep path {
        stroke: #fff;
      }
      & .file-explorer-new-button__arrow::v-deep path {
        fill: #fff;
      }
    }
  }
  &-popup {
    position: absolute;
    top: 45px;
    width: 100%;
    border: 1px solid $primary;
    border-radius: 8px;
    background: #fff;
    z-index: 4;
    &__element {
      display: block;
      padding: 8px;
      border-bottom: 1px solid $primary;
      font-size: 13px;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>