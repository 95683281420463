<template>
  <div
    :class="['task-header',{'task-header-check': isCheck},{'task-header_non-progress': isCheck && !studentProgress  || isMethodist}]">
    <div class="task-header-left">
      <FileExplorerHeader
        v-if="isShowFiles"
        :opened="openFilesData"
        @openFiles="toggleOpenFiles"/>
      <span class="tag task-header__tag" v-if="taskKind">{{taskKind}}</span>
      <div class="task-header-score" v-show="maxGrade && minGrade || isCreate && maxTaskScore">
        <inline-svg class="task-header-score__diamond" :src="require('@/assets/svg/diamond.svg')"/>
        <template v-if="!isCreate">
          {{studentTaskGrade || 0}}/{{maxGrade}}
        </template>
        <template v-if="isCreate">
          {{maxTaskScore}}
        </template>
      </div>
       <span v-if="isCreate" class="task-header_create-type">{{getTypeTask}}</span>
      <div class="task-header-task_info" v-else>
      <span  class="task-header-student">{{$t('pageTask.student')}}: <span class="task-header-student-name">{{studentName}}</span></span>
      <span v-if="passedTaskTime" class="task-header-deadline">{{$t('pageTask.checkDeadline')}}: <span class="task-header-deadline__date">{{passedTaskTime}}</span></span>
      <span
        v-if="!passedTaskTime" class="task-header-deadline">{{$t('pageTask.deadline.deadlineFirstWord')}}<span class="task-header-deadline__date">{{$t('pageTask.deadline.deadlineLastWord')}} 21.09.2019 00:00</span></span>
      </div>
    </div>
    <div :class="['task-header-right', {'task-header-right__empty-check' : !studentProgress  || isMethodist}]">
      <button class="btn btn--secondary btn--task-header task-header-start" @click="onRun()" v-if="!isScratch && !isCreateTaskLink && !isChat">
        <span class="btn__text">{{$t('pageTask.start')}}</span>
        <inline-svg class="task-header-start__icon" :src="require('@/assets/svg/start.svg')"/>
      </button>
      <button
        v-if="isDecision && !isChat"
        @click="openReset"
        class="btn btn--primary btn--task-header btn--outline task-header-restart">
        <span class="btn__text">{{$t('pageTask.restart')}}</span>
        <inline-svg class="btn__mobile-icon" :src="require('@/assets/svg/refresh.svg')"/>
      </button>
      <button
        v-if="!isCreateTaskLink"
        :class="['btn', 'btn--primary', 'btn--header-small', 'btn--outline', 'task-header-comments',
                {'btn--outline--active': showTaskCheck}]"
        @click="toggleTaskCheck">
        <i class="task-header-comments__unread" v-if="unreadMessages"></i>
        <span v-if="isCheck || isCreate" class="btn__text">{{$t('pageTask.toggleCreate')}}</span>
        <span v-else class="btn__text">{{$t('pageTask.toggleTask')}}</span>
        <inline-svg :class="['btn__mobile-icon', 'list-icon', {'list-icon-black': !showTaskCheck}]" 
        :src="require('@/assets/svg/list.svg')"/>
        <inline-svg
          class="task-header-comments__close"
          :src="require('@/assets/svg/task-close-comments.svg')"
          v-if="showTaskCheck"/>
      </button>
      <i :class="['task-header__separate', {'task-header__separate--none' : isCheck && !studentProgress && !isCreateTaskLink || isMethodist}]"></i>
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
import FileExplorerHeader from '@/components/FileExplorer/FileExplorerHeader'

export default {
  name: 'TaskHeader',
  props: {
    studentTaskGrade: {
      type: Number,
      required: false,
    },
    role: {
      type: String,
      required: false,
    },
    isChat: {
      type: Boolean,
      required: false,
      default: false
    },
    passedTaskTime: {
      // type: String,
      required: false,
      default: null
    },
    typeOfTask: {
      type: String,
      required: false,
      default: ''
    },
    maxTaskScore: {
      type: Number, String,
      required: false,
      default: null
    },
    isCreateTaskLink: {
      type: Boolean,
      required: false,
      default: false
    },
    isPygame: {
      type: Boolean,
      required: false,
      default: false
    },
    isDecision: {
      type: Boolean,
      required: false,
      default: false
    },
    isCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    studentProgress: {
      required: false,
    },
    studentName: {
      required: false,
    },
    teacherCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    isCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    isSendOnReview: {
      type: Boolean,
      required: false,
      default: false
    },
    showFiles: {
      type: Boolean,
      required: false,
      default: false
    },
    openFiles: {
      type: Boolean,
      required: false,
      default: false
    },
    taskKind: {
      required: false, 
    },
    maxGrade: {
      type: Number,
      required: false,
    },
    minGrade: {
      type: Number,
      required: false,
    },
    showTaskCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    runTask: {
      type: Boolean,
      required: false,
      default: true
    },
    rerunTask: {
      type: Boolean,
      required: false,
      default: true
    },
    handoverTask: {
      type: Boolean,
      required: false,
      default: true
    },
    isScratch: {
      type: Boolean,
      required: false,
      default: false
    },
    unreadMessages: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      openFilesData: false,
    }
  },
  computed: {
    isMethodist() {
      return this.role === 'methodologist'
    },
    getTypeTask() {
      return this.typeOfTask === 'Theoretical' ? this.$t('createTask.typeTask.theoretical') : this.$t('createTask.typeTask.practical')
    },
    isShowFiles() {
      return this.taskKind === 'HTML' ||
        this.taskKind === 'PYGAME' ||
        this.taskKind === 'CLOUD' ||
        this.taskKind === 'UNITY'
    },
  },
  components: {
    FileExplorerHeader
  },
  methods: {
    toggleOpenFiles() {
      this.openFilesData = !this.openFilesData
      this.$emit('toggleOpenFiles')
    },
    toggleTaskCheck() {
      this.$emit('toggleTaskCheck')
    },
    onRun() {
      this.$emit('run')
    },
    openReset() {
      this.$emit('openReset')
    }
  },
  created() {
    this.openFilesData = this.openFiles
  }
 }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.button-rate{
  @media(max-width: 1116px) {
    width: 46px;
  }
  &-text{
    @media(max-width: 1116px) {
      display: none;
    }
  }
} 
.list-icon {
  fill: #fff;
}
.list-icon-black {
  fill: #000;
}

.task-header {
  &_create-type {
    color: #4C5261;
    font-size: 14px;
  }
  &_non-progress {
    justify-content: start;
  }
  // &-check{
  //   @media(max-width: 992px) {
  //     height: 80px;
  // }
  //   @media(max-width: 900px) {
  //     height: 60px;
  //   }
  // }
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 32px;
  box-shadow: inset 0px -1px rgba(0,0,0,.1) ;
  @media(max-width: 900px) {
      overflow-x: auto;
      padding: 0 16px;
    }
    &_icon--decision {
      min-width: 15px;
    }
    &_settings--icon {
      fill: #3860ED;
    }
    &_trash--icon {
       fill: #E55961
    }
    &-button {
      margin-right: 8px;
      width: 32px;
      height: 32px;
      &_settings {
        &:hover{
          .task-header_settings--icon{
            fill: #fff;
          }
        }
      }
      &_trash{
        &:hover{
          .task-header_trash--icon{
            fill: #fff;
          }
        }
      }
      &_trash {
        background: #fff;
        border: 1px solid #E55961;
        &:hover{
          background: #E55961;
        }
      }
    }
  &-task_info {
    display: flex;
    @media(max-width: 992px) {
      flex-direction: column;
    }
    @media(max-width: 900px) {
      flex-direction: row;
    }
  }
  &-collab {
    margin-right: 15px;
  }
  &-left {
    display: flex;
    align-items: center;
  }
  &-right {
    display: flex;
    align-items: center;
    @media(max-width: 900px) {
      display: none;
    }
    &__empty-check{
      margin-left: 30px;
    }
  }
  &__tag {
    margin-right: 14px;
  }
  &-score {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $grey;
    font-family: "InterSemiBold";
    margin-right: 8px;
    &__diamond {
      margin-right: 4px;
    }
  }
  &-deadline {
    text-align: center;
    color: #4C5261;
    font-size: 12px;
    font-family: "InterMedium";
    // margin-right: 8px; 
    &__date {
      font-family: "InterSemiBold";
      color: $primary;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
  &-student{
    text-align: center;
    color: #4C5261;
    font-size: 12px;
    font-family: "InterMedium";
    margin-right: 8px;
  }
  &-student-name {
    white-space: nowrap;
    font-family: "InterSemiBold";
    color: $primary;
  }
  &-start {
    margin-right: 8px;
    @media(max-width: 1320px) {
      span {
        display: none;
      }
    }
    &__icon {
      margin-left: 10px;
      @media(max-width: 1320px) {
          margin-left: 0;
      }
    }
  }
  &-restart {
    margin-right: 8px;
  }
  &-comments {
    &__unread {
      background: #E55961;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 8px;
      display: block;
    }
    &__close::v-deep {
      margin-left: 8px;
      cursor: pointer;
      & path {
        fill: #fff;
      }
    }
    &.btn--outline--active,
    &:hover {
      .list-icon-black {
        fill: #fff;
      }
    }
    &:hover {
      .list-icon-black {
        fill: #fff;
      }
    }
  }
  &__separate {
    border-right: 1px solid #D9D9D9;
    height: 32px;
    margin: 0 8px;
    &--none {
      display: none;
    }
    @media(max-width: 1116px) {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}
.btn {
  &__mobile-icon {
    display: none;
    @media(max-width: 1320px) {
      display: inline-block;
    }
  }
  &__text {
    @media(max-width: 1320px) {
      display: none;
    }
  }
}
</style>