import Vue from 'vue'
import axios from "axios"
import config from "@/config"
import store from '../store'
import router from '../router'
import Cookies from "js-cookie"
export const HTTP = axios.create({
  baseURL: config.PLATFORM_API,
  headers: {
    "Accept-Language": store.state.user.language
  }
});

export const Notifications = axios.create({
  baseURL: config.NOTIFICATIONS_API,
  headers: {
    "api-key": "2a22530094a1cb8ed66d5db3d5cadda5715bbb5e9e227213490749bf9103"
  }
});

HTTP.interceptors.request.use(async function(config) {
  const token = Cookies.get('access')
  if (token) config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});

HTTP.interceptors.response.use(response => {
  return response;
}, error => {
  if (router.history.current.name !== 'Auth') {
    if (error.response.status === 401) {
      router.push('/auth')
    }
    if (error.response.status === 402 || error.response.status === 403) {
      router.push('/error?code=403')
    }
    if (error.response.status >= 500) {
      router.push('/error?code=500')
    }
    if (error.response.status === 400) {
      for (const [key, value] of Object.entries(error.response.data)) {
        Vue.$toast.open({
          message: `${key}: ${value}`,
          type: 'error',
          position: 'top',
          delay: 300000
        });
      }
    }
    if (error.response.status > 404) {
      Vue.$toast.open({
        message: error.response.data.detail,
        type: 'error',
        position: 'top',
        delay: 300000
      });
    }
  }
  return Promise.reject(error);
});