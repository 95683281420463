<template>
  <div class="question-reset">
    <span class="question-reset__title">{{title}}</span>
    <span class="question-reset__description" v-html="description"></span>
    <div class="question-reset-buttons">
      <button
        class="btn btn--primary btn--small question-reset-buttons__button"
        @click="action">{{$t('pageTask.modalQuestion.button.agree')}}</button>
      <button
        class="btn btn--primary btn--outline btn--small question-reset-buttons__button"
        @click="close">{{$t('pageTask.modalQuestion.button.disagree')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskquestionReset',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    action() {
      this.$emit('action')
    }
  }
}
</script>

<style lang="scss" scoped>
.question-reset {
  &__title {
    display: block;
    font-size: 24px;
    font-family: "InterBold";
    margin-bottom: 16px;
    word-break: break-word;
  }
  &__description {
    display: block;
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 24px;
  }
  &-buttons {
    display: flex;
    &__button {
      width: 100px;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
</style>