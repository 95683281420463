<template>
  <div class="file-explorer-upload" v-click-outside="closePopup">
    <div
      :class="['file-explorer-upload-button', {'file-explorer-upload-button--open': openPopup}]"
      @click="toggleOpenPopup">
      <inline-svg
        class="file-explorer-upload-button__icon"
        :src="require('@/assets/svg/file-upload.svg')"/>
      <inline-svg
        class="file-explorer-upload-button__arrow"
        :src="require('@/assets/svg/file-buttons-arrow.svg')"/>
    </div>
    <div class="file-explorer-upload-popup" v-if="openPopup">
      <label class="file-explorer-upload-popup__element">
        {{$t('pageTask.fileExplorer.file')}}
        <input type="file" @change="selectFile" style="display: none" />
      </label>
      <label class="file-explorer-upload-popup__element">
        {{$t('pageTask.fileExplorer.zip')}}
        <input type="file" @change="selectFolder" accept=".zip" style="display: none" />
      </label>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },
  data() {
    return {
      openPopup: false
    }
  },
  methods: {
    toggleOpenPopup() {
      this.openPopup = !this.openPopup
    },
    closePopup() {
      if (this.openPopup) this.openPopup = false
    },
    selectFile(event) {
      this.$emit("selectFile", event.target.files[0]);
      this.closePopup();
    },
    selectFolder(event) {
      this.$emit("selectFolder", event.target.files[0]);
      this.closePopup();
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.file-explorer-upload {
  position: relative;
  width: 49%;
  &-button {
    position: relative;
    border: 1px solid $primary;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
    &__arrow {
      position: absolute;
      right: 8px;
      transform: translateY(-50%);
      top: 50%;
    }
    &--open {
      background: $primary;
      & .file-explorer-upload-button__icon::v-deep path {
        stroke: #fff;
      }
      & .file-explorer-upload-button__arrow::v-deep path {
        fill: #fff;
      }
    }
  }
  &-popup {
    position: absolute;
    top: 45px;
    width: 100%;
    border: 1px solid $primary;
    border-radius: 8px;
    background: #fff;
    z-index: 4;
    &__element {
      display: block;
      padding: 8px;
      border-bottom: 1px solid $primary;
      font-size: 13px;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>