<template>
<div>
  <div class="error">
    <div class="error-wrapper">
    <div class="error-image">
      <img srcset="
        /images/404boyX.png, 
        /images/404boy.png 2x">
    </div>
      <div class="error-message">
        <span class="error-type">{{$route.query.code}}</span>
        <div class="error-text">
          <span class="error-text_element">{{$t('errorPage.error')}}</span>
          <span class="error-text_element">
            <div v-if="textError">{{textError}}</div>
            <div v-else>
              <span v-if="code === 500" v-html="$t('errorPage.text500')" />
              <span v-if="code === 403" v-html="$t('errorPage.text403')" />
              <span v-if="code === 404" v-html="$t('errorPage.text404')" />
            </div>
          </span>
        </div>
        <router-link class="error-button" to="/">{{$t('errorPage.pathToMainPage')}}</router-link>
      </div>
      <div class="error-image">
        <img srcset="
          /images/404computerX.png, 
          /images/404computer.png 2x">
      </div>
  </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {  
      queryCode: 404
    }
  },
  computed: {
    code() {
      return this.$route.query.code
    },
    textError() {
      return this.$route.query.text
    }
  },
  created() {
    if (!this.$route.query.code) {
      this.$route.query.code = this.queryCode
    }
    // this.$router.push({ query: { code: this.$route.query.code } })
  }
}
</script>

<style lang="scss" scoped>

.error {
  height: 100vh;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(315.44deg, #FF98E2 -4%, #7C79FD 94.41%);
  &-wrapper {
    display: flex;
    align-items: flex-end;
  }
  &-message {
    text-align: center;
    margin: 0 52px 0 62px;
    padding: 32px;
    background: #fff;
  }
  &-image {
    display: flex;
    margin-bottom: 32px;
  }
  &-type {
    color: #3560EE;
    font-family: "JetBrainsMonoExtraBold";
    font-size: 120px;
    font-weight: bold; 
  }
  &-text {
    font-size: 18px;
    white-space: nowrap;
    &_element {
      display: block;
      &:first-child {
        margin-bottom: 8px;
      }
      &:nth-child(2) {
        margin-bottom: 24px;
      }
    }
  }
  &-button {
    margin-top: 24px;
    display: inline-block;
    font-size: 16px;
    width: 100%;
    color: #141F43;
    background: #D0FA61;
    padding: 14px 0
  }
}

@media screen and (max-width: 1023px) {
  .error {
    &-message {
          margin: 0 12px 0 20px;
          padding: 20px;
    }
    &-image {
      max-height: 280px;
      min-height: 260px;
      margin-bottom: 0;
    }
    &-text {
      font-size: 14px;
    }
    &-type {
      font-size: 85px;
    }
  }
}

@media screen and (max-width: 767px) {
  .error {
       &-image {
      &:last-child {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 565px) {
  .error {
       &-image {
      &:first-child {
        display: none;
      }
    }
  }
}
</style>