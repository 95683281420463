<template>
  <div class="lang" v-click-outside="hideSelectedLang">
    <div class="lang-selected" @click="toggleSelectedLang">
      <span class="lang-selected__name">
        {{selectedLangValue}}
        <inline-svg class="lang-selected__icon" :src="require('@/assets/svg/blue-arrow-bottom.svg')"/>
      </span>
      <div class="lang-list" v-if="openSelectLang" >
        <span
          :class="['lang-list-element', {'lang-list-element--active': lang.selected}]"
          @click="selectLang(lang)"
          v-for="lang in langs"
          :key="lang.value">
          <span class="lang-list-element__value">{{lang.value}}</span>
          <span class="lang-list-element__name">{{lang.name}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'SelectLang',
  directives: {
    ClickOutside
  },
  data() {
    return {
      openSelectLang: false,
      selectedLang: '',
      selectedLangValue: '',
      langs: [
        {
          value: 'ru',
          name: 'Русский',
          selected: true
        },
        {
          value: 'en',
          name: 'English',
          selected: false
        }
      ]
    }
  },
  watch: {
    selectedLang(val) {
      this.selectedLangValue = this.langs.find(el => el.name === val).value
    }
  },
  methods: {
    toggleSelectedLang() {
      this.openSelectLang = !this.openSelectLang;
    },
    hideSelectedLang() {
      setTimeout(() => {
        this.openSelectLang = false;
      }, 100);
      
    },
    changeLang(language) {
      this.openSelectLang = false
      this.langs.forEach(element => {
        if (element.value === language) {
          element.selected = true;
          this.selectedLang = element.name;
        } else {
          element.selected = false;
        }
      });
    },
    selectLang(lang) {
      this.changeLang(lang.value);
      this.$store.commit('user/setLanguageFromSelect', lang.value);
    },
  },
  mounted() {
    this.changeLang(this.$store.state.user.language)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.lang {
  position: relative;
  z-index: 1;
  margin-right: 16px;
  &-selected {
    width: 53px;
    height: 32px;
    background: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    justify-content: center;
    cursor: pointer;
    font-family: "InterMedium";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 7px;
    font-size: 14px;
    &__icon {
      display: block;
      margin-left: 5px;
    }
    &__name {
      color: #000;
      display: flex;
      align-items: center;
    }
  }
  &-list {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 150px;
    padding: 8px 0;
    right: 0;
    top: 46px;
    &-element {
      display: flex;
      height: 40px;
      padding: 0 16px;
      align-items: center;
      &__value {
        width: 30px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "InterSemiBold";
        border-radius: 4px;
        font-size: 12px;
        margin-right: 8px;
      }
      &--active {
        & .lang-list-element__value {
          color: #fff;
          background: $primary;
        }
      }
    }
  }
}
</style>