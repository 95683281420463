import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth.vue'
import Task from '../views/Task.vue'
import CheckTask from '../views/CheckTask.vue'
import CreateTask from '../views/CreateTask.vue'
import CreateTaskLink from '../views/CreateTaskLink.vue'
import IframeForm from '@/components/IframeForm.vue'
// import TaskLink from '../views/TaskLink.vue'
import UI from '../views/UI.vue'
import NotFound from '../views/NotFound.vue'
import store from '../store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/task',
    name: 'task',
    component: Task,
    query: {
      task_id: null,
      user_ud: store.state.user.user.studentInfo.student_id
    },
    meta: {
      requiresAuth: true
    },
    beforeEnter(to, from, next) {
      const teacherPath = `check-task/?task_id=${to.query.task_id}&user_id=${to.query.user_id}`
      if (store.state.user.user.role === 'teacher') next(teacherPath)
      if (store.state.user.user.role === 'methodologist') {
        next(`create-task?task_id=${to.query.task_id}&user_id=${store.state.user.user.id}`)
      }
      next();
    }
  },
  {
    path: '/check-task',
    name: 'check-task',
    component: CheckTask,
    query: {
      task_id: null,
      user_ud: null
    },
    meta: {
      requiresAuth: true
    },
    beforeEnter(to, from, next) {
      if (store.state.user.user.role === 'student') {
        next(`task?task_id=${to.query.task_id}&user_id=${store.state.user.user.id}`)
      }
      if (store.state.user.user.role === 'methodologist') {
        next(`create-task?task_id=${to.query.task_id}`)
      }
      else next();
    }
  },
  {
    path: '/create-task',
    name: 'CreateTask',
    component: CreateTask,
    meta: {
      requiresAuth: true
    },
    query: {
      task_id: null,
      user_ud: null
    },
    beforeEnter(to, from, next) {
      if (store.state.user.user.role === 'student') {
        next(`task/?task_id=${to.query.task_id}&user_id=${store.state.user.user.id}`)
      }
      if (store.state.user.user.role === 'teacher') {
        next(`check-task/?task_id=${to.query.task_id}&user_id=${store.state.user.user.id}`)
      }
      else next();
    }
  },
  {
    path: '/taskLink',
    name: 'CreateTaskLink',
    component: CreateTaskLink,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ui',
    name: 'UI',
    component: UI
  },
  {
    path: '/iframe',
    name: 'Iframe',
    component: IframeForm,
    props: route => ({ url: route.query.next, studentId: store.state.user.user.studentInfo.student_id })
  },
  {
    path: '/error',
    query: 'code' ,
    component: NotFound,
    name: 'error',
  },
  {
    path: '*',
    redirect: '/error',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Auth' && store.state.user.user.id) {
    const lang = localStorage.getItem('lang') || 'en'
    if (to.query.next && to.query.next.includes('tally.so')) {
      const studentId = store.state.user.user.studentInfo.student_id
      next({
        path: '/iframe',
        query: { next: to.query.next, student_id: studentId }
      });
      return;
    }
    if (to.query.next && !to.query.next.includes('tally.so')) window.location.replace(to.query.next)
    else window.location.replace(`https://${window.location.hostname}/${lang}/courses/`)

    return
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // этот путь требует авторизации, проверяем залогинен ли
    // пользователь, и если нет, перенаправляем на страницу авторизации

    if (!store.state.user.user.id) {
      next({
        path: '/auth',
        query: { redirect: to.fullPath }
      })
    } else {
      const nextUrl = to.query.next;
      if (nextUrl && nextUrl.includes('tally.so')) {
        const studentId = store.state.user.user.studentInfo.student_id;
        next({
          path: '/iframe',
          query: { next: nextUrl, student_id: studentId }
        });
        return;
      }
      next();
    }
  } else {
    next();
  }
})

export default router
