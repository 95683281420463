<template>
  <div class="ui">
    <router-link to="/" class="link">Ссылка</router-link>
    <input type="text" class="input-text" placeholder="Какой-то input">
    <div>
      <input type="text" class="input-text input-text--error" placeholder="Какой-то input с ошибкой">
      <span class="input-error-text">Текст ошибки</span>
    </div>
    <button class="btn btn--primary">Кнопка primary</button>
    <button class="btn btn--primary btn--outline">Кнопка primary outline</button>
    <button class="btn btn--primary btn--outline btn--outline--active">Кнопка primary outline active</button>
    <button class="btn btn--secondary">Кнопка primary</button>
    <button class="btn btn--primary btn--fullwidth">Кнопка на всю ширину</button>
    <button class="btn btn--primary" disabled>Кнопка disabled</button>
    <button class="btn btn--primary btn--small">Кнопка маленькая</button>
    <div class="message">Блок с сообщением</div>
    <div class="message message--error">Блок с ошибкой</div>
    <span class="tag">Какой-то тег</span><br>
        <div class="radio-button">
      <label class="radio-container">Радио кнопка
        <input type="radio"  name="radio">
        <span class="radio-checkmark"></span>
      </label>
    </div>
    <div class="toggle-switch">
       <span class="switch-text">Свитч</span> 
      <label class="switch">
        <input type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
    <label class="checkbox checkbox--bold">
      <input type="checkbox" class="checkbox__input">
      <span class="checkbox__check"></span>
      <span class="checkbox__check__text">Чекбокс</span>
    </label>
    <a href="#" v-tooltip="'Подсказка'">Tooltip</a>
    <br><br>
    <div>
      <button class="btn btn--primary" @click="openBigModal">Big Modal</button>
      <modal
        ref="modalBig"
        :showExpand="true"
        :showFooter="true"
        :showClose="true"
        size="big">
        <template v-slot:content>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem odit vitae iure autem itaque nam corporis quas, ratione culpa nisi debitis neque, ex eaque earum, pariatur amet. Commodi, animi ab.
        </template>
        <template v-slot:footer>
          Подвал модалки
        </template>
      </modal>
    </div>
    <div>
      <button class="btn btn--primary" @click="openMiddleModal">Middle Modal</button>
      <modal
        ref="modalMiddle"
        :showExpand="false"
        :showFooter="false"
        :showClose="false"
        size="middle">
        <template v-slot:content>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem odit vitae iure autem itaque nam corporis quas, ratione culpa nisi debitis neque, ex eaque earum, pariatur amet. Commodi, animi ab.
        </template>
      </modal>
    </div>
    <div>
      <button class="btn btn--primary" @click="openSmallModal">Small Modal (Question)</button>
      <modal
        ref="modalSmall"
        :showExpand="false"
        :showFooter="false"
        :showClose="false"
        size="small">
        <template v-slot:content>
          <ModalQuestion
            title="Заголовок окна"
            description="Какое-то описание, <br> можно передать как HTML"
            @close="closeSmallModal"
            @action="closeSmallModal"/>
        </template>
      </modal>
    </div>
    <div>
      <StarRating/>
    </div>
    <div class="tab">
      <Tabs>
        <Tab title="Какие-то"> 
          Контент первого таба
        </Tab>
        <Tab title="Табы">
          Контент второго таба
        </Tab>
      </Tabs>
    </div>
    <br>
    <div>
      <span class="title">Кнопка настройки</span>
      <br><br>
      <button class="btn btn--small btn--primary task-header-button_settings btn--outline task-header-button" ><inline-svg class="task-header_icon--decision task-header_settings--icon" :src="require('@/assets/svg/setting.svg')"/></button>
      <br>
      <span class="title">Кнопка удаление</span>
      <br><br>
      <button class="btn btn--smal task-header-button_trash task-header-button"><inline-svg class="task-header_icon--decision task-header_trash--icon" :src="require('@/assets/svg/trash_Icon.svg')"/></button>
    </div>
  </div>
</template>

<script>
import StarRating from '@/components/StarRating'
import Tabs from '@/components/Tabs/Tabs'
import Tab from '@/components/Tabs/Tab'
import Modal from '@/components/Modal'
import ModalQuestion from '@/components/ModalQuestion'
export default {
  name: 'UI',
  data() {
    return {
      isClickSave: false
    }
  },
  components: {
    StarRating,
    Tabs,
    Tab,
    Modal,
    ModalQuestion
  },
  methods: {
    openBigModal() {
      this.$refs.modalBig.openModal()
    },
    openMiddleModal() {
      this.$refs.modalMiddle.openModal()
    },
    openSmallModal() {
      this.$refs.modalSmall.openModal()
    },
    closeSmallModal() {
      this.$refs.modalSmall.hideModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.save-button {
  margin: 60px;
  
}
.tab{
  @media (max-width: 900px) {
    height: 60px;
  }
}

.ui {
  padding: 50px;
  width: 400px;
  & > * {
    margin-bottom: 20px;
  }
}
</style>