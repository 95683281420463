import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
  months: ['Январь', 'Февраль', 'Март', 'Апрель',
          'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октяюрь', 'Ноябрь', 'Декабрь']
})

export default {
  install(Vue) {
    Vue.prototype.$dayjs = dayjs
  },
};