<template>
  <div class="explorer-content">
    <modal
      ref="modalRemoveFile"
      :showExpand="false"
      :showFooter="false"
      :showClose="false"
      size="small">
      <template v-slot:content>
        <ModalQuestion
          @close="closeRemoveFile"
          @action="deleteItem(removeElement.node, removeElement.event)"
          :title="`${$t('pageTask.fileExplorer.removeFile.title')} ${removeElement.node.data.text}?`"
          :description="$t('pageTask.fileExplorer.removeFile.description')"/>
      </template>
    </modal>
    <modal
      ref="modalRemoveFolder"
      :showExpand="false"
      :showFooter="false"
      :showClose="false"
      size="small">
      <template v-slot:content>
        <ModalQuestion
          @close="closeRemoveFolder"
          @action="deleteItem(removeElement.node, removeElement.event)"
          :title="`${$t('pageTask.fileExplorer.removeFolder.title')} ${removeElement.node.data.text}?`"
          :description="$t('pageTask.fileExplorer.removeFolder.description')"/>
      </template>
    </modal>
    <div v-show="treeLoading" class="tree-loader">
      <Loader color="primary"/>
    </div>
    <div v-show="!treeLoading" class="explorer-container">
      <div class="explorer-content-buttons">
        <FileExplorerUpload
          @selectFile="uploadFile"
          @selectFolder="uploadFolder"/>
        <FileExplorerNew
          @clickFile="createFile"
          @clickFolder="createFolder"/>
      </div>
      <button class="btn btn--primary btn--outline explorer-download" @click="getZipFolder">
        <inline-svg class="explorer-download__icon" :src="require('@/assets/svg/file-download.svg')"/>
        {{$t('pageTask.fileExplorer.download')}}
      </button>
      <perfect-scrollbar class="explorer-scrollbar">
        <tree
          ref="tree"
          v-if="haveTreeData"
          :data="treeData"
          :options="treeOptions"
          @node:text:changed="finishEdit"
          @node:selected="getFileContent"
          @node:dragging:start="dragStart"
          @node:dragging:finish="dragFinish"
          @tree:mounted="removeIniFiles">
          <span class="tree-text" slot-scope="{ node }">
            <inline-svg
              :src="require('@/assets/svg/file-explorer-remove.svg')"
              class="explorer-remove"
              v-if="node.data.isFolder"
              @click="openRemoveFolder(node, $event)"/>
            <inline-svg
              :src="require('@/assets/svg/file-explorer-remove.svg')"
              class="explorer-remove"
              v-else
              @click="openRemoveFile(node, $event)"/>
            <!-- <img
              v-if="isAuthor"
              src="@/assets/svg.svg"
              class="explorer-remove"
              :class="{ 'explorer-remove--disabled': isPyGameMainPy(node.text) }"
              @click="deleteItem(node, $event)" -->
            <inline-svg
              class="explorer__icon"
              v-if="!node.hasChildren() && !node.data.isFolder"
              :src="require('@/assets/svg/file-explorer-file.svg')"/>
            <inline-svg
              class="explorer__icon"
              v-else
              :src="require('@/assets/svg/file-explorer-folder.svg')"/>
            <span class="explorer-item-name" @click="editItem(node)">{{node.text}}</span>
          </span>
        </tree>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import apiExplorer from '@/api/explorer'
import apiTask from '@/api/task'
import Loader from '@/components/Loader'
import ClickOutside from 'vue-click-outside'
import LiquorTree from 'liquor-tree'
import Modal from '@/components/Modal'
import ModalQuestion from '@/components/ModalQuestion'
import FileExplorerUpload from '@/components/FileExplorer/FileExplorerUpload'
import FileExplorerNew from '@/components/FileExplorer/FileExplorerNew'

export default {
  name: 'FileExplorerContent',
  directives: {
    ClickOutside
  },
  props: {
    taskId: {
      type: Number,
      required: true
    },
    kind: {
      type: String,
      required: false
    },
    studentFiles: {
      type: Array,
      required: false
    }
  },
  components: {
    Loader,
    FileExplorerUpload,
    FileExplorerNew,
    [LiquorTree.name]: LiquorTree,
    Modal,
    ModalQuestion
  },
  data() {
    return {
      treeData: [],
      haveTreeData: false,
      treeLoading: false,
      treeOptions: {
        propertyNames: {
          text: "name"
        },
        dnd: true
      },
      // removeElement нужен для того, чтобы хранить информацию об
      // удаляемом файле или папке. Для проброски node и event.
      removeElement: {
        node: {
          data: {
            text: ''
          }
        },
        event: ''
      }
    }
  },
  computed: {
    isPyGame() {
      return this.kind === 'PYGAME';
    },
  },
  methods: {
    closeRemoveFile() {
      this.$refs.modalRemoveFile.hideModal()
    },

    openRemoveFile(node, event) {
      this.removeElement.node = node
      this.removeElement.event = event
      this.$refs.modalRemoveFile.openModal()
    },

    closeRemoveFolder() {
      this.$refs.modalRemoveFolder.hideModal()
    },

    openRemoveFolder(node, event) {
      this.removeElement.node = node
      this.removeElement.event = event
      this.$refs.modalRemoveFolder.openModal()
    },

    async fetchFilesTree(treeFiles) {
      this.treeLoading = true;
      try {
        const dataFiles = [...treeFiles];
        this.addUrlToNodeData(dataFiles);
        // For PyGame we will create a pseudo file 'main.py'
        // When user select it --> open project's code
        if (this.isPyGame) this.addMainPyFile(dataFiles);
        this.treeData = dataFiles;

        this.haveTreeData = false;

        this.$nextTick(() => {
          this.haveTreeData = true;
        });
      } catch (e) {
        this.reportError(e);
      } finally {
        this.treeLoading = false;
      }
    },

    addUrlToNodeData(tree) {
      const treeInstance = this.getTree();
      tree.forEach(item => {
        item.data = { url: item.url };

        // Restore 'expanded' state
        if (treeInstance) {
          const nodes = treeInstance.find(item.name);
          const node = nodes[0];
          if (node) {
            item.state = { ...item.state, expanded: node.states.expanded };
          }
        }

        if (item.children.length > 0) {
          this.addUrlToNodeData(item.children);
        }
      });
    },

    getTree() {
      return this.$refs.tree;
    },

    async createFolder() {
      this.treeLoading = true;
      const tree = this.getTree();
      try {
        const folderName = this.generateName(this.$t('pageTask.fileExplorer.newFolder'), 0);
        const { targetPath, targetNode } = this.getTargetPathNode(folderName);
        await apiExplorer.createFolder(this.taskId, targetPath);
        if (targetNode) {
          tree.append(targetNode, {
          text: folderName,
          data: { isFolder: true }
          });
          targetNode.expand();
        } else {
          tree.append({ text: folderName, data: { isFolder: true } });
        }
      } catch (e) {
        this.reportError(e);
      } finally {
        this.treeLoading = false;
      }
    },

    async createFile() {
      this.treeLoading = true;
      const tree = this.getTree();
      try{
        let fileName  = this.$t('pageTask.fileExplorer.nameCreateFile');
        if (this.kind === "HTML") fileName = "index.html";
        fileName = this.generateName(fileName, 0);
        const { targetPath, targetNode } = this.getTargetPathNode(fileName);
        await apiExplorer.createFile(this.taskId, targetPath, "");
        if (targetNode) {
          tree.append(targetNode, fileName);
          targetNode.expand();
        } else {
          tree.append(fileName);
        }
      } catch (e) {
        this.reportError(e);
      } finally {
        this.treeLoading = false;
      }
    },

    async deleteItem(node, event) {
      // Deleting 'main.py' in PyGame is not allowed
      if (this.isPyGameMainPy(node.text)) return;
      
      this.closeRemoveFile()
      this.closeRemoveFolder()
      this.treeLoading = true
      event.stopPropagation()
      try {
        const tree = this.getTree()
        const path = this.getFolderPath(node)
        // When deleting folder we have to add '/' at the end
        let pathString = path.join("/")
        if (node.hasChildren() || node.data.isFolder) {
          pathString = `${pathString}/`
        }

        await apiExplorer.deleteItem(this.taskId, pathString)
        tree.remove(node)
        this.deselectTree()
      } catch (e) {
        this.reportError(e)
      } finally {
        this.treeLoading = false;
      }
    },

    async uploadFile(file) {
      this.treeLoading = true
      try {
        const tree = this.getTree()
        const selected = tree.selected()[0]

        let path = ""
        let pathArray = []
        let nodeToAppend = null
        if (selected) {
          // Folder
          if (selected.children.length > 0 || selected.data.isFolder) {
            pathArray = this.getFolderPath(selected)
            nodeToAppend = selected
          } else {
            // File
            if (selected.parent) {
              pathArray = this.getFolderPath(selected.parent)
              nodeToAppend = selected.parent
            }
          }
        }

        path = pathArray.join("/");

        // Generate unique name needed
        const fileName = this.generateName(file.name, 0)

        const url = await apiExplorer.uploadFile(
          this.taskId,
          path,
          file,
          fileName
        );

        if (nodeToAppend) {
          nodeToAppend.append({ text: fileName, data: { url } })
          selected.expand()
        } else {
          tree.append({ text: fileName, data: { url } })
        }
      } catch (e) {
        this.reportError(e)
      } finally {
        this.treeLoading = false
      }
    },

    async uploadFolder(file) {
      this.treeLoading = true;
      
      try {
        const tree = this.getTree();
        const selected = tree.selected()[0];

        if (!file.name.endsWith("zip")) {
          this.$toast.open({
            message: this.$t('pageTask.fileExplorer.zipLoad'),
            type: 'error',
            position: 'top',
            delay: 300000
          });
          throw this.$t('pageTask.fileExplorer.zipLoad');
        }

        const fileName = this.generateName(file.name, 0);

        let path = "";
        let pathArray = [];
        if (selected) {
          // Folder
          if (selected.children.length > 0 || selected.data.isFolder) {
            pathArray = this.getFolderPath(selected);
          } else {
            // File
            if (selected.parent) {
              pathArray = this.getFolderPath(selected.parent);
            }
          }
        }

        path = pathArray.join("/");

        await apiExplorer.uploadFolder(this.taskId, path, file, fileName);
        const treeFiles = await apiTask.getTempProgressTask(this.taskId)
        this.fetchFilesTree(treeFiles.student_files);
        this.treeLoading = false;
      } catch (e) {
        this.reportError(e);
      } finally {
        this.treeLoading = false;
      }
    },

    async finishEdit(node, newText, oldText) {
      const tree = this.getTree();
      const selection = tree.findAll(newText);
      if (selection.length > 1) {
        this.reportError(
          this.$t('pageTask.fileExplorer.errors.renameError'),
          this.$t('pageTask.fileExplorer.errors.existNameError')
        );
        this.fetchFilesTree(this.treeData);
        return;
      }

      this.treeLoading = true;

      if (newText !== oldText) {
        try {
          let oldPath = [],
            newPath = [];
          if (node.parent) {
            oldPath = this.getFolderPath(node.parent);
            newPath = this.getFolderPath(node.parent);
          }

          oldPath.push(oldText);
          newPath.push(newText);

          let oldPathString = oldPath.join("/");
          let newPathString = newPath.join("/");
          if (node.hasChildren() || node.data.isFolder) {
            oldPathString = `${oldPathString}/`;
            newPathString = `${newPathString}/`;
          }

          await apiExplorer.renameFile(
            this.taskId,
            oldPathString,
            newPathString
          );
        } catch (e) {
          this.reportError(e);
        } finally {
          this.treeLoading = false;
        }
      }
    },

    getFolderPath(node, path = []) {
      if (!node.parent) {
        path.unshift(node.text);
        return path;
      } else {
        path.unshift(node.text);
      }

      return this.getFolderPath(node.parent, path);
    },

    generateName(name, counter) {
      const tree = this.getTree();

      let newName = `${counter}${name}`;
      if (counter === 0) newName = `${name}`;

      const selection = tree.find(newName);
      if (selection.length === 0) return newName;

      return this.generateName(name, ++counter);
    },

    isPyGameMainPy(name) {
      return this.isPyGame && name === "main.py";
    },
    
    editItem(node) {
      // Renaming 'main.py' in PyGame is not allowed
      if (this.isPyGameMainPy(node.text)) return;

      if (node.selected()) node.startEditing();
    },

    reportError(e) {
      this.$toast.open({
        message: `${this.$t('pageTask.fileExplorer.errors.toastError')}: ${e}`,
        type: 'error',
        position: 'top',
        delay: 300000
      });
      
    },

    expandParentNodes(node) {
      if (node.parent) {
        node.parent.expand();
        this.expandParentNodes(node.parent);
      }
    },

    
    /**
     * Returns targetPath & targetNode
     * Target means node we want to append
     * If selected node is folder - will append to selected node
     * If selected has parent - will append to selected node's parent
     * Otherwise - return null - will append to root
     */
    getTargetPathNode(name) {
      const tree = this.getTree();
      const selection = tree.selected();

      if (selection && selection.length > 0) {
        const selectedNode = selection[0];
        const targetNode = this.getTargetNode(selectedNode);

        if (targetNode) {
          const pathArray = this.getFolderPath(targetNode);
          pathArray.push(name);
          const targetPath = pathArray.join("/");

          return { targetPath, targetNode };
        }
      }

      return { targetPath: name, targetNode: null };
    },

    getTargetNode(node) {
      if (this.nodeIsFolder(node)) {
        return node;
      }

      if (node.parent) {
        return node.parent;
      }

      return null;
    },

    nodeIsFolder(node) {
      return node.hasChildren() || node.data.isFolder;
    },

    deselectTree() {
      this.$emit('clearFileSelected')
      const tree = this.getTree();
      const node = tree.selected();
      node.unselect();  
    },

    findIndexNode() {
      const tree = this.getTree();

      const generalFile = tree.find('index.html')
      if (generalFile.length > 0) return generalFile[0];

      return null;
    },

    addMainPyFile(tree) {
      tree.unshift({ name: "main.py" });
    },

    removeIniFiles() {
      // We need to remove ini files, yep
      const tree = this.getTree();
      const iniFiles = tree.findAll('ini');

      function removeFilesAndFolders(obj) {
        obj.forEach(element => {
          if (element.parent) element.parent.data.isFolder = true;
          tree.remove(element);
        });
      }

      removeFilesAndFolders(iniFiles)


      if (this.kind === 'HTML') {
        const indexNode = this.findIndexNode();
        if (indexNode) {
          // this.getFileContent(indexNode);
          this.expandParentNodes(indexNode);
          indexNode.select();
        }
      }
    },

    dragStart(targetNode) {
      this.startDragPath = this.getFolderPath(targetNode).join("/");
    },

    async dragFinish(node) {
      this.treeLoading = true;
      try {
        const suffix =
          node.children.length > 0 || node.data.isFolder ? "/" : "";

        const oldPath = this.startDragPath + suffix;
        const newPath = this.getFolderPath(node).join("/") + suffix;

        const canMove =
          !node.parent ||
          node.parent.children.length > 1 ||
          node.parent.data.isFolder;
        const samePath = oldPath === newPath;
        if (canMove && !samePath) {
          await apiExplorer.renameFile(
            this.taskId,
            oldPath,
            newPath);
        }

        // await this.fetchFilesTree(this.project);
      } catch (error) {
        this.reportError(error);
      } finally {
        this.treeLoading = false;
      }
    },

    async getFileContent(node) {
      if (node.children.length > 0 || node.data.isFolder) {
        return;
      }

      // Special case for pseudo file 'main.py'
      if (this.isPyGameMainPy(node.text)) {
        this.$emit("openCode");
        return;
      }

      try {
        const path = this.getFolderPath(node);
        const pathToFolder = path.join("/");
        const name = node.text;

        const nonEditableFiles = /(.+)+(.jpeg|.jpg|.png|.gif|.bmp|.ico)/i;
        if (nonEditableFiles.test(name)) {
          this.$emit("fileSelected", {
            name,
            content,
            previewable: true,
            url: node.data.url
          });

          return;
        }

        const notSupportedFiles = /(.+)+(.aseprite|.DS_Store|.unityweb|.exe|.data|.wasm|.rar|.gz|.zip|.mp4|.pdf)/i;
        if (notSupportedFiles.test(name)) {
          this.$emit("fileSelected", {
            name,
            content,
            notSupported: true,
            url: node.data.url
          });

          return;
        }

        const content = await apiExplorer.getFileContent(this.taskId, pathToFolder);
        this.$emit("fileSelected", {
          name,
          content,
          editable: true,
          url: node.data.url,
          pathToFolder
        });
      } catch (e) {
        // this.$emit("fileSelected", {
        //   name,
        //   notSupported: true,
        //   url: node.data.url
        // });
      }
    },

    async getZipFolder() {
      try {
        const url = await apiExplorer.getZipTask(this.taskId)
        window.open(url, "_blank");
      } catch (error) {
        this.reportError(error)
      } 
    }
  },
  async mounted() {
    this.fetchFilesTree(this.studentFiles)
  }
}
</script>

<style lang="scss" scoped>
.explorer {
  &-scrollbar {
    height: calc(100vh - 330px);
    &::v-deep .ps__rail-y {
      z-index: 1;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-content {
    min-width: 220px;
    max-width: 220px;
    background: #fff;
    padding: 24px 16px;
    border-right: 1px solid #bfbfbf;
    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
    }
  }
  &-item-name {
    vertical-align: middle;
    margin-left: 3px;
    font-size: 13px;
  }
  &-remove {
    opacity: 0.3;
    min-width: 12px;
    max-width: 12px;
    margin-right: 4px;

    &:hover {
      opacity: 1;
    }

    &--disabled {
      opacity: 0;

      &:hover {
        opacity: 0;
      }
    }
  }
  &__icon {
    min-width: 24px;
    max-width: 24px;
  }
  &-download {
    width: 100%;
    margin-bottom: 25px;
    &__icon {
      margin-right: 10px;
    }
    &:hover {
      .explorer-download__icon::v-deep path {
        stroke: #fff;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .explorer {
    &-content {
      min-width: 100%;
      max-width: 100%;
      padding-bottom: 0;
      &-buttons {
        width: 200px;
        margin: auto;
        margin-bottom: 15px;
      }
    }
    &-scrollbar {
      height: calc(100vh - 325px);
    }
    &-download {
      width: 200px;
      margin: auto;
      margin-bottom: 15px;
    }
  }
}
</style>