<template>
  <div class="task-comments">
    <div class="task-comments-item">
      <img
        class="task-comments-item__image"
        src="https://backoffice.kodland.org/uploads/student_photos/Logo_Kodland_sq_3RP81Tb.png">
      <div>
        <span class="task-comments-item__name">{{user.first_name}} {{user.last_name}} ({{$t('pageTask.comments.teacher')}})</span>
        <span class="task-comments-item__text">{{user.comment}}</span>
        <span class="task-comments-item__date">{{commentDate}}</span>
      </div>
    </div>
  </div>
  
</template>

<script>
import Dates from "@/helpers/Dates"
export default {
  props: {
    user: {
      type: Object,
      required: false
    }
  },
  computed: {
    commentDate() {
      return new Dates(this.user.datetime).getNotificationFormat()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.task-comments {
  // padding: 30px 0 0 24px;
  &-item {
    display: grid;
    grid-template-columns: 48px 1fr;
    column-gap: 16px;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    margin-top: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    &__image {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
    }
    &__name {
      display: block;
      font-size: 17px;
      font-family: $f700;
      margin-bottom: 8px;
    }
    &__text {
      display: block;
      font-size: 14px;
      margin-bottom: 8px;
    }
    &__date {
      display: block;
      color: #909090;
      font-size: 12px;
    }    
  }
  .ps {
    height: calc( 100vh - 206px )
  }
}
</style>