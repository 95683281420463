<template>
  <div>
    <div :class="
      ['task-rate',
      {'task-rate--radius': borderRadius}]">
      <span class="task-rate__title">{{$t('pageTask.windowTask.likeTask')}}</span>
      <span
        class="task-rate-button"
        @click="rateTask(true)"
        v-tooltip="$t('pageTask.windowTask.likeFeedback')">
        <inline-svg class="task-rate-button__icon" :src="require('@/assets/svg/task-rate.svg')"/>
      </span>
      <span
        class="task-rate-button task-rate-button--dislike"
        @click="rateTask(false)"
        v-tooltip="$t('pageTask.windowTask.disLikeFeedback')">
        <inline-svg class="task-rate-button__icon" :src="require('@/assets/svg/task-rate.svg')"/>
      </span>
    </div>
  </div>
</template>

<script>
import apiTask from '@/api/task'

export default {
  name: 'TaskLike',
  props: {
    taskId: {
      type: Number,
      required: true
    },
    borderRadius: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  methods: {
    rateTask(value) {
      apiTask.taskFeedback(this.taskId, value)
      this.$toast.open(this.$t('pageTask.windowTask.thanksReview'));
      this.$emit('closeFeedback')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
.task-rate {
  display: flex;
  align-items: center;
  height: 84px;
  background: #fff;
  border-top: 1px solid #D9D9D9;
  padding: 0 20px;
   @media(max-width: 1155px) {
     padding: 0 10px;
   }
  &--relative {
    position: relative;
    bottom: auto;
    right: auto;
    left: auto;
  }
  &--radius {
    border-radius: 0 0 8px 8px;
  }
  &__title {
    font-family: "JetBrainsMonoExtraBold";
    font-size: 18px;
    color: #000;
    margin-right: 32px;
    @media(max-width: 1155px) {
      margin-right: 5px;
      font-size: 15px;
    }
  }
  &-button {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
    transition: 0.2s;
    @media(max-width: 1155px) {
      min-width: 35px;
      max-width: 35px;
    }
    &--dislike {
      transform: scale(1, -1);
    }
    &:hover {
      background: $primary;
      & .task-rate-button__icon::v-deep path {
        fill: #fff;
      }
    }
  }
}
</style>