<template>
  <vue-editor  v-model="editorContent" :editorToolbar="customToolbar" :placeholder="$t('createTask.editorPlaceholder')"></vue-editor>
</template>

<script>
export default {
  data() {
    return {
      editorContent: '',
      customToolbar: [
        ["bold", "italic", "underline"], 
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image"],
        [{ color: [] }],
      ]
    }
  },
  props: {
    textTask: {
      type: String,
      required: false,
      default: ''
    }
  },
  created() {
    this.editorContent = this.textTask
  },
  methods:  {
    ClearTextEditor() {
      this.editorContent = ''
    }
  },
  watch: {
    editorContent(value) {
      let textWithDeleteTag = value.slice(3, -4)
      this.$emit('transferTaskDescription', textWithDeleteTag)
    },
  },
}
</script>

<style lang="scss" scoped>
.quillWrapper{
 height: calc(100% - 48px)
}
</style>