<template>
  <div>
    <!-- 
    data - все поля из даты
    в model - лежат все v-model со страниц которые оборачиваем этим компонентом
    computed и methods все computed и методы
    -->
   <slot 
    name="taskRenderlessComponent"
    :data="$data"
    :model="model"

    :computed="{
      isScratch, 
      isBase, 
      isCloud, 
      isUnity, 
      isTurtle, 
      isPygame, 
      isWebsite, 
      isChat, 
      isLink, 
      inPreviewMode, 
      inEditMode, 
      isNotSelectedFile, 
      inNotSupportedMode,
      showFileExplorer }"

    :methods="{
      forceRateTask,
      changeTaskFields,
      saveTaskSettings,
      toggleGradeSwitch,
      callSettingsModal, 
      cancelModalTask, 
      cancelDelete, 
      deleteTask, 
      callModal, 
      callCheckModal, 
      checkTask, 
      closeModalTask, 
      changeStepBreadcrumbs, 
      clearSelectFile, 
      fileExplorerOpenProjectCode, 
      reviewTask, 
      updateWidth,
      updateHeight, 
      onChangeCode, 
      changeDecisionCode,
      toggleShowTaskCheck, 
      readySplit, 
      changeTaskNumber, 
      openRereviewTask, 
      goToStep, 
      openResetTask,
      closeRereviewTask, 
      closeResetTask, 
      closeModalReview, 
      toggleOpenFiles, 
      resetProgressTask, 
      fileExplorerSelected,
      run, 
      save,
      transferTaskDescription }"
    />
  </div>
</template>
<script>
import apiLesson from '@/api/lesson'
import apiNotifications from '@/api/notifications'
import apiExplorer from '@/api/explorer'
import apiTask from '@/api/task'
import apiCourse from '@/api/course'
import config from '@/config.js'
import store from '@/store'
import Dates from "@/helpers/Dates"

const FILE_EXPLORER_MODE_EDIT = "EDIT";
const FILE_EXPLORER_MODE_PREVIEW = "PREVIEW";
const FILE_EXPLORER_MODE_NOT_SUPPORTED = "NOT_SUPPORTED";

export default {
  data() {
    return { 
    ref: this.$parent.$refs,
    studentName: null,
    isCheck: true, 
    openFiles: true,
    tabContentEditor: false,
    studentProgress: false,
    progressId: null,
    solution: '',
    passedTaskTime: null,
    numberStep: null,
    numberTask: null,
    model: {
      result: '',
      taskData: {
        taskName: '',
        buttonName: '',
        taskAddress: '',
        editorContent: '',
        taskText: '',
      },
    },
    loader: false,
    isMobile: false,
    task: '',
    code: '',
    decisionCode: '',
    disableSave: false,
    isSendOnReview: false,
    tempProgress: '',
    studentFiles: [],
    scratchSrc: config.SCRATCH_FRONT_URL,
    accessFeedback: true,
    taskLength: null,
    paginatedTask: 1,
    tasks: {},
    showTaskCheck: true,
    mheight: 0,
    heightEditor: 0,
    splitEditorWidth: 70,
    splitEditorHeight: 70,
    files: [],
    fileExplorerMode: FILE_EXPLORER_MODE_EDIT,
    currentFile: null,
    currentFileUrl: '',
    currentFilePath: '',
    turtleOutput: "",
    reviewTaskLoad: false,
    editableCode: true,
    previewUrl: '',
    loadContent: false,
    taskCheckContentHeight: 200,
    FieldsOfChangeTask: {
      taskName: null,
      taskNumber: null,
      taskType: 'Practice',
      maxTaskScore: null,
      isGraded: false,
    },
    breadcrumbs: [],
    teacherBreadcrumbs: [],
    taskComment: {
      comment: '',
      first_name: '',
      last_name: '',
      avatar: '',
      datetime: ''
    },
    studentTaskGrade: null,
    }
  },
   async mounted() {
    this.loader = true
    // typePage следим проверка ли это сдача или создание задачи
    this.studentRedirect()
    this.mobileCheck()
    await this.getTask()
    if (this.userRole === 'student') { 
      await this.getFeedbackTask()
      await this.createStudentBreadcrumbs()
    }
    this.loader = false
    if(!this.isMobile && this.typePage !== 'CreateTaskLink') {
      setTimeout(() => {
      this.updateWidth()
      if (!JSON.parse(localStorage.getItem('showTaskPageCheck'))) {
        this.splitEditorWidth = 100
        localStorage.setItem('splitEditorWidth', 100)
        this.showTaskCheck = false
      }
      this.code = this.$t('pageTask.fileExplorer.сhoiseFile')
      // Проверка на то, на последней ли задаче урока студент
      // Если да - показывать ему модалку с оценкой урока

      //Если это только не страница для методиста
      if (this.userRole === 'student') this.openFeedbackLesson()
    }, 300);
    }
      if (localStorage.getItem('showTaskPageCheck')) {
        this.showTaskCheck = JSON.parse(localStorage.getItem('showTaskPageCheck'))
      }
    this.calcMobileHeight();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    async forceRateTask(grade) {
      grade === 'max' ? 
      await apiTask.gradeTask(this.progressId, this.task.max_grade) : 
      await apiTask.gradeTask(this.progressId, this.task.min_grade)
      await apiNotifications.setNotification(this.$route.query.user_id, this.$route.query.task_id)
    },
    studentRedirect() {
      const replacePath = `/${this.typePage}?task_id=${this.$route.query.task_id}&user_id=${this.$store.state.user.user.id}`
      if (this.userRole === 'student' && replacePath !== this.$route.fullPath) {
        this.$router.push(replacePath)
      }
    },
    async changeTaskFields() {
      const isTheory = this.FieldsOfChangeTask.taskType === 'Practice' ? false : true
      const changeTaskObject = {
        taskTitle: this.FieldsOfChangeTask.taskName || this.model.taskData.taskName,
        taskNumber: this.FieldsOfChangeTask.taskNumber,
        taskText: this.model.taskData.taskText,
        isTheory,
        isGraded: this.FieldsOfChangeTask.isGraded,
        maxGrade: this.FieldsOfChangeTask.maxTaskScore,
      };
      if (this.decisionCode) Object.assign(changeTaskObject, {decisionCode: this.decisionCode})
      else {
        Object.assign(changeTaskObject, {
          buttonName: this.model.taskData.buttonName,
          linkAdress: this.model.taskData.taskAddress
        });
      }
      await apiTask.changeTaskFields(this.$route.query.task_id, changeTaskObject)
      this.$toast.open(this.$t('pageTask.saveSuccessfully'))
    },
    transferTaskDescription(value) {
      this.model.taskData.taskText = value
    },
    // На странице создания задачи вызываем модалку настроек по задаче или удаления задачи
    callModal(type) {
      if(type === 'settings') this.ref.settingsModal.openModal()
      if(type === 'delete') this.ref.deleteModal.openModal()
    },
    // удаляем данные по задаче
    async deleteTask() {
      // Удаляем оценку
      this.FieldsOfChangeTask.maxTaskScore = null
      // меняем тип задачи на дефолтный
      this.FieldsOfChangeTask.taskType = 'Practice'
      // чистим текстовый редактор
      this.ref.TextEditor?.ClearTextEditor()
      // чистим хлебные крошки
      await apiTask.deleteTask(this.$route.query.task_id)
      // const indexBreadCrumbsTasks = this.breadcrumbs.findIndex(el => el.id === 'list-tasks')
      // this.breadcrumbs[indexBreadCrumbsTasks].list?.shift()
      this.ref.deleteModal.hideModal()
    },
    // отмена удаления задачи
    cancelDelete() {
      this.ref.deleteModal.hideModal()
    },
    toggleGradeSwitch(bollean) {
      this.FieldsOfChangeTask.isGraded = bollean
    },
    //Сохраняем данные по новой задаче на createTask
    saveTaskSettings(parameters) {
      // максимальный возможный балл
      this.FieldsOfChangeTask.maxTaskScore = parameters.maxScore
       // тип задачи
      this.FieldsOfChangeTask.taskType = parameters.typeTask
      this.FieldsOfChangeTask.taskName = parameters.taskName
      this.FieldsOfChangeTask.taskNumber = parameters.taskNumber
      // добавляем название задачи в хлебные крошки


      // if (this.nameNewTask) {
      //   const indexBreadCrumbsTasks = this.breadcrumbs.findIndex(el => el.id === 'list-tasks')
      //   this.breadcrumbs[indexBreadCrumbsTasks].list?.shift()
      //   this.breadcrumbs[indexBreadCrumbsTasks].list.push(
      //     { 
      //       current: true,
      //       name: `${this.numberNewTask}. ${this.nameNewTask}`,
      //       number: this.numberNewTask,
      //     }
      //   )
      // }

      this.ref.settingsModal.hideModal()
    },
    // при нажатии на кнопку отмена в модалке настройки задачр
    cancelModalTask() {
      this.ref.settingsModal.hideModal()
    },
        // вызов модалки с настройкой
    callSettingsModal() {
      this.ref.settingsModal.openModal()
    },
    //открытие модалки для оценки задания методистом
    callCheckModal() {
      this.ref.ratingModal.openModal()
    },
        // здесь будет запрос для оценки задачи
    async checkTask(gradeData) {
      this.loader = true
      await apiTask.gradeTask(this.progressId, gradeData.taskGrade, gradeData.taskComment)
      await apiNotifications.setNotification(this.$route.query.user_id, this.$route.query.task_id)
      this.loader = false
    },
      // это просто закрытие при нажатии на кнопку отмена
    closeModalTask() {
      this.ref.ratingModal.hideModal()
    },
    clearSelectFile() {
      this.fileExplorerMode = ''
    },
    fileExplorerOpenProjectCode() {
      this.fileExplorerMode = FILE_EXPLORER_MODE_EDIT;
      this.code = this.formatCode(this.task.code);
      this.currentFile = null;
      this.currentFileUrl = null;
    },
    async changeStepBreadcrumbs(step) {
      this.numberStep = step.number
      // выбор раздела в хлебных крошках
    },
    fileExplorerSelected({
      name,
      content,
      editable,
      previewable,
      notSupported,
      url,
      pathToFolder
    }) {
      this.ref.mobileTabs?.toFirstTab()
      this.loadContent = true
      setTimeout(() => {
        this.loadContent = false
        if (editable) {
          this.editableCode = true
          this.code = content;
          this.fileExplorerMode = FILE_EXPLORER_MODE_EDIT;
        }

        if (previewable) {
          this.content = null;
          this.previewUrl = url;
          this.fileExplorerMode = FILE_EXPLORER_MODE_PREVIEW;
        }

        if (notSupported) {
          this.content = null;
          this.fileExplorerMode = FILE_EXPLORER_MODE_NOT_SUPPORTED;
        }

        this.currentFile = name;
        this.currentFileUrl = url;
        this.currentFilePath = pathToFolder;
      }, 100);
    },

    changeTaskNumber(num) {
      const indexBreadCrumbsTasks = this.breadcrumbs.findIndex(el => el.id === 'list-tasks')
      this.breadcrumbs[indexBreadCrumbsTasks].list.forEach((el, index) => {
        if (index+ 1 === +num) {
          el.current = true
          el.selected = true
        } else {
          el.current = false
          el.selected = false
        } 
      })
      this.paginatedTask = num
      this.model.result = ""
    },
    goToStep(elementField) {
      this.paginatedTask = elementField.pagintedNumber
    },

    async getTask() {
      // Проверка сдано ли задание
      let progress = null
      if(this.typePage !== 'CreateTask') progress = await this.getProgress();
      if(progress) {
        // Если сдано таск берем из ответа
        this.progressId = progress.id
        this.studentProgress = true
        this.task = progress.task;
        this.code = progress.solution ? this.formatCode(progress.solution) : this.formatCode(this.task.code);
      } else {
        // Если не сдано дергаем таск
        await apiTask.getTask(this.$route.query.task_id)
        .then((task)=> {
          this.task = task;
          this.$emit('getTaskLessonid', this.task.lesson.id)
          // -----------AKIM--------
          // Проблема в в page/task - передаю из скопед-слота данные в другой скопед слот, данные передаются в дочерний,
          //  но в консоли выбрасывает ошибку, поэтому передал эмитом
          this.code = this.formatCode(task.code)
        })
      }
      // Проверяем есть ли временный 
      if(this.userRole !== 'methodologist') {
        await this.getTempProgress()
        }
      if (this.isScratch) {
        this.getScratchId();
        this.disableSave = false;
      }
      const kind = this.task.kind
      if (kind === 'UNITY' || kind === 'CLOUD' || kind === 'HTML' || kind === 'PYGAME') {
        this.fileExplorerMode = ''
        } else {
          this.fileExplorerMode = FILE_EXPLORER_MODE_EDIT
        }
    },
    
    async getProgress() {
      const progressTask = await apiTask.getProgressTask(this.$route.query.task_id,this.$route.query.user_id)
      this.solution = progressTask.solution
      this.studentTaskGrade = progressTask.grade
      // Если есть информация об оставленном комменте
      if (progressTask.grade_user) {
        this.taskComment = {
          comment: progressTask.comment,
          first_name: progressTask.grade_user.first_name,
          last_name: progressTask.grade_user.last_name,
          datetime: progressTask.grade_datetime
        }
      }
      // Если сдано
      if (progressTask !== 404) {
        this.passedTaskTime = new Dates(progressTask.local_time).getNotificationFormat()
        this.isSendOnReview = true
        return progressTask
      }
      // Если не сдано
      return false
    },
        //Получаем изменения ученика если они есть
    async getTempProgress() {
      this.tempProgress = await apiTask.getTempProgressTask(this.$route.query.task_id, this.$route.query.user_id);
      if (this.typePage === 'check-task' || this.typePage === 'CreateTask') this.teacherBreadcrumbs = this.tempProgress.breadcrumbs
      const studentName = this.tempProgress.student.first_name
      const studentSurname = this.tempProgress.student.last_name
      this.studentName = `${studentName} ${studentSurname}`
      // Если есть то отображаем в редакторе
      if(this.tempProgress) {
        if(this.tempProgress.solution) {
          this.code = this.formatCode(this.tempProgress.solution)
        }
        // Если есть измененные файлы 
        if(this.tempProgress.student_files) {
          this.studentFiles = [...this.tempProgress.student_files]
        }
      }      
    },

    // async getProgressTask() {
    //   const progressTask = await apiTask.getProgressTask(this.$route.params.id)
    //   if (progressTask.user) {
    //     this.taskComment = {
    //       comment: progressTask.comment,
    //       first_name: progressTask.grade_user.first_name,
    //       last_name: progressTask.grade_user.last_name,
    //       datetime: progressTask.grade_datetime
    //     }
    //   }

    //   if (progressTask !== 404) {
    //     this.isSendOnReview = true
    //   }

    //   const tempProgressTask = await apiTask.getTempProgressTask(this.$route.params.id)
    //   this.studentFiles = [...tempProgressTask.student_files]
    //   this.tasks = await apiLesson.getLessonsTasks(this.task.lesson.id)
    //   const feedback = await apiTask.taskCheckFeedback(this.$route.params.id)
    //   this.accessFeedback = !feedback
    // },
    async resetProgressTask() {
      this.loader = true
      await apiTask.resetProgressTask(this.$route.query.task_id)
      await this.getTask()
      this.loader = false
    },
    async reviewTask(handover) {
      if(handover) this.passedTaskTime =  new Dates().getNotificationFormat()
      this.closeRereviewTask()
      this.reviewTaskLoad = true
      // if (this.task.is_contain_files) {
       
        await apiTask.checkProgressTask(this.$route.query.task_id, this.code) // taskId, solution
      // }
      this.reviewTaskLoad = false
      this.isSendOnReview = true
    },

    toggleShowTaskCheck() {
      // открытие и закрытие окна с комменатами
      this.showTaskCheck = !this.showTaskCheck
      localStorage.setItem('showTaskPageCheck', this.showTaskCheck)
      if (this.showTaskCheck && this.splitEditorWidth === 100) {
        this.splitEditorWidth = 70
        localStorage.setItem('splitEditorWidth', 70)
      }
    },
    async getScratchId(){
      if (this.task.code != null && this.task.code.substring(0, 33) === 'https://scratch.mit.edu/projects/') {
        this.scratchSrc = 'https://scratch.mit.edu/projects/' + this.task.code.substring(33, 42) + `/embed/`;
        return;
      }

      let url = `${config.SCRATCH_BACK_URL}/student_project_meta/student_${store.state.user.user.studentInfo.student_id}/project_${this.task.id}/`;
      let res = await fetch(url);
      if(res.ok) {
        let json = await res.json();
        this.scratchSrc = `${config.SCRATCH_FRONT_URL}/?lang=${this.userLanguage}#${json.scratch_project}/`
        // this.scratchSrc = `${config.SCRATCH_FRONT_URL}/?lang=${this.userLanguage}#${json.scratch_project}/`
      }
      return res
    },
    formatCode(code) {
      if(!code) {
        return code
      }
      if(code[0]==='"') {
        code = code.substring(1,code.length)
        }
      if(code[code.length-1]==='"') {
        code = code.substring(0,code.length-1)
        }
      return code.replace(new RegExp("/LINEBREAK/", "g"), "\n");
    },
    checkCodeForChanges(){
      this.disableSave = (this.code!==this.formatCode(this.tempProgress.solution)) ? false : true 
    },
    changeDecisionCode(decisionCode) {
      this.decisionCode = decisionCode
    },
    onChangeCode(e) {
      this.code = e;
      this.checkCodeForChanges()
    },
    mobileCheck() {
      this.isMobile = (window.innerWidth > 900) ? false : true;
    },
    setFullEditorHeight(){
      this.splitEditorHeight = 100
      this.heightEditor = window.innerHeight - 132;
    },
    updateWidth($event) {
      // хранение ширины редактора в localStorage
      if ($event) {
        localStorage.setItem('splitEditorWidth', $event[0].size)
      }
      this.heightEditor = this.ref.paneEditor?.$el.clientHeight
      if(this.isPygame || this.isWebsite || this.isCloud) {
        this.setFullEditorHeight()
      }
    },
    updateHeight($event) {
      // хранение высоты редактора в localStorage
      if ($event) {
        localStorage.setItem('splitEditorHeight', $event[0].size)
      }
      this.heightEditor = this.ref.paneEditor.$el.clientHeight
      if(this.isPygame || this.isWebsite || this.isCloud) {
        this.setFullEditorHeight()
      }
    },
        readySplit() {
      // обновление данных о высоте и ширине редактора в localStorage после окончания перетаскивания
      if (localStorage.getItem('splitEditorWidth')) {
        this.splitEditorWidth = localStorage.getItem('splitEditorWidth')
      }
      if (localStorage.getItem('splitEditorHeight')) {
        this.splitEditorHeight = localStorage.getItem('splitEditorHeight')
      }
    },
    onResize() {
      this.debounce(this.mobileCheck(),500);
    },
    debounce(f, ms) {
      let isCooldown = false;
      return function() {
        if (isCooldown) return;
        f.apply(this, arguments);
        isCooldown = true;
        setTimeout(() => isCooldown = false, ms);
      };
  },
    async getFeedbackTask() {
      // Получение всех задач урока
      this.tasks = await apiLesson.getLessonsTasks(this.task.lesson.id)
      // Получение информации о том, был ли оценен урок
      const feedback = await apiTask.taskCheckFeedback(this.$route.query.task_id)
      this.accessFeedback = !feedback
    },
    async createStudentBreadcrumbs() {
      // Формируем ссылку для "Мои курсы"
      this.breadcrumbs = [
        {
          id: 'my-courses',
          name: this.$i18n.t('header.menu.myCourses'),
          link: '/',
          type: 'link',
          staticLink: true
        },
        {
          id: 'list-courses',
          type: 'dropdown',
          name: '',
          list: [],
          open: false,
          staticLink: true
        },
        {
          id: 'list-tasks',
          type: 'dropdown',
          list: [],
          open: false
        }
      ]
      const indexMyCourses = this.breadcrumbs.findIndex(el => el.id === 'my-courses')
      this.breadcrumbs[indexMyCourses].link = `/${this.lang}/courses`

      // Получаем курсы и формируем их для хлебных крошек
      const listCourses = await apiCourse.getCoursesCurrentUser()
      const mapListCourses = listCourses.map(el => {
        return {
          id: `course_${el.course}`,
          link: `/${this.lang}/course_${el.course}`,
          current: el.is_current,
          name: el.course_title,
          staticLink: true
        }
      })
      const indexBreadCrumbsCourses = this.breadcrumbs.findIndex(el => el.id === 'list-courses')
      if (mapListCourses.length > 1) {
        this.breadcrumbs[indexBreadCrumbsCourses].list = [...mapListCourses]
      } else {
        this.breadcrumbs[indexBreadCrumbsCourses].type = 'link'
        this.breadcrumbs[indexBreadCrumbsCourses].name = mapListCourses[0].name
        this.breadcrumbs[indexBreadCrumbsCourses].link = mapListCourses[0].link
      }
      // if(this.typePage === "CreateTask" || this.typePage === "CreateTaskLink") return
      // Формируем список задач для хлебных крошек
      const indexBreadCrumbsTasks = this.breadcrumbs.findIndex(el => el.id === 'list-tasks')
      for (const key in this.tasks) {
        let numberTask = Number(key) + 1
        this.breadcrumbs[indexBreadCrumbsTasks].list.push(
          { 
            id: this.tasks[key].id,
            link: `/${this.typePage}?task_id=${this.tasks[key].id}&user_id=${this.$store.state.user.user.id}`,
            current: false,
            name: `${numberTask}. ${this.tasks[key].title}`,
            staticLink: false,
            number: this.tasks[key].number,
            is_hw: this.tasks[key].is_hw
          }
        )
      }
      this.taskLength = this.breadcrumbs[indexBreadCrumbsTasks].list.length
      this.breadcrumbs[indexBreadCrumbsTasks].list[0].current = true
      this.breadcrumbs[indexBreadCrumbsTasks].list.forEach((el, idx) => {
        if (+el.id === +this.$route.query.task_id) {
          el.current = true
          this.paginatedTask = ++idx
        } else {
          el.current = false
          } 
      })
    },
    async openFeedbackLesson() {
      const indexBreadCrumbsTasks = this.breadcrumbs.findIndex(el => el.id === 'list-tasks')
      const findIndexSelectedBreadcrumbs = this.breadcrumbs[indexBreadCrumbsTasks].list.findIndex(el => el.current)
      if ( findIndexSelectedBreadcrumbs === this.breadcrumbs[indexBreadCrumbsTasks].list.filter(el => !el.is_hw).length - 1) {
        const feedback = await apiLesson.getLessonFeedback(this.task.lesson.id)
        if (feedback === 404) {
          this.ref.modalReview?.openModal()
        }
      }
    },
    calcMobileHeight() {
      this.mheight = window.innerHeight - 184;
    },
    toggleOpenFiles() {
      this.openFiles = !this.openFiles
    },
    openRereviewTask() {
      this.ref.modalRereview.openModal()
    },
    closeRereviewTask() {
      this.ref.modalRereview.hideModal()
    },
    openResetTask() {
      this.ref.modalReset.openModal()
    },
    closeResetTask() {
      this.ref.modalReset.hideModal()
    },
    closeModalReview() {
      this.ref.modalReview.hideModal()
    },
    openModal() {
      this.ref.gameModal.openModal()
    },

    outf(text) {
      this.model.result += text
      window.output = this.model.result;
      this.moveCursorToEnd();
    },

    outTurtle(text) {
      this.turtleOutput += text;
    },
   
    builtinRead(x) {
      if (
        window.Sk.builtinFiles === undefined ||
        window.Sk.builtinFiles["files"][x] === undefined
      )
        throw "File not found: '" + x + "'";
      return window.Sk.builtinFiles["files"][x];
    },

    moveCursorToEnd() {
      var consoleEl = document.getElementById("console");
      consoleEl.focus();

      if (typeof consoleEl.selectionStart == "number") {
        consoleEl.selectionStart = consoleEl.selectionEnd =
          window.output.length;
      } else if (typeof consoleEl.createTextRange != "undefined") {
        var range = consoleEl.createTextRange();
        range.collapse(false);
        range.select();
      }
    },

     resetTarget() {
      if (this.ref.canvas.firstChild) {
          while (this.ref.canvas.firstChild) {
              this.ref.canvas.removeChild(this.ref.canvas.firstChild);
          }
      }
    },

    readTextFile(path) {
      let reader = new XMLHttpRequest();
      reader.open("get", path, false);
      reader.send();

      return reader.responseText;
    },

     runPyGame() {
      let basePath = "/pygame/";

      window.Sk.externalLibraries = {
        pygame: {
          path: basePath + "__init__.js"
        },
        "pygame.display": {
          path: basePath + "display.js"
        },
        "pygame.draw": {
          path: basePath + "draw.js"
        },
        "pygame.event": {
          path: basePath + "event.js"
        },
        "pygame.font": {
          path: basePath + "font.js"
        },
        "pygame.image": {
          path: basePath + "image.js"
        },
        "pygame.key": {
          path: basePath + "key.js"
        },
        "pygame.mouse": {
          path: basePath + "mouse.js"
        },
        "pygame.time": {
          path: basePath + "time.js"
        },
        "pygame.transform": {
          path: basePath + "transform.js"
        },
        "pygame.version": {
          path: basePath + "version.js"
        }
      };
      this.ref.gameModal.openModal();
      this.resetTarget();
      window.Sk.main_canvas = document.createElement("canvas");
      this.ref.canvas.appendChild(window.Sk.main_canvas);
      this.pygameQuit=false
    },
    
    setActiveTab(tab) {
      this.activeTab = tab
    },

    run() {
      this.model.result = ""
        window.output = this.model.result
        let prog = "";
        if (this.code) {
          prog = this.code
        }
  
        if (this.isTurtle) { 
            window.Sk.configure({
              output: this.outTurtle,
              read: this.builtinRead,
              inputfunTakesPrompt: true
            });
            this.splitEditorHeight = 25;
          this.setActiveTab('result')
          var width = 0
          if(this.isMobile) {
            setTimeout(
              ()=>{
                width = document.getElementById("mturtleCanvas").offsetWidth;
                (window.Sk.TurtleGraphics ||
                  (window.Sk.TurtleGraphics = { width: width })
                ).target = "mturtleCanvas";
                console.log(window.Sk.TurtleGraphics);
                }, 0
            )
          } else {
            width = document.getElementById("turtleCanvas").offsetWidth;
            (window.Sk.TurtleGraphics ||
            (window.Sk.TurtleGraphics = { width: width })
            ).target = "turtleCanvas";
          }
          console.log(window.Sk.TurtleGraphics);
        } else if (this.isBase) {
          window.Sk.configure({
            output: this.outf,
            read: this.builtinRead,
            inputfunTakesPrompt: true
          });
        } 
        else if (this.isPygame) {
          window.Sk.configure({
            output: this.outf,
            read: this.builtinRead,
            inputfunTakesPrompt: true
          });
          // window.Sk.imgPath = `${config.SELECTEL_CONTAINER}project_${this.project.id}/`;
          // window.Sk.studentImgPath = `${config.SELECTEL_CONTAINER}project_${this.project.id}/`;
  
          let is_pgzero = prog.split("\n")[0] == "#pgzero";
  
          if (is_pgzero) {
            let wrapper = this.readTextFile("/pygame_zero/wrapper.txt");
            let main_loop = this.readTextFile("/pygame_zero/main_loop.txt");
  
            prog = wrapper + prog + main_loop;
          }
  
          this.runPyGame();
        } else if(this.isWebsite) {
          let url = this.currentFileUrl;
           console.log(url)
          fetch(url)
          .then(res => res.text())
            .then(res => {
              this.runWebsite(res,url,prog)
            })
        }
  
        window.Sk.python3 = true;
  
        window.Sk.inputfun = text => {
          return new Promise(resolve => {
            if (text != "") {
              this.model.result = window.output + text + "  ";
              window.output = window.output + text + "  ";
            }
  
            this.moveCursorToEnd();
  
            let enterClick = e => {
              if (e.keyCode == 13) {
                consoleOut.removeEventListener("keyup", enterClick);
  
                if (text != "" || window.output != "") {
                  var value = this.model.result.split(window.output);
                  var num = value[value.length - 1].replace(/\n$/, "");
  
                  if (num == "") {
                    num = window.output.replace(/\n$/, "");
                  }
                } else {
                  num = this.model.result.replace(/\n$/, "");
                }
  
                resolve(num);
                window.output = this.model.result;
              }
            };
  
            let forbidChanging = e => {
              let initialValue = window.output;
  
              if (e.target.selectionStart < initialValue.length) {
                e.preventDefault();
                return;
              }
  
              if (
                (e.which == 8 || e.which == 46) &&
                e.target.value === initialValue
              ) {
                e.preventDefault();
              }
            };
  
            let consoleOut = document.getElementById("console");
            consoleOut.addEventListener("keyup", enterClick);
            consoleOut.addEventListener("keydown", forbidChanging);
          });
        };
  
        var myPromise = window.Sk.misceval.asyncToPromise(function() {
          return window.Sk.importMainWithBody("<stdin>", false, prog, true);
        });
  
        myPromise.then(
          () => {
            console.log("success");
            if(this.isPygame) {
              this.pygameQuit=true
            }
          },
          err => {
            this.model.result = err.toString();
          }
        );
      },
  
      runWebsite(content, url, output) {
        let pre_prog = content;
        let head_tag = pre_prog.indexOf("<head");
        let head = pre_prog.match(/<head[^>]*>[\s\S]*<\/head[\s]*>/gi);
        if (head != null) {
          head_tag = pre_prog.indexOf(">", head_tag) + 1;
          output =
            pre_prog.slice(0, head_tag) +
            '<base href="' +
            url +
            '">' +
            pre_prog.slice(head_tag, pre_prog.length);
        } else {
          output = '<base href="' + url + '">' + pre_prog;
        }
        let win = window.open("", "_blank");
        win.document.write(output);
      },
    saveProjectToScratch() {
       let iframe = this.ref.scratchIframe.contentWindow;
       console.log(iframe);
       iframe.postMessage('please save project', '*');
    },
    onMessage() {
      window.onmessage = (event) => {
        if (!event.origin.startsWith(config.SCRATCH_FRONT_URL)) return;

        let data = {
            project_id: this.task.id,
            student_id: store.state.user.user.studentInfo.student_id,
            from_who: 'student',
            type: 'project'
        }
        let iframe = this.ref.scratchIframe.contentWindow;
        iframe.postMessage(data,'*')
      }
    },

    async saveFile() {
      await apiExplorer.updateFile(this.task.id,this.currentFilePath,this.code)
        .then(res => {
          if(res.data.id) {
          this.$toast.open(this.$t('pageTask.saveSuccessfully'))
          }
          })
    },
    async saveTempProgress() {
      await apiTask.saveTempProgressTask(this.$route.query.task_id,this.code)
        .then(res => {
          if(res.data.id) {
            this.$toast.open(this.$t('pageTask.saveSuccessfully'));
          } 
        })
        .catch(err => console.log(err));
    },
    
    async save() {
      if (this.isScratch) {
        this.saveProjectToScratch();
        console.log('save')
      } else {
        this.disableSave = true;
        if(this.currentFile) {
          this.saveFile()
        } else {
          this.saveTempProgress()
        }
        
      }
    },
  },
  computed: {
    isCloud: function() {return this.task.kind==="CLOUD" ? true : false},
    isUnity: function() {return this.task.kind==="UNITY" ? true : false},
    isTurtle: function() {return this.task.kind==="TURTLE" ? true : false},
    isBase: function() {return this.task.kind==="BASIC" ? true : false},
    isPygame: function() {return this.task.kind==="PYGAME" ? true : false},
    isWebsite: function() {return this.task.kind==="HTML" ? true : false},
    isScratch: function() {return this.task.kind==="SCRATCH" ? true : false},
    isChat: function() {return this.task.kind==="CHAT" ? true : false},
    isLink: function() {return this.task.kind==="LINK" ? true : false},
    showFileExplorer() {
      return this.openFiles && (this.isCloud || this.isUnity || this.isPygame || this.isWebsite) ? true : false
    },
    inPreviewMode() {
      return this.fileExplorerMode === FILE_EXPLORER_MODE_PREVIEW;
    },
    inEditMode() {
      return this.fileExplorerMode === FILE_EXPLORER_MODE_EDIT;
    },
    isNotSelectedFile() {
      return this.fileExplorerMode === ''
    },
    inNotSupportedMode() {
      return this.fileExplorerMode === FILE_EXPLORER_MODE_NOT_SUPPORTED;
    },
    lang() {
      return this.$store.state.user.language
    },
    userRole() {
      return this.$store.state.user.user.role
    },
    typePage() {
      return this.$route.name
    },
  },
  watch: {
    lang() {
      const indexMyCourses = this.breadcrumbs.findIndex(el => el.id === 'my-courses')
      this.breadcrumbs[indexMyCourses].name = this.$i18n.t('header.menu.myCourses')
    },
    async "$route.query.task_id"() {
      this.clearSelectFile()
      this.loader = true
      this.mobileCheck()
      await this.getTask()
      if (this.userRole === 'student') { 
        await this.getFeedbackTask()
        setTimeout(() => {
          this.openFeedbackLesson()
        }, 300);
      }
      this.loader = false
    },
  },
}
</script>

<style lang="scss">
  @import "@/assets/scss/var.scss";
  // .split-container {
  // height: calc(100vh - 156px);
  // }
  .tabs__item--notread {
    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background: #E55961;
      top: 11px;
      right: -13px;
      border-radius: 50%;
        @media(max-width: 900px) {
          display: none;
        }
    }
  }

  .not-supported-msg {
  text-align: center;
  display: block;
  margin-top: 20px;
  }
  .code-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  &-notselected {
    display: block;
    text-align: center;
    margin-top: 30px;
  }
  }
  .editor-wrapper {
  z-index: 0;
  }
  .btn--reload {
  max-width: 668px;
  }
  .content-center {
  display: flex;
  justify-content: center;
  }
  .reload-icon {
  margin-right: 10px;
  }
  .split-pagination {
  padding: 8px 0;
  width:100%; 
  background: #D9D9D9;
  // position: absolute;
  // bottom: 0;
  z-index: 1;
  &_arrows{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_arrow{
    cursor: pointer;
    &:first-child{
      margin-right: 26px;
    }
    &:last-child{
      margin-left: 26px;
    }
  }
  }
.splitpanes {
  &__pane {
    position: relative;
    transition: none !important;
  }
  &--horizontal {
    & > .splitpanes__splitter {
      min-height: 8px;
      background: #ACB2BE;
      position: relative;
      &:before {
        content: '';
        display: block;
        background: #FFFFFF;
        border-radius: 8px;
        height: 4px;
        width: 48px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }
  }
  &--vertical {
    & > .splitpanes__splitter {
      min-width: 8px;
      background: #ACB2BE;
      position: relative;
      &:before {
        content: '';
        display: block;
        background: #FFFFFF;
        border-radius: 8px;
        width: 4px;
        height: 48px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }
  }
}
  .task {
  &-check {
    height: 100%;
    position: relative;
    &-header {
      display: flex;
      align-items: stretch;
      height: 52px;
      border-bottom: 1px solid #D9D9D9;
      padding: 0 20px;
      margin-bottom: 30px;
      position: relative;
      &__link {
        color: $grey;
        font-family: "InterSemiBold";
        margin-right: 16px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        &--active {
          color: $primary;
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            bottom: 0;
            background: $primary;
          }
        }
        &--notread {
          &:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background: #E55961;
            top: 11px;
            right: -13px;
            border-radius: 50%;
          }
        }
      }
    }
    &-content {
      // padding: 0 20px;
    }
  }
  &-content {
    display: flex;
  }
  &-content__scratch {
    display: block;
  }
  }
  .tab {
  &-trigger {
    color: #ACB2BE;
    border-bottom: 4px solid transparent;
    padding: 0 8px;
    align-items: center;
    display: flex;
    cursor: pointer;
    &_active {
      color: $primary;
      border-bottom: 4px solid $primary;
    }
  }
  }
  .btn {
  &__mobile-icon {
    display: none;
    @media(max-width: 1320px) {
      display: inline-block;
    }
  }
  }
  .mtask {
    &-content {
      overflow-y: auto;
    }
    &-btns {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      height: 72px;
      padding: 0 16px;
      &__left {
        display: flex;
        position: relative;
        &:before {
          content: "";
          display: inline-block;
          height: 32px;
          position: absolute;
          right: 0;
          top: 4px;
          border-right: 1px solid $grayBorder;
        }
        &--line {
          &:before {
            display: none;
          }
        }
        .btn {
          margin-right: 16px;

        }
      }
      &__right {
        display: flex;
        flex: 1;
        margin-left: 16px;
        align-items: center;
        .btn {
          &--pass {
            flex: 1
          }
        }
      }
    }
    
  }
  .console {
  background: #1C1C1C;
  color: #fff;
  padding: 16px;
  height: 100%;
  overflow-y: auto;
  }
  .project-console {
  background: #1C1C1C;
  color: #fff;
  padding: 16px;
  height: 100%;
  overflow-y: auto;
  border: 0;
  width: 100%;
  resize: none;
  }
  .full-width {
    width: 100%;
  }
  .mtask {
  &-result,
  &-description {
    margin: 25px 24px 0;
    &-title {
      font-family: "InterBold";
      font-size: 18px;
    }
    // &-subtitle {
    //   margin-top: 16px;
    // }
  }
  &-comments,
  &-vote {
    padding: 16px;
  }
  &-vote {
    width: 100%;
    position: absolute;
    background: #fff;
    border-top: 1px solid $grayBorder;
    border-bottom: 1px solid $grayBorder;
    bottom: 60px;
    left: 0;
    justify-content: space-between;
    font-family: "InterSemiBold";
    &__question {
      display: flex;
      align-items: center;
      display: none;
    }
    &__answer {
      text-align: center;
    }
    &__close {
      position: absolute;
      right: 16px;
      top: 16px;
    }
    &__btns {
      display: flex;
      .btn {
        margin-left: 16px;
      }
    }
  }
  }
  .turtle-canvas {
  min-height: 455px;
    width: 100%;
    border: 1px solid #d9d9d9;
  }
  .scratch-iframe {
  height: calc(100vh - 135px);
  }
  .scratch-resolution-error {
  text-align: center;
  width: 100%;
  padding: 30px 10px;
  }
  .task-header{
    &-rate__icon{
      min-width: 22px;
      margin-left: 9px;
      fill:#141F43;
    }
      &_save-button {
      position: relative;
      margin-right: 8px;
    }
      &-start {
    margin-right: 8px;
    @media(max-width: 900px) {
      width: 40px;
    }
    @media(max-width: 1320px) {
      span {
        display: none;
      }
    }
    &__icon {
      margin-left: 10px;
      @media(max-width: 1320px) {
          margin-left: 0;
      }
    }
  }
  &-restart {
    margin-right: 8px;
  }
  }

  .sidebar {
  &-task-container {
    padding-right: 5px;
  }
  }

</style>