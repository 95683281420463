<template>
  <div>
    <span class="feedback__title">{{$t('pageTask.feedback.title')}}</span>
    <StarRating @saveRate="setupRate"/>
    <div v-if="rate > 0">
      <span class="feedback__question" v-if="positiveRating">{{$t('pageTask.feedback.questions.whatLike')}}</span>
      <span class="feedback__question" v-else>{{$t('pageTask.feedback.questions.whatDislike')}}</span>
      <label
        class="checkbox checkbox--bold"
        v-for="cause in causes"
        :key="cause.id">
        <input type="checkbox" class="checkbox__input" v-model="cause.selected">
        <span class="checkbox__check"></span>
        <span class="checkbox__check__text">{{cause.text}}</span>
      </label>
      <textarea
        v-if="isOtherChecked.selected"
        v-model="comment"
        class="textarea feedback__textarea"
        placeholder="Напишите свой вариант"
        maxlength="300"></textarea>
      <button class="btn btn--primary btn--center" @click="postFeedback" :disabled="sendPostFeedback">{{$t('pageTask.feedback.gradeButton')}}</button>
    </div>
    
  </div>
</template>

<script>
import apiLesson from '@/api/lesson.js'
import StarRating from '@/components/StarRating'
export default {
  name: 'rating',
  components: {
    StarRating
  },
  props: {
    lessonId: {
     required: false
    }
  },
  data() {
    return {
      causes: [
        {id: 'mark_teachers_work', text: this.$t('pageTask.feedback.reasons.teacherWork'), selected: false},
        {id: 'mark_practical_assignments', text: this.$t('pageTask.feedback.reasons.tasks'), selected: false},
        {id: 'mark_lesson_topic', text: this.$t('pageTask.feedback.reasons.lessonTopic'), selected: false},
        {id: 'mark_lesson_benefit', text: this.$t('pageTask.feedback.reasons.lessonBenefit'), selected: false},
        {id: 'mark_other', text: this.$t('pageTask.feedback.reasons.other'), selected: false},
      ],
      rate: 0,
      comment: '',
      sendPostFeedback: false
    }
  },
  computed: {
    positiveRating() {
      return this.rate > 3 ? true : false
    },
    isOtherChecked() {
      return this.causes.find(el => el.id === 'mark_other')
    }
  },
  methods: {
    setupRate(value) {
      this.rate = value
    },
    async postFeedback() {
      this.sendPostFeedback = true
      // Собираем все параметры перед передачей их на сервер
      const params = {
        lesson: this.lessonId,
        grade: this.rate,
        comment: this.comment
      }
      const selectedCauses = this.causes.filter(el => el.selected === true)
  
      for (let key in selectedCauses) {
        params[selectedCauses[key].id] = true
      }

      await apiLesson.postLessonFeefback(params).then(() => {
        this.$toast.open(this.$t('pageTask.feedback.notificationText'));
      })

      this.sendPostFeedback = false

      this.$emit('closeModalReview')
    }
  },
}
</script>

<style lang="scss" scoped>
.feedback {
  &__title {
    display: block;
    text-align: center;
    margin-bottom: 24px;
    font-family: "InterBold";
    font-size: 24px;
  }
  &__question {
    margin: 24px 0;
    display: block;
    text-align: center;
  }
  &__textarea {
    margin-bottom: 24px;
  }
}
</style>