<template>
  <div :class="['file-title', {'file-title--open': isopen}]" @click="openFiles">
    <div class="file-title__text">{{$t('pageTask.fileExplorer.files')}}</div>
    <inline-svg class="file-title__icon" :src="require('@/assets/svg/file-title-arrow.svg')"/>
  </div>
</template>

<script>
export default {
  name: "FileExplorerHeader",
  data() {
    return {
      isopen: true,
    }
  },
  props: {
    opened: {
      type: Boolean
    }
  },
  methods: {
    openFiles() {
      this.isopen = this.opened
      this.$emit('openFiles')
    }
  }
};
</script>

<style lang="scss" scoped>
.file-title {
  font-family: "InterSemiBold";
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 25px;
  padding-right: 27px;
  border-right: 1px solid #bfbfbf;
  height: 48px;
  margin-left: -32px;
  padding-left: 32px;
  max-width: 144px;
  min-width: 144px;
  transition: 0.2s;
  &--open {
    min-width: 220px;
    max-width: 220px;
    & .file-title__icon {
      transform: rotate(0deg);
    }
  }
  &__text {
    margin-right: 15px;
  }
  &__icon {
    transform: rotate(180deg);
    transition: 0.2s;
  }
}

@media screen and (max-width: 900px) {
  .file-title {
    display: none;
  }
}
</style>
