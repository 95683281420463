<template>
  <div>
    <div :class="['chat-element', {'student' : message.student}]" >
      <img
        v-if="!message.student"
        class="chat-element__avatar"
        src="/images/chat-avatar-robot.png"
        srcset="/images/chat-avatar-robot2x.png 2x"
        alt="robot">
      <div class="chat-element-message">
        <inline-svg
          v-if="message.student"
          class="chat-element-message__remove"
          :src="require('@/assets/svg/task-close-comments.svg')"
          @click="deleteMessage(i)"
        />
        <img class="chat-element-message__tail" v-if="message.student" :src="require('@/assets/svg/task-chat-tail-student.svg')">
        <img class="chat-element-message__tail" v-else :src="require('@/assets/svg/task-chat-tail.svg')">
        <span class="chat-element-message__name" v-if="!message.student">{{this.$t('pageTask.windowTask.chat.robot')}}</span>
        <span class="chat-element-message__text">{{message.text}}</span>
        <a class="chat-element-message-file" v-for="(file, i) of message.files" :key="i" href="#">
          <inline-svg
            class="chat-element-message-file__icon"
            :src="require('@/assets/svg/icon-file.svg')"/>
          <span>{{file.name}}</span>
        </a>
        <span class="chat-element-message__date">{{message.date}}</span>
      </div>
      </div>
  </div>
</template>

<script>
export default {
  props: ['message']
}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

  .chat-element {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
    &.student {
      justify-content: flex-end;
      & .chat-element-message {
        background: $primary;
        color: #fff;
        & .chat-element-message__tail {
          left: auto;
          right: -10px;
        }
        & .chat-element-message__text {
          margin-right: 25px;
        }
      }
    }
    &__avatar {
      min-width: 56px;
      max-width: 56px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 24px;
    }
    &-message {
      position: relative;
      padding: 24px 24px 24px 24px;
      border-radius: 32px;
      background: #BCE2FE;
      color: #141F43;
      max-width: 960px;
      &__remove::v-deep {
        position: absolute;
        right: 24px;
        top: 11px;
        width: 11px;
        height: 11px;
        cursor: pointer;
        & path {
          fill: #fff;
        }
      }
      &-file {
        font-size: 14px;
        background: rgba(255, 255, 255, .8);
        display: block;
        align-items: center;
        border-radius: 16px;
        padding: 11px 16px;
        color: #141F43;
        transition: 0.2s;
        margin-bottom: 10px;
        &:hover {
          opacity: .8;
        }
        &__icon {
          margin-right: 11px;
        }
      }
      &__tail {
        position: absolute;
        bottom: 0px;
        left: -10px;
      }
      &__text {
        display: block;
        margin-bottom: 16px;
        font-size: 18px;
      }
      &__date {
        display: block;
        text-align: right;
        font-size: 14px;
      }
      &__name {
        display: block;
        margin-bottom: 12px;
        font-size: 18px;
        color: $primary;
        font-family: "InterBold";
      }
    }
  }
</style>