<template>
  <div>
    <TaskGeneral>
      <!-- Принимаю все данные и методы из дочернего компонента  TaskGeneral и собираю их в один пропс : "taskComponent" -->
      <template #taskRenderlessComponent="taskComponent" >
        <Header
          :breadcrumbs="taskComponent.data.breadcrumbs"
          :teacherBreadcrumbs="taskComponent.data.teacherBreadcrumbs"
          />
        <Loader v-if="taskComponent.data.loader" color="primary" position="center"/>
        <div v-else>
          <modal 
            ref="ratingModal"
            :showFooter="false"
            :size="'medium'"
            :showExpand="false"
            :outsideModalCloseIcon="true">
            <template #content>
              <TaskRate
                :numberTask="taskComponent.data.task.number"
                :studentName="taskComponent.data.studentName"
                :maxScore="taskComponent.data.task.max_grade || null"
                :minScore="taskComponent.data.task.min_grade || null"
                @checkTask="taskComponent.methods.checkTask"
                @closeModalTask="taskComponent.methods.closeModalTask"
              />
            </template>
          </modal>
          <TaskHeader 
            @run="taskComponent.methods.run()"
            @toggleTaskCheck="taskComponent.methods.toggleShowTaskCheck"
            @toggleOpenFiles="taskComponent.methods.toggleOpenFiles"
            @callCheckModal="taskComponent.methods.callCheckModal"
            :teacherCheck="false"
            :studentName="taskComponent.data.studentName"
            :maxGrade="taskComponent.data.task.max_grade || null"
            :minGrade="taskComponent.data.task.min_grade || null"
            :taskKind="taskComponent.data.task.kind"
            :showTaskCheck="taskComponent.data.showTaskCheck"
            :studentProgress="taskComponent.data.studentProgress"
            :role="taskComponent.computed.userRole"
            :isPygame="taskComponent.computed.isPygame"
            :isCheck="taskComponent.data.isCheck"
            :studentTaskGrade="taskComponent.data.studentTaskGrade"
            :passedTaskTime="taskComponent.data.passedTaskTime">
            <template v-if="taskComponent.data.studentProgress && (taskComponent.computed.userRole !== 'manager')">
              <button   
                @click="taskComponent.methods.forceRateTask('min')"
                class="btn btn--min button-rate btn--header-small task-header__handover">
                <span class="button-rate-text">{{$t('pageTask.teacherFeedback.min')}}</span>
                <inline-svg class="task-header-rate__icon task-header-rate__icon--dislike" :src="require('@/assets/svg/task-rate.svg')"/>
              </button>
              <button 
                @click="taskComponent.methods.forceRateTask('max')"
                class="btn btn--max button-rate btn--header-small task-header__handover">
                <span class="button-rate-text">{{$t('pageTask.teacherFeedback.max')}}</span> 
                <inline-svg class="task-header-rate__icon" :src="require('@/assets/svg/task-rate.svg')"/>
              </button>
              <button 
                @click="taskComponent.methods.callCheckModal" 
                class="btn btn--primary btn--header-small task-header__handover">
                <span v-if="!taskComponent.data.studentTaskGrade">{{$t('checkTask.grade')}}</span>
                <span v-else>{{$t('checkTask.changeGrade')}}</span>
              </button>
            </template>
          </TaskHeader>
          <div class="task-content__scratch" v-if="taskComponent.computed.isScratch">
            <iframe :src="taskComponent.data.scratchSrc"  
              width="100%" 
              height="100%" 
              ref="scratchIframe" 
              class="scratch-iframe" 
              allow="camera;microphone" 
              v-if="!taskComponent.data.isMobile"
            />
            <h2 v-if="taskComponent.data.isMobile" class="scratch-resolution-error">{{$t('pageTask.sorryDevice')}} :(</h2>
          </div>
          <div v-if="!taskComponent.data.isMobile && !taskComponent.computed.isScratch">
            <div class="task-content">
              <FileExplorerContent
                v-if="taskComponent.computed.showFileExplorer"
                :taskId="taskComponent.data.task.id"
                :kind="taskComponent.data.task.kind"
                :studentFiles="taskComponent.data.studentFiles"
                @fileSelected="taskComponent.methods.fileExplorerSelected"
                @openCode="taskComponent.methods.fileExplorerOpenProjectCode"
                @clearFileSelected="taskComponent.methods.clearSelectFile"
              />
              <splitpanes class="split-container" vertical @resize="taskComponent.methods.updateWidth" @ready="taskComponent.methods.readySplit">
                <pane :size="taskComponent.data.splitEditorWidth" >
                  <div  :class="{'editor-wrapper': !taskComponent.computed.isChat}"> 
                    <TaskChat :minusHeight="250" v-if="taskComponent.computed.isChat"/>
                    <splitpanes v-else class="split-container" horizontal @resize="taskComponent.methods.updateHeight" @ready="taskComponent.methods.readySplit">
                      <pane ref="paneEditor" :size="taskComponent.data.splitEditorHeight">
                        <div class="code-wrapper">
                          <loader v-if="taskComponent.data.loadContent" position="center" color="primary" typePosition="absolute"/>
                          <div v-else>
                            <span class="code-wrapper-notselected" v-if="taskComponent.computed.isNotSelectedFile && (taskComponent.computed.isCloud || taskComponent.computed.isWebsite || taskComponent.computed.isUnity || taskComponent.computed.isPygame)">
                              {{$t('pageTask.fileExplorer.сhoiseFile')}}
                            </span>
                            <img v-if="taskComponent.computed.inPreviewMode" :src="taskComponent.data.previewUrl" width="500">
                            <code-editor 
                              v-if="taskComponent.computed.inEditMode"
                              :height="taskComponent.data.heightEditor" 
                              :code="taskComponent.data.code"
                              v-on:code-change="taskComponent.methods.onChangeCode" 
                            />
                            <span class="not-supported-msg" v-if="taskComponent.computed.inNotSupportedMode">
                              {{$t('pageTask.fileExplorer.errors.NotSupported')}}
                            </span>
                          </div>
                        </div>
                      </pane>
                      <pane min-size="10" :size="100 - taskComponent.data.splitEditorHeight" v-if="taskComponent.computed.isBase"> 
                        <textarea class="project-console" id="console" v-model="taskComponent.model.result" />
                      </pane>
                      <pane min-size="10" :size="100 - taskComponent.data.splitEditorHeight" v-if="taskComponent.computed.isTurtle">
                        <Tabs>
                          <Tab :title="$t('pageTask.tabs.result')">
                            <div id="turtleCanvas" class="turtle-output">
                                {{ taskComponent.data.turtleOutput }}
                            </div>
                          </Tab>
                          <Tab :title="$t('pageTask.tabs.console')">
                            <textarea class="project-console" id="console" v-model="taskComponent.model.result" />
                          </Tab>
                        </Tabs>
                        </pane>
                    </splitpanes>
                  </div>
                </pane>
              <pane min-size="30" :size="100 - taskComponent.data.splitEditorWidth" v-if="taskComponent.data.showTaskCheck">
                <aside class="sidebar">
                  <Tabs>
                    <Tab :title="$t('pageTask.tabs.task')">
                      <div class="sidebar-task-container">
                        <TaskDescription
                          :title="taskComponent.data.task.title"
                          :text="taskComponent.data.task.unescaped_text_of_task"
                          :taskId="+$route.query.task_id"
                          :accessFeedback="taskComponent.data.accessFeedback"
                          :heightScroll="taskComponent.data.taskCheckContentHeight"
                          :isCheck="taskComponent.data.isCheck"
                        />
                      </div>
                    </Tab>
                    <Tab :title="$t('pageTask.tabs.decison')" v-if="taskComponent.data.studentProgress">
                      <code-editor 
                        :height="taskComponent.data.heightTabEditor" 
                        :code="taskComponent.data.solution" 
                        :themeDefault="'chrome'" 
                        :isDropdown="false" 
                      />
                    </Tab>
                    <Tab :title="$t('pageTask.tabs.comments')" v-if="taskComponent.data.taskComment.comment.length">
                      <div class="sidebar-tab">
                        <TaskComments :user="taskComponent.data.taskComment"/>
                      </div>
                    </Tab>
                  </Tabs>
                <inline-svg
                  class="sidebar-close"
                  :src="require('@/assets/svg/task-close-comments.svg')"
                  @click="taskComponent.methods.toggleShowTaskCheck"/>
                </aside>
                </pane>
              </splitpanes>
            </div>
          </div>
          <Tabs mobile="true" v-if="taskComponent.data.isMobile && !taskComponent.computed.isScratch">
              <Tab :title="$t('pageTask.tabs.task')">
                <TaskDescription
                  @calcHeight="calculateHeightOfCheckContent(true)"
                  :title="taskComponent.data.task.title"
                  :text="taskComponent.data.task.unescaped_text_of_task"
                  :taskId="+$route.query.task_id"
                  :heightScroll="taskComponent.data.taskCheckContentHeight"
                  :isCheck="taskComponent.data.isCheck"
                />
              </Tab>
              <Tab :title="$t('pageTask.tabs.code')">
                <loader v-if="taskComponent.data.loadContent" position="center" color="primary" typePosition="absolute"/>
                <template v-else>
                  <img v-if="taskComponent.computed.inPreviewMode" :src="taskComponent.data.previewUrl" width="500">
                  <code-editor 
                    v-if="taskComponent.computed.inEditMode && !taskComponent.computed.isChat"
                    :height="taskComponent.data.mheight - 60"  
                    :code="taskComponent.data.code"
                    v-on:code-change="taskComponent.methods.onChangeCode" 
                  />
                  <TaskChat :minusHeight="300" v-if="taskComponent.computed.isChat"/>
                  <span class="not-supported-msg" v-if="taskComponent.computed.inNotSupportedMode">
                    {{$t('pageTask.fileExplorer.errors.NotSupported')}}
                  </span>
                </template>
                  <div class="mtask-btns">
                    <div :class="['mtask-btns__left', {'mtask-btns__left--line': !taskComponent.data.studentProgress}]">
                      <button class="btn btn--secondary btn--small task-header-start" @click="taskComponent.methods.run()">
                        <inline-svg class="task-header-start__icon" :src="require('@/assets/svg/start.svg')"/>
                      </button>
                    </div>
                    <div class="mtask-btns__right" v-if="taskComponent.data.studentProgress">
                      <button   class="btn btn--min button-rate btn--small task-header__handover">
                        <span class="button-rate-text">{{$t('pageTask.teacherFeedback.min')}}</span> <inline-svg class="task-header-rate__icon task-header-rate__icon--dislike" :src="require('@/assets/svg/task-rate.svg')"/>
                      </button>
                      <button class="btn btn--max button-rate btn--small task-header__handover">
                        <span class="button-rate-text">{{$t('pageTask.teacherFeedback.max')}}</span> <inline-svg class="task-header-rate__icon" :src="require('@/assets/svg/task-rate.svg')"/>
                      </button>
                      <button  @click="taskComponent.methods.callCheckModal" class="btn btn--primary btn--small task-header__handover">
                        <span v-if="!taskComponent.data.studentTaskGrade">{{$t('checkTask.grade')}}</span>
                        <span v-else>{{$t('checkTask.changeGrade')}}</span>
                      </button>
                    </div>
                  </div>
              </Tab>
              <Tab :title="$t('pageTask.tabs.decison')" v-if="taskComponent.data.studentProgress">
                <code-editor 
                  :height="taskComponent.data.mheight + 20" 
                  :code="taskComponent.data.solution" 
                  :themeDefault="'chrome'" 
                  :isDropdown="false" 
                />
              </Tab>
              <Tab :title="$t('pageTask.tabs.comments')" v-if="taskComponent.data.taskComment.comment.length">
                <div class="mtask-comments">
                  <TaskComments :user="taskComponent.data.taskComment"/>
                </div>
              </Tab>
              <Tab 
                :title="$t('pageTask.fileExplorer.files')" 
                v-if="
                  taskComponent.computed.isUnity || 
                  taskComponent.computed.isCloud || 
                  taskComponent.computed.isWebsite || 
                  taskComponent.computed.isPygame">
                <FileExplorerContent
                :taskId="taskComponent.data.task.id"
                :kind="taskComponent.data.task.kind"
                :studentFiles="taskComponent.data.studentFiles"
                @openCode="taskComponent.methods.fileExplorerOpenProjectCode"  
                @fileSelected="taskComponent.methods.fileExplorerSelected"/>
              </Tab>
          </Tabs>
        </div>
      </template>
 </TaskGeneral>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import TaskGeneral from '@/components/Task/TaskGeneral'
import Header from '@/components/Header/Header'
import TaskHeader from '@/components/Task/TaskHeader'
import CodeEditor from '@/components/CodeEditor/CodeEditor'
import Modal from '@/components/Modal'
import TaskChat from '@/components/Task/TaskChat/TaskChat'
import Tabs from '@/components/Tabs/Tabs'
import Tab from '@/components/Tabs/Tab'
import TaskDescription from '@/components/Task/TaskDescription'
import TaskComments from '@/components/Task/TaskComments'
import TaskRate from '@/components/Task/TaskRate'
import { Splitpanes, Pane } from 'splitpanes'
import FileExplorerContent from '@/components/FileExplorer/FileExplorerContent'
import 'splitpanes/dist/splitpanes.css'

export default {
  components: {
    Header,
    Loader,
    TaskHeader,
    CodeEditor,
    Modal,
    Tabs,
    Tab,
    TaskDescription,
    TaskComments,
    TaskRate,
    Splitpanes,
    Pane,
    FileExplorerContent,
    TaskGeneral,
    TaskChat
  },
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/var.scss";

  .split-container {
    height: calc(100vh - 96px);
  }
  .button-rate{
    @media(max-width: 1116px) {
      width: 46px;
    }
    @media(max-width: 900px) {
      width: 40px;
    }
    &-text{
      @media(max-width: 1116px) {
        display: none;
      }
    }
  } 

  .task-header {
    &__handover {
      margin-left: 8px;
      @media(max-width: 900px) {
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &-rate__icon{
        &--dislike{
      transform: scale(1, -1);
    }
    }
    &-rate__icon::v-deep path{
      min-width: 22px;
      margin-left: 9px;
      fill:#141F43;
    }
  }
  .task-header-rate__icon::v-deep path {
      min-width: 22px;
      fill:#141F43;
  }
</style>