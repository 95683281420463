<template>
  <div>
    <Header
      :breadcrumbs="breadcrumbs"
      :breadcrumbsSteps="breadcrumbsSteps"/>
  </div>
</template>

<script>
import Header from '@/components/Header/Header'

export default {
  name: 'Home',
  components: {
    Header
  },
  data() {
    return {
      breadcrumbs: [],
      breadcrumbsSteps: []
    }
  },
  mounted() {
    this.breadcrumbs = [
      {
        name: 'Мои курсы',
        link: '/'
      },
      {
        name: 'МК Веб-дизайн',
        link: '/'
      },
      {
        name: 'Урок 3',
        link: '/'
      },
    ]
    this.breadcrumbsSteps = [
      {id: 1, name: '1. Проверим, все ли на месте', link: '/', selected: false},
      {id: 2, name: '2. Основы C# скриптинга', link: '/about', selected: false},
      {id: 3, name: '3. Марвин Марсианин', link: '/', selected: false},
      {id: 4, name: '4. Ищем базу', link: '/', selected: true},
      {id: 5, name: '5. Управление игроком', link: '/', selected: false},
    ]
  }
}
</script>
