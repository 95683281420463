<template lang="html">
  <div class="tabs" :class="{mtabs: mobile}">
    <div :class="['tabs__header',{'tabs__header--mobile': mobile}]" :style="{height: `${height}px`}">
      <div v-for='(tab,i) in tabs' 
        :key='i' 
        @click="selectTab(i)"
        :style="{height: `${height}px` ,lineHeight: `${height}px`}"
        :class='["tabs__item",{"tabs__item_active": (i == selectedIndex),"tabs__item--notread" : newComment && i == tabCommentIndex,"tabs__item--mobile": mobile}]'
        >
        {{ tab.title }} 
      </div>
    </div>
    <slot></slot>
  </div>
</template>
       
<script>
export default {
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: []         // all of the tabs
    }
  },
  props: {
    height: {
      type: Number,
      required: false,
      default: null
    },
    newComment: {
      type: Boolean,
      required: false
    },
    tabCommentIndex: {
      type: Number,
      required: false
    },
     mobile: {
      // type: Boolean,
      required: false
    },
  },
  methods: {
    toFirstTab() {
      this.selectedIndex = 0
      this.tabs.forEach((tab, index) => {
      tab.isActive = (index === 0)
      })
    },
    selectTab (i) {
    this.selectedIndex = i
    
    // loop over all the tabs
    this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
    })
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    this.selectTab(0)
},
}
</script>

<style lang="scss">
  .tabs {
    height: 100%;
    overflow: hidden;
    &__header {
      position: relative;
      display: flex;
      height: 48px;
      border-bottom: 1px solid #ACB2BE;
      padding: 0 8px;
      &--mobile {
        height: 60px;
        box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.1);
      }
    }
    &__item {
      position: relative;
      cursor: pointer;
      font-family: "InterBold";
      height: 48px;
      line-height: 48px;
      margin: 0 8px;
      color: #ACB2BE;
      font-size: 14px;
      &--mobile {
        font-size: 16px;
        height: 60px;
        line-height: 60px;
      }
      &_active {
        color: #3860ED;
        border-bottom: 2px solid #3860ED;
      }
      &--notread {
        &::after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          background: #E55961;
          top: 11px;
          right: -13px;
          border-radius: 50%;
            @media(max-width: 900px) {
              display: none;
            }
        }
      }
    }
    &__content {
      // padding: 32px 24px 0;
      height: 100%;
    }
    img {
      max-height: 100%;
    }
  }
  .mtabs {
    display: flex;
    flex-direction: column-reverse;
    .tabs__header {
      // height: 48px;
      border-bottom: none;
      overflow: scroll;
    }
    .tabs__item_active {
      border-bottom: 4px solid #3860ED;
    }
    .tab__content {
      min-height: calc(100vh - 160px);
    }
}
    
</style>