<template>
  <div class="registration">
    <div class="registration-content">
      <span class="registration__title">{{$t('auth.signup.title')}}</span>
      <div class="registration-socials">
        <template v-if="lang === 'ru'">
          <a href="https://platform.kodland.org/ru/social/vk/login/?process=" v-tooltip="$t('auth.login.socials.vk')" class="registration-socials__element">
            <inline-svg :src="require('@/assets/svg/white-vk.svg')"/>
          </a>
          <!-- <a href="#" v-tooltip="'Twitter'" class="login-socials__element">
            <inline-svg :src="require('@/assets/svg/white-twitter.svg')"/>
          </a> -->
          <!-- <a href="https://platform.kodland.org/ru/social/facebook/login/?process=" v-tooltip="'Facebook'" class="login-socials__element">
            <inline-svg :src="require('@/assets/svg/white-facebook.svg')"/>
          </a> -->
          <a href="https://platform.kodland.org/ru/social/yandex/login/?process=" v-tooltip="$t('auth.login.socials.yandex')" class="registration-socials__element">
            <inline-svg :src="require('@/assets/svg/white-yandex.svg')"/>
          </a>
        </template>
        <a href="https://platform.kodland.org/ru/social/google/login/?process=" v-tooltip="'Google'" class="registration-socials__element">
          <inline-svg :src="require('@/assets/svg/white-google.svg')"/>
        </a>
      </div>
      <span class="registration-or">{{ $t('auth.login.or') }}</span>
      <form @submit.prevent="signup">
        <div class="registration-input-container">
          <input
            :class="['input-text', 'login-input', {'input-text--error': errors.errorFirstName}]"
            v-model.trim="firstName"
            type="text"
            name="firstName"
            id="firstName"
            :placeholder="$t('auth.signup.inputs.placeholderFirstName')">
          <span class="input-error-text" v-if="errors.errorFirstName">{{$t('auth.errors.validateName')}}</span>
        </div>
        <div class="registration-input-container">
          <input
            :class="['input-text', 'login-input', {'input-text--error': errors.errorLastName}]"
            v-model.trim="lastName"
            type="text"
            name="lastName"
            id="lastName"
            :placeholder="$t('auth.signup.inputs.placeholderLastName')">
          <span class="input-error-text" v-if="errors.errorLastName">{{$t('auth.errors.validateLastName')}}</span>
        </div>
        <div class="registration-input-container">
          <input
            :class="['input-text', 'login-input', {'input-text--error': errors.errorEmail}]"
            v-model.trim="email"
            type="text"
            name="email"
            id="email"
            :placeholder="$t('auth.signup.inputs.email')">
          <span class="input-error-text" v-if="errors.errorEmail">{{$t('auth.errors.validateEmail')}}</span>
        </div>
        <div class="message message--error" v-if="responseError">
          {{responseErrorText}}
        </div>
        <div class="message message--error" v-if="socialLoginError">
          {{$t('auth.errors.socialLoginError')}}
        </div>
        <button
          class="btn btn--secondary btn--fullwidth registration-button-registration"
          :disabled="!firstName || !lastName || !email || sendRequestSignup">
          <span v-if="!sendRequestSignup">{{$t('auth.signup.buttons.signup')}}</span>
          <Loader v-else/>
        </button>
        <div class="registration-text block-vhtml" v-html="$t('auth.signup.confidentiality')" />
      </form>
    </div>
    <button
      class="btn btn--primary btn--fullwidth registration-button-login"
      @click="signin">{{$t('auth.signup.buttons.signin')}}</button>
  </div>
</template>

<script>
import apiUser from '@/api/user'
import validations from '@/helpers/validations'
import Loader from '@/components/Loader'

export default {
  name: 'sugnup',
  components: {
    Loader
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      signupAttempt: '',
      sendRequestSignup: false,
      responseError: false,
      responseErrorText: '',
      errors: {
        errorFirstName: false,
        errorLastName: false,
        errorEmail: false
      }
    }
  },
  computed: {
    lang() {
      return this.$store.state.user.language
    },
    socialLoginError() {
      if (this.$route.query.socialLoginError) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    checkValid() {
      if (this.signupAttempt) { // check validation only if were signup attempt
        const validationFirstName = validations.countSymbols(this.firstName, 2, 32)
        const validationLastName = validations.withoutNumbers(this.lastName)
        const validationEmail = validations.email(this.email)
        !validationFirstName ? this.errors.errorFirstName = true : this.errors.errorFirstName = false
        !validationLastName ? this.errors.errorLastName = true : this.errors.errorLastName = false
        !validationEmail ? this.errors.errorEmail = true : this.errors.errorEmail = false
        if (!validationFirstName || !validationLastName || !validationEmail) {
          return false
        } else {
          return true
        }
      }
    },
    signin() {
      this.$emit('changeTypeAuth', 'signin')
    },
    async signup() {
      this.signupAttempt = true;
      this.errors = {
        errorFirstName: false,
        errorLastName: false,
        errorEmail: false
      }
      const valid = this.checkValid();
      if (valid) {
        this.responseError = false;
        this.sendRequestSignup = true;
        const signup = await apiUser.signup(this.firstName, this.lastName, this.email);
        this.sendRequestSignup = false;
        if (signup === 'ok') {
          this.$emit('changeTypeAuth', 'signup-completed')
          if (this.$metrika) {
            this.$metrika.reachGoal('register')
          }
          if (this.$route.query.next) {
            window.location.replace(this.$route.query.next)
          } else {
            window.location.replace(`https://${window.location.hostname}/${this.lang}/courses/`)
          }
        } else {
          this.responseError = true;
          this.responseErrorText = signup;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.registration {
  width: 400px;
  margin: auto;
  &__title {
    font-family: "JetBrainsMonoExtraBold";
    font-size: 32px;
    color: $primary;
    display: block;
    text-align: center;
    margin-bottom: 40px;
  }
  &-socials {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    &__element {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background: $primary;
      margin: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      position: relative;
      &:hover {
        background: $primaryHover;
      }
    }
  }
  &-or {
    display: block;
    text-align: center;
    margin-bottom: 16px;
    color: $grey;
  }
  &-input {
    &-container {
      position: relative;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 24px;
      }
    }
  }
  &-button {
    &-registration {
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  &-text {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1023px) {
  .registration {
    &-socials {
      &__element {
        width: 40px;
        height: 40px;
      }
    }
    &-content {
      background: #fff;
      padding: 20px 20px 0 20px;
      margin-bottom: 20px;
    }
    &-text {
      padding-bottom: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .registration {
    width: 100%;
    max-width: 500px;
    &__title {
      font-size: 24px;
    }
    &-socials {
      &__element {
        margin: 0 5px;
      }
    }
    &-button {
      &-login {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 374px) {
  .registration {
    &-socials {
      &__element {
        margin: 0 5px;
      }
    }
  }
}

</style>