<template>
  <div class="recovery-completed">
    <div class="recovery-completed-content">
      <span class="recovery-completed__title">{{$t('auth.recovery.completed.title')}}</span>
      <div class="recovery-completed__text">
        {{$t('auth.recovery.completed.spam')}}
      </div>
      <div class="recovery-completed__text block-vhtml" v-html="$t('auth.recovery.completed.service')"/>
    </div>
    <button
      class="btn btn--primary btn--fullwidth recovery-completed-button"
      @click="signin">{{$t('auth.signup.buttons.signin')}}</button>
  </div>
</template>

<script>
export default {
  name: 'recovery-completed',
  methods: {
    signin() {
      this.$emit('changeTypeAuth', 'signin')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.recovery-completed {
  width: 400px;
  margin: auto;
  &__title {
    font-family: "JetBrainsMonoExtraBold";
    font-size: 32px;
    color: $primary;
    display: block;
    text-align: center;
    margin-bottom: 40px;
  }
  &__text {
    font-size: 14px;
    text-align: center;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 1023px) {
  .recovery-completed {
    &-content {
      background: #fff;
      padding: 20px 20px 0 20px;
      margin-bottom: 20px;
    }
    &__text {
      padding-bottom: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .recovery-completed {
    width: 100%;
    max-width: 500px;
    &__title {
      font-size: 24px;
    }
    &-button {
      margin-bottom: 20px;
    }
  }
}
</style>