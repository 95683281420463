<template>
  <label class="switcher">
    <input type="checkbox" class="switcher-input" @change="onChange()">
    <span class="switcher-slider"></span>
  </label>
</template>

<script>
export default {
  name: 'Switcher',
  data() {
    return {
      checked: false
    }
  },
  methods: {
   onChange() {
     this.checked = !this.checked;
     this.$emit('checked',this.checked);
   }
  },
}
</script>

<style lang="scss">
  
.switcher {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  &-input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: -2px;
      top: -2px;
      background-color: #8a8c90;
      -webkit-transition: .3s;
      transition: .3s;
      border-radius: 50%;
    }
  }
  &-input:checked + .switcher-slider {
    background-color: #A0D1F4;
  }

  &-input:focus + .switcher-slider {
    box-shadow: 0 0 1px #2196F3;
  }

  &-input:checked + .switcher-slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: #3860ED;
  }
}






</style>