<template>
	<div class="split-pagination">
		<div class="split-pagination_arrows">
			<i class="split-pagination_arrow" @click="paginateTask('minus')">
				<img :src="require('@/assets/svg/editor-left-arrow.svg')"/>
			</i>
			{{currentTaskNumber}}/{{taskLength}}
			<i class="split-pagination_arrow" @click="paginateTask('plus')">
				<img :src="require('@/assets/svg/editor-right-arrow.svg')"/>
			</i>
		</div>
	</div> 
</template>

<script>
export default {
	data() {
		return {
      currentTaskNumber: null,
      taskPaginate: [],
    }
	},
	props: {
		breadcrumbs: Array,
    numberStep: {
      type: Number,
      required: false,
			default: null
    },
    paginatedTask: {
      type: Number,
      required: false,
			default: null
    },
    taskLength: {
      type: Number,
      required: false,
			default: null
    },
  }, 
  created() {
      this.currentTaskNumber = this.paginatedTask
      this.breadcrumbs.forEach(el => {
				 if (el.id === "list-tasks") {
					 el.list.forEach(dropdown => {
						 this.taskPaginate.push(dropdown)
					 })
				 }
			})
  },
	methods: {
		paginateTask (operator) {
			if (operator === 'plus') {
				if (this.currentTaskNumber === this.taskLength) return
				this.currentTaskNumber++ 
			} else {
					if (this.currentTaskNumber <= 1) return
					this.currentTaskNumber--
				}
				this.$emit('changeTaskNumber', this.currentTaskNumber)
			this.taskPaginate.forEach((el, index) => {
			if (index+ 1 === this.currentTaskNumber) {
				el.selected = true
				this.$router.push(el.link)
			} else {
				el.selected = false
				}  
			})
		}
	},
  watch: {
    numberStep(val) {
      this.currentTaskNumber = val
    },
  }
}
</script>