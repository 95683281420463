<template >
  <div>
    <TaskGeneral  @getTaskLessonid="getTaskLessonid">
      <!-- Принимаю все данные и методы из дочернего компонента  TaskGeneral и собираю их в один пропс : "taskComponent" -->
      <template #taskRenderlessComponent="taskComponent">
        <Header
          :breadcrumbs="taskComponent.data.breadcrumbs"
          :paginatedTask="taskComponent.data.paginatedTask"
          @goToStep="taskComponent.methods.goToStep"
          @selectStep="taskComponent.methods.changeStepBreadcrumbs"
          />
        <Loader v-if="taskComponent.data.loader" color="primary" position="center"/>
        <div v-else>
          <template v-if="taskComponent.computed.isLink">
            <TaskLink
              :taskId="taskComponent.data.task.id"
              :kind="taskComponent.data.task.kind" 
              :title="taskComponent.data.task.title"
              :text="taskComponent.data.task.unescaped_text_of_task"
              :maxGrade="taskComponent.data.task.max_grade || null"
              :minGrade="taskComponent.data.task.min_grade || null"
            />
          </template>
          <template v-else>
          <modal
            ref="modalReset"
            :showExpand="false"
            :showFooter="false"
            :showClose="false"
            size="small">
            <template v-slot:content>
              <ModalQuestion
                @close="taskComponent.methods.closeResetTask"
                @action="taskComponent.methods.resetProgressTask"
                :title="$t('pageTask.modalQuestion.title.reset')"
                :description="$t('pageTask.modalQuestion.description')"
              />
            </template>
          </modal>
          <modal
            ref="modalRereview"
            :showExpand="false"
            :showFooter="false"
            :showClose="false"
            size="small">
            <template v-slot:content>
              <ModalQuestion
                @close="taskComponent.methods.closeRereviewTask"
                @action="taskComponent.methods.reviewTask"
                :title="`${$t('pageTask.retakeTask')} ?`" 
              />
            </template>
          </modal>
          <modal
            ref="modalReview"
            :showExpand="false"
            :showFooter="false"
            :showClose="true"
            :closeOnOverlay="false"
            size="small">
            <template  v-slot:content>
              <TaskFeedback
                :lessonId="taskLessonId"
                @closeModalReview="taskComponent.methods.closeModalReview"
              />
            </template>
          </modal>
          <TaskHeader 
            @run="taskComponent.methods.run()"
            @save="taskComponent.methods.save()"
            @toggleTaskCheck="taskComponent.methods.toggleShowTaskCheck"
            @toggleOpenFiles="taskComponent.methods.toggleOpenFiles"
            @openRereview="taskComponent.methods.openRereviewTask"
            @openReset="taskComponent.methods.openResetTask"
            :isSendOnReview="taskComponent.data.isSendOnReview"
            :studentName="taskComponent.data.studentName"
            :maxGrade="taskComponent.data.task.max_grade || null"
            :minGrade="taskComponent.data.task.min_grade || null"
            :taskKind="taskComponent.data.task.kind"
            :showTaskCheck="taskComponent.data.showTaskCheck"
            :isScratch="taskComponent.computed.isScratch"
            :isDecision="true"
            :unreadMessages="taskComponent.data.taskComment.comment.length"
            :passedTaskTime="taskComponent.data.passedTaskTime"
            :isChat="taskComponent.computed.isChat">
            <div class="task-header_save-button">
              <button class="btn btn--primary btn--header-small task-header__save" :disabled="taskComponent.data.disableSave"  @click="taskComponent.methods.save()">
                <span span class="btn__text">{{$t('pageTask.save')}}</span>
                <inline-svg class="btn__mobile-icon" :src="require('@/assets/svg/save.svg')"/>
              </button>
            </div>
            <button
              v-if="taskComponent.data.isSendOnReview"
              :disabled="taskComponent.data.reviewTaskLoad"
              @click="taskComponent.methods.openRereviewTask"
              class="btn btn--secondary btn--header-small task-header__handover">
              {{$t('pageTask.retakeTask')}}
            </button>
            <button
              v-else
              :disabled="taskComponent.data.reviewTaskLoad"
              @click="taskComponent.methods.reviewTask"
              class="btn btn--secondary btn--header-small task-header__handover">
              {{$t('pageTask.handover')}}
            </button>
          </TaskHeader>
          <div class="task-content__scratch" v-if="taskComponent.computed.isScratch">
            <iframe 
            :src="taskComponent.data.scratchSrc"  
            width="100%" 
            height="100%" 
            ref="scratchIframe" 
            class="scratch-iframe" 
            allow="camera;microphone" 
            v-if="!taskComponent.data.isMobile" />
            <h2 v-if="taskComponent.data.isMobile" class="scratch-resolution-error">{{$t('pageTask.sorryDevice')}} :(</h2>
            <TaskPagination 
              :breadcrumbs="taskComponent.data.breadcrumbs"
              :paginatedTask="taskComponent.data.paginatedTask"
              :taskLength="taskComponent.data.taskLength"
              :numberStep="taskComponent.data.numberStep"
              @changeTaskNumber="taskComponent.methods.changeTaskNumber"
              v-if="!taskComponent.data.isMobile"
            />
          </div>
          <div v-if="!taskComponent.data.isMobile && !taskComponent.computed.isScratch">
            <div class="task-content">
              <FileExplorerContent
                v-if="taskComponent.computed.showFileExplorer"
                :taskId="taskComponent.data.task.id"
                :kind="taskComponent.data.task.kind"
                :studentFiles="taskComponent.data.studentFiles"
                @fileSelected="taskComponent.methods.fileExplorerSelected"
                @openCode="taskComponent.methods.fileExplorerOpenProjectCode"
                @clearFileSelected="taskComponent.methods.clearSelectFile"
              />
              <splitpanes class="split-container" vertical @resize="taskComponent.methods.updateWidth" @ready="taskComponent.methods.readySplit">
                <pane :size="taskComponent.data.splitEditorWidth" >
                  <div  :class="{'editor-wrapper': !taskComponent.computed.isChat}"> 
                    <TaskChat :minusHeight="250" v-if="taskComponent.computed.isChat"/>
                    <splitpanes v-else class="split-container" horizontal @resize="taskComponent.methods.updateHeight" @ready="taskComponent.methods.readySplit">
                      <pane ref="paneEditor" :size="taskComponent.data.splitEditorHeight">
                        <div class="code-wrapper">
                          <loader v-if="taskComponent.data.loadContent" position="center" color="primary" typePosition="absolute"/>
                          <div v-else>
                          <span class="code-wrapper-notselected" v-if="taskComponent.computed.isNotSelectedFile && !taskComponent.computed.isChat">
                            {{$t('pageTask.fileExplorer.сhoiseFile')}}
                          </span>
                            <img
                             v-if="taskComponent.computed.inPreviewMode"
                             :src="taskComponent.data.previewUrl"
                             width="500">
                            <code-editor 
                              v-if="taskComponent.computed.inEditMode"
                              :height="taskComponent.data.heightEditor" 
                              :code="taskComponent.data.code"
                              v-on:code-change="taskComponent.methods.onChangeCode"
                            />
                            <span class="not-supported-msg" v-if="taskComponent.computed.inNotSupportedMode">
                              {{$t('pageTask.fileExplorer.errors.NotSupported')}}
                            </span>
                          </div>
                        </div>
                      </pane>
                      <pane min-size="10" :size="100 - taskComponent.data.splitEditorHeight" v-if="taskComponent.computed.isBase"> 
                        <textarea class="project-console" id="console" v-model="taskComponent.model.result" />
                      </pane>
                      <pane min-size="10" :size="100 - taskComponent.data.splitEditorHeight" v-if="taskComponent.computed.isTurtle">
                        <Tabs :height="32">
                          <Tab :title="$t('pageTask.tabs.result')">
                            <div id="turtleCanvas" class="turtle-output">
                                {{ taskComponent.data.turtleOutput }}
                            </div>
                          </Tab>
                          <Tab :title="$t('pageTask.tabs.console')">
                              <textarea class="project-console" id="console" v-model="taskComponent.model.result" />
                          </Tab>
                        </Tabs>
                      </pane>
                    </splitpanes>
                  </div>
                </pane>
              <pane min-size="30" :size="100 - taskComponent.data.splitEditorWidth" v-if="taskComponent.data.showTaskCheck">
                <aside class="sidebar">
                <Tabs>
                  <Tab :title="$t('pageTask.tabs.task')">
                    <div class="sidebar-task-container">
                      <TaskDescription
                        :title="taskComponent.data.task.title"
                        :text="taskComponent.data.task.unescaped_text_of_task"
                        :taskId="+$route.params.taskId"
                        :accessFeedback="taskComponent.data.accessFeedback"
                        :heightScroll="taskComponent.data.taskCheckContentHeight"/>
                    </div>
                  </Tab>
                  <Tab :title="$t('pageTask.tabs.comments')" v-if="taskComponent.data.taskComment.comment.length">
                    <div class="sidebar-tab">
                      <TaskComments :user="taskComponent.data.taskComment"/>
                    </div>
                  </Tab>
                </Tabs>
                <inline-svg
                  class="sidebar-close"
                  :src="require('@/assets/svg/task-close-comments.svg')"
                  @click="taskComponent.methods.toggleShowTaskCheck"/>
                </aside>
                </pane>
              </splitpanes>
            </div>
            <TaskPagination 
              :breadcrumbs="taskComponent.data.breadcrumbs"
              :paginatedTask="taskComponent.data.paginatedTask"
              :taskLength="taskComponent.data.taskLength"
              :numberStep="taskComponent.data.numberStep"
              @changeTaskNumber="taskComponent.methods.changeTaskNumber"
            />
          </div>
          <Tabs mobile="true" v-if="taskComponent.data.isMobile && !taskComponent.computed.isScratch">
            <Tab :title="$t('pageTask.tabs.code')">
              <loader v-if="taskComponent.data.loadContent" position="center" color="primary" typePosition="absolute"/>
              <template v-else>
                <img v-if="taskComponent.computed.inPreviewMode" :src="taskComponent.data.previewUrl" width="500">
                <span class="code-wrapper-notselected" v-if="taskComponent.computed.isNotSelectedFile && (taskComponent.computed.isCloud || taskComponent.computed.isWebsite || taskComponent.computed.isUnity || taskComponent.computed.isPygame)">
                  {{$t('pageTask.fileExplorer.сhoiseFile')}}
                </span>
                <!-- :height="mheight - 50"  - В актуальной версии develop -->
                <code-editor 
                  v-if="taskComponent.computed.inEditMode && !taskComponent.computed.isChat"
                  :height="taskComponent.data.mheight - 60"  
                  :code="taskComponent.data.code"
                  v-on:code-change="taskComponent.methods.onChangeCode" 
                />
                <TaskChat :minusHeight="300" v-if="taskComponent.computed.isChat"/>
                <span class="not-supported-msg" v-if="taskComponent.computed.inNotSupportedMode">
                  {{$t('pageTask.fileExplorer.errors.NotSupported')}}
                </span>
              </template>
                <div class="mtask-btns" :class="{'full-width': taskComponent.computed.isChat}">
                  <template v-if="!taskComponent.computed.isChat">
                    <div class="mtask-btns__left">
                      <button class="btn btn--secondary btn--small task-header-start" @click="taskComponent.methods.run()">
                        <inline-svg class="task-header-start__icon" :src="require('@/assets/svg/start.svg')"/>
                      </button>
                      <button
                        class="btn btn--primary btn--small btn--outline task-header-restart"
                        @click="taskComponent.methods.openResetTask">
                        <inline-svg class="btn__mobile-icon" :src="require('@/assets/svg/refresh.svg')"/>
                      </button>
                    </div>
                    <div class="mtask-btns__right">
                      <button class="btn btn--primary btn--small task-header_save-button">
                        <inline-svg class="btn__mobile-icon" :src="require('@/assets/svg/save.svg')"/> 
                      </button>
                    </div>
                  </template>
                  <button
                    v-if="taskComponent.data.isSendOnReview"
                    :disabled="taskComponent.data.reviewTaskLoad"
                    @click="taskComponent.methods.openRereviewTask"
                    class="btn btn--secondary btn--small btn--pass"
                    :class="{'full-width': taskComponent.computed.isChat}">
                    {{$t('pageTask.retakeTask')}}
                  </button>
                  <button
                    v-else
                    :disabled="taskComponent.data.reviewTaskLoad"
                    @click="taskComponent.methods.reviewTask"
                    class="btn btn--secondary btn--small btn--pass"
                    :class="{'full-width': taskComponent.computed.isChat}">
                    {{$t('pageTask.handover')}}
                  </button>
              </div>
            </Tab>
            <Tab :title="$t('pageTask.tabs.result')" v-if="taskComponent.computed.isTurtle">
              <div id="mturtleCanvas" class="turtle-output">
                {{ taskComponent.data.turtleOutput }}
              </div>
            </Tab>
            <Tab :title="$t('pageTask.tabs.task')">
              <div class="mtask-description">
                <span class="mtask-description-title">{{taskComponent.data.task.title}}</span>
                <div class="mtask-description-subtitle" v-html="taskComponent.data.task.unescaped_text_of_task"></div>
              </div>
              <div class="mtask-vote">
                <div class="mtask-vote__question">
                  {{$t('pageTask.windowTask.likeTask')}} 
                  <div class="mtask-vote__btns">
                    <button class="btn btn--primary btn--outline">
                      <inline-svg class="task-header-rate__icon" :src="require('@/assets/svg/task-rate.svg')"/>
                    </button>
                    <button class="btn btn--primary btn--outline">
                      <inline-svg class="task-header-rate__icon" :src="require('@/assets/svg/dislike.svg')"/>
                    </button>
                </div>
                </div>
                <div class="mtask-vote__answer">
                  {{$t('pageTask.windowTask.thanksReview')}}
                  <inline-svg class="mtask-vote__close" :src="require('@/assets/svg/task-close-comments.svg')"/>
                </div>
              </div>
            </Tab>
            <Tab :title="$t('pageTask.tabs.comments')" v-if="taskComponent.data.taskComment.comment.length">
              <div class="mtask-comments">
                <TaskComments :user="taskComponent.data.taskComment"/>
              </div>
            </Tab>
            <Tab :title="$t('pageTask.tabs.console')" v-if="!taskComponent.computed.isPygame && !taskComponent.computed.isChat">
              <textarea class="project-console"
                :style="{ minHeight: (taskComponent.data.mheight + 20) + 'px' }"
                v-model="taskComponent.model.result"
                id="console" 
              />
            </Tab>
            <Tab :title="$t('pageTask.fileExplorer.files')"
              v-if="taskComponent.computed.isUnity || taskComponent.computed.isCloud || taskComponent.computed.isWebsite || taskComponent.computed.isPygame">
              <FileExplorerContent
              :taskId="taskComponent.data.task.id"
              :kind="taskComponent.data.task.kind"
              :studentFiles="taskComponent.data.studentFiles"
              @openCode="taskComponent.methods.fileExplorerOpenProjectCode"  
              @fileSelected="taskComponent.methods.fileExplorerSelected"/>
            </Tab>
          </Tabs>
          <modal ref="gameModal">
            <template v-slot:content>
              <Tabs>
                <Tab :title="$t('pageTask.tabs.result')">
                  <div class="align-center">
                    <div ref="canvas"></div>
                  </div>
                </Tab>
                <Tab :title="$t('pageTask.tabs.console')" >
                  <textarea 
                    class="project-console mconsole"
                    :style="{ minHeight: taskComponent.data.mheight + 'px' }"
                    v-model="taskComponent.model.result" id="console"
                  />
                </Tab>
              </Tabs>
            </template>
              <template v-slot:footer>
                <div class="content-center">
                  <button class="btn btn--fullwidth btn--secondary btn--reload" 
                    @click="taskComponent.methods.run()"
                  >
                    <inline-svg class="reload-icon"  :src="require('@/assets/svg/refresh-dark.svg')"/>
                    {{$t('pageTask.rerun')}}
                  </button>
                </div>
              </template>
          </modal>
          </template>
        </div>
      </template>
    </TaskGeneral>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import Header from '@/components/Header/Header'
import TaskHeader from '@/components/Task/TaskHeader'
import TaskGeneral from '@/components/Task/TaskGeneral'
import TaskLink from '@/components/Task/TaskLink'
import CodeEditor from '@/components/CodeEditor/CodeEditor'
import Modal from '@/components/Modal'
import ModalQuestion from '@/components/ModalQuestion'
import Tabs from '@/components/Tabs/Tabs'
import Tab from '@/components/Tabs/Tab'
import TaskDescription from '@/components/Task/TaskDescription'
import TaskComments from '@/components/Task/TaskComments'
import TaskChat from '@/components/Task/TaskChat/TaskChat'
import TaskFeedback from '@/components/Task/TaskFeedback'
import TaskPagination from '@/components/Task/TaskPagination'
import { Splitpanes, Pane } from 'splitpanes'
import FileExplorerContent from '@/components/FileExplorer/FileExplorerContent'
import 'splitpanes/dist/splitpanes.css'

export default {
  name: 'Home',
  components: {
    Header,
    Loader,
    TaskHeader,
    CodeEditor,
    Modal,
    ModalQuestion,
    Tabs,
    Tab,
    TaskDescription,
    TaskComments,
    TaskFeedback,
    Splitpanes,
    Pane,
    FileExplorerContent,
    TaskPagination,
    TaskGeneral,
    TaskLink,
    TaskChat
  },
  data() {
    return {
      taskLessonId: null
    }
  },
  methods: {
    // метод для получения id урока и передачи его в другой скопед-слот
    getTaskLessonid(id) {
      this.taskLessonId = id
    },
  },
}
</script>

<style lang="scss">
  .split-container {
    height: calc(100vh - 131px);
  }
</style>