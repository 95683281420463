import { HTTP } from "@/services/HttpService"

export default {
  async getCoursesCurrentUser() {
    try {
      const response = await HTTP.get(`/student/courses/`)
      return response.data
    } catch (err) {
      console.log(err.response);
    }
  }
}