<template>
  <div class="rate-modal">
    <span class="rate-modal-title">{{$t('checkTask.grade')}} {{numberTask}} {{$t('checkTask.lesson')}}</span>
    <span class="rate-modal-name">{{studentName}}</span>
    <div class="rate-modal-rating">
      <span class="rate-modal-rating_subtitle rate-modal-subtitle mb-none">{{$t('checkTask.lesson')}}</span>
      <div class="rate-modal-rating_mistake-wrapper">
        <span class="rate-modal-rating_mistake" v-show="maxRateMistake">{{$t('checkTask.modal.maxGrade')}}</span>
        <span class="rate-modal-rating_mistake" v-show="minRateMistake">{{$t('checkTask.modal.minGrade')}}</span>
      </div>
      <input :class="['rate-modal-rating_input', {'rate-modal-rating_input--mistake': maxRateMistake}]" v-model="teacherScore" :placeholder="$t('checkTask.modal.placeholders.grade')" type="number">
      <span class="rate-modal-rating_max">{{$t('checkTask.modal.maxScore')}}: {{maxScore}}</span>
    </div>
    <div class="rate-modal-comment">
      <span class="rate-modal-comment_subtitle rate-modal-subtitle">{{$t('checkTask.modal.comment')}}</span>
      <textarea class="rate-modal-comment_area" v-model="comment" :placeholder="$t('checkTask.modal.placeholders.comment')" cols="30" rows="10"></textarea>
    </div>
    <div class="rate-modal-buttons">
      <button @click="checkTask" :disabled="!teacherScore || maxRateMistake || minRateMistake" class="btn btn--primary rate-modal-button">{{$t('checkTask.grade')}}</button>
      <button @click="checkTask" class="btn btn--primary btn--outline rate-modal-button">{{$t('checkTask.cancel')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teacherScore: '',
      comment: '',
      maxRateMistake: false,
      minRateMistake: false,
    }
  },
  props: {
    studentName: {
      required: true,
    },
    numberTask: {
      type: Number,
      required: false,
    },
    maxScore: {
      type: Number,
      required: false,
    },
    minScore: {
      type: Number,
      required: false,
    },
    callClearModal: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    checkTask() {
      this.$emit('checkTask' ,{
        taskComment: this.comment,
        taskGrade: this.teacherScore
        })
    },
    closeModalTask() {
      this.$emit('closeModalTask')
    },
  },  
  watch: {
  teacherScore(val) {
    if(val === '') {
      this.minRateMistake = false
      return
    }
    if (this.maxScore){
      val > this.maxScore ? this.maxRateMistake = true : this.maxRateMistake = false
      val < this.minScore ? this.minRateMistake = true : this.minRateMistake = false
    }
  },
}
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.rate-modal{
  &-title{
    font-family: "InterBold";
    display: block;
    font-size: 24px;
    margin-bottom: 8px;
  }
  &-name{
    color: #3860ED;
  }
  &-subtitle{
    font-family: "InterBold";
  }
  &-rating{
    margin: 24px 0;
    display: flex;
    flex-direction: column;

    & > span,input{
      margin-bottom: 8px;
    }
    &-subtitle {
      margin-bottom: 8px;
    }
    &_mistake{
      color: #E55961;
      font-size: 14px;
      margin-bottom: 5px;
      height: 22x;
      &-wrapper{
        height: 22px;
      }
    }
    &_input {
      padding: 8px;
      border: 1px solid #D9D9D9;
      border-radius: 8px;
      height: 33px;
      outline: none;
      font-size: 14px;
      &--mistake{
        border: 1px solid #E55961;
      }
      &:focus {
        border: 1px solid $primary;
      }
      &-max{
        font-size: 14px;
      } 
    }
  }
  &-comment{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    &_subtitle{
      margin-bottom: 9px;
    }
    &_area{
      border-radius: 8px;
      color: #141F43;
      resize: none;
      min-height: 182px;
      padding: 8px;
      outline: none;
      font-size: 14px;
      &::placeholder {
        font-family: "InterRegular";
        font-size: 14px;
      }
      &:focus {
        border: 1px solid $primary;
      }
    }
  }
  &-buttons{
    display: flex;
  }
  &-button{
    width: 112px;
    &:first-child{
      margin-right: 16px;
    }
  }
}
.mb-none{
  margin-bottom: 0!important;
}
  @media(max-width: 767px) {
    .rate-modal{
      &-title{
        font-size: 18px;
      }
      &-comment_area{
        min-height: 90px;
        height: 105px;
      }
    }
  }
</style>