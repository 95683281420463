<template>
  <div class="popup-menu">
    <div class="popup-menu-block">
      <span class="popup-menu__name">{{name}}</span>
      <span class="popup-menu__city">{{city}}<span v-if="age">, {{age}} {{ageText}}</span></span>
    </div>
    <div class="popup-menu-block">
      <router-link class="popup-menu__link link" to="/">{{$t('header.menu.myProjects')}}</router-link>
      <router-link class="popup-menu__link link" to="/">{{$t('header.menu.myCourses')}}</router-link>
      <router-link class="popup-menu__link link" to="/">{{$t('header.menu.profile')}}</router-link>
      <router-link class="popup-menu__link link" to="/">{{$t('header.menu.mapOfLevels')}}</router-link>
    </div>
    <span class="popup-menu-exit link" @click="logout">
      <inline-svg class="popup-menu-exit__icon" :src="require('@/assets/svg/exit.svg')"/>
      {{$t('header.menu.logout')}}
    </span>
  </div>
</template>

<script>
import declinationWords from '@/helpers/declinationWords'
import cookies from 'js-cookie'

export default {
  props: ['name', 'city', 'age'],
  computed: {
    ageText() {
      if (this.age) {
        return declinationWords(this.age, ['год', 'года', 'лет'])
      } else {
        return ''
      }
    }
  },
  methods: {
    logout() {
      cookies.remove('access', {domain: ".kodland.org"})
      cookies.remove('refresh', {domain: ".kodland.org"})
      this.$router.replace('/auth')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.popup-menu {
  width: 300px;
  background: #fff;
  border: 2px solid $primary;
  box-sizing: border-box;
  box-shadow: 0 0 15px rgba(0, 0, 0, .15);
  border-radius: 4px;
  text-align: left;
  position: absolute;
  right: 0;
  top: 56px;
  padding: 30px;
  z-index: 50;
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: -11px;
    right: 80px;
    background: #fff;
    border-top: 2px solid $primary;
    border-right: 2px solid $primary;
    box-sizing: border-box;
    transform: rotate(-45deg);
    z-index: 10;
  }
  &-block {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  &__name {
    font-weight: 800;
    font-size: 18px;
    color: $primary;
    display: block;
  }
  &__city {
    display: block;
    margin-top: 8px;
    font-family: InterRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2e2c2c;
  }
  &__link {
    display: block;
    font-size: 18px;
    color: #2e2c2c;
    font-family: "JetBrainsMonoExtraBold";
    margin-bottom: 8px;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-exit {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #2e2c2c;
    font-family: "JetBrainsMonoExtraBold";
    &__icon {
      margin-right: 16px;
    }
    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}
</style>