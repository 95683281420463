<template>
  <div class="task-description">
    <div class="task-description-text">
      <perfect-scrollbar :class="[{'scroll-box': !localFeedback},{'scroll-box__answer': localFeedback}]">
        <div class="task-description-wrapper">
        <span class="task-description-title">{{title}}</span>
        <div class="task-description-subtitle" v-html="text"></div>
        </div>
      </perfect-scrollbar>
    </div>
    <TaskLike
      v-if="localFeedback && !isCheck"
      @closeFeedback="closeFeedback"
      :taskId="taskId"/>
  </div>
</template>

<script>
import TaskLike from '@/components/Task/TaskLike'
export default {
  
  props: {
    isCheck: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    taskId: {
      type: Number,
      required: true
    },
    accessFeedback: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    TaskLike,
  },
  data() {
    return {
      localFeedback: false,
    }
  },
  methods: {
    closeFeedback() {
      this.localFeedback = false
    }
  },
  created() {
    this.localFeedback = this.accessFeedback
  }
}
</script>

<style lang="scss" scoped>
.task-description {
  &-wrapper {
 padding: 32px 34px 32px 22px;
  }
  &-title {
    font-family: "InterBold";
    font-size: 18px;
    margin-bottom: 10px;
  }
  &-subtitle {
    margin-top: 10px;
  }
  .scroll-box {
    height: calc( 100vh - 180px)
  }
  .scroll-box__answer {
    height: calc( 100vh - 265px) 
  }
}

</style>