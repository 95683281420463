import { HTTP } from "@/services/HttpService"
import store from '@/store'

export default {
  getStartPath() {
    const studentId = `students/student_${store.state.user.user.studentInfo.student_id}`
    if (!studentId) {
      this.$router.replace('/auth')
    } else {
      return studentId
    }
  },

  async getFilesTree(taskId) {
    const response = await HTTP.get("/s3/", { params: {
      folder: `task_${taskId}`,
      path: this.getStartPath(),
    }});
    return response.data;
  },

  async createFolder(taskId, path) {
    const response = await HTTP.put("/s3/", {
      folder: `task_${taskId}`,
      path: `${this.getStartPath()}/${path}`,
      create_new_file: false
    });
    return response.data;
  },

  async createFile(taskId, path) {
    const response = await HTTP.put("/s3/", {
      folder: `task_${taskId}`,
      path: `${this.getStartPath()}/${path}`,
      create_new_file: true
    });
    return response.data;
  },

  async deleteItem(taskId, path) {
    const response = await HTTP.delete('/s3/', {data: {
      folder: `task_${taskId}`,
      path: `${this.getStartPath()}/${path}`,
    }})
    return response.data
  },

  async uploadFile(taskId, path, file, fileName) {
    const params = new FormData();
    params.append('folder', `task_${taskId}`);
    params.append('path', `${this.getStartPath()}/${path}`);
    params.append('file', file, fileName);

    const response = await HTTP.post("/s3/", params);

    return response.data.url;
  },

  async uploadFolder(taskId, path, file, fileName) {
    const params = new FormData();
    params.append('folder', `task_${taskId}`);
    params.append('path', `${this.getStartPath()}/${path}`);
    params.append('file', file, fileName);

    const response = await HTTP.put('/s3/zip/', params)
    return response.data
  },

  async renameFile(taskId, path, newPath) {
    const response = await HTTP.patch("/s3/", {
      folder: `task_${taskId}`,
      path: `${this.getStartPath()}/${path}`,
      new_path: `${this.getStartPath()}/${newPath}`
    });

    return response.data.url;
  },

  async getZipTask(taskId) {
    const response = await HTTP.get('/s3/zip/', {params: {
      folder: `task_${taskId}`,
      path: this.getStartPath()
    }})

    return response.data.url
  },

  async getFileContent(taskId, path) {
    const response = await HTTP.get("/s3/edit/", { params: {
      folder: `task_${taskId}`,
      path: `${this.getStartPath()}/${path}`
    }});

    return response.data.content;
  },

  async updateFile(taskId, path, content) {
    const response = await HTTP.put("/s3/edit/", {
      folder: `task_${taskId}`,
      path: `${this.getStartPath()}/${path}`,
      content
    });
    return response.data;
  },
}