<template>
  <div class="popup-notifications">
    <perfect-scrollbar @ps-y-reach-end="loadNewNotifications" ref="scrollbar">
      <div class="popup-notifications-content">
        <span class="popup-notifications__header">{{ $t('header.notifications.title') }}</span>
        <div class="popup-notifications-block" v-if="unReadNotifications.length">
          <span class="popup-notifications__title popup-notifications__title--stuck">{{ $t('header.notifications.unread') }}</span>
          <NotificationItem 
            v-for="(item, i) of unReadNotifications"
            :key="i"
            :notification="item"
            @setNotificationRead="setNotificationRead"
            />
        </div>
        <div class="popup-notifications-block"   v-if="readNotifications.length">
          <span class="popup-notifications__title popup-notifications__title--stuck">{{ $t('header.notifications.read') }}</span>
            <NotificationItem 
             v-for="(item, i) of readNotifications"
             :key="i"
             :notification="item"
             @setNotificationRead="setNotificationRead"
             />
        </div>
      </div>
       <Loader :color="'primary'" v-show="loader"/>
    </perfect-scrollbar>
  </div>
</template>

<script>
import NotificationItem from '@/components/Header/Notifications/NotificationItem'
import apiNotifications from '@/api/notifications'
import Loader from '@/components/Loader'
export default {
  components: {
    Loader,
    NotificationItem
  },
  data() {
    return {
      notifications: [],
      page: 2,
      userId: this.$store.state.user.user.studentInfo.student_id,
      loader: true,
      loadAllNotifications: false,
      readStuck: false,
      readNotifications: [],
      unReadNotifications: []
    }
  },
  props: {
    notificationsCount: {
      type: Number,
      required: false,
      default: null
    },
    currentPage: {
      type: Number,
      required: false,
      default: null
    },
    firstPageNotifications: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  async mounted() {
    if(this.currentPage) this.page = this.currentPage
    if (this.firstPageNotifications.length) {
      this.notifications = this.firstPageNotifications
      this.loader = false
    }     
    this.readNotifications = this.notifications.filter(el => el.viewed)
    this.unReadNotifications = this.notifications.filter(el => !el.viewed)
    let readNotifications = localStorage.getItem('readNotification')
    let unReadNotifications = localStorage.getItem('unReadNotifications')
    if(readNotifications?.length) this.readNotifications = JSON.parse(readNotifications)
    if(unReadNotifications?.length) this.unReadNotifications = JSON.parse(unReadNotifications)
  },
  methods: {
    async loadNewNotifications() {
      if(this.loader === true || this.readNotifications.length + this.unReadNotifications.length === this.notificationsCount) return
      this.loader = true
      const newNotifications = await apiNotifications.getNotifications(this.userId, this.page)
      if (newNotifications.results) {
        this.page++
        this.loader = false
      }
      let readNotifications = newNotifications.results.filter(el => el.viewed )
      let unReadNotifications = newNotifications.results.filter(el => !el.viewed )
      if (readNotifications) this.readNotifications.push(...readNotifications)
      if (unReadNotifications) this.unReadNotifications.push(...unReadNotifications)
       this.$emit('loadNewNotifications', this.page)
    },
    setNotificationRead(notificationId) {
      const readNotificationsIndex = this.unReadNotifications.findIndex(element => element.id === notificationId)
      this.readNotifications.push(this.unReadNotifications[readNotificationsIndex])
      this.unReadNotifications.splice(readNotificationsIndex, 1)
      localStorage.setItem('readNotification', JSON.stringify(this.readNotifications))
      localStorage.setItem('unReadNotifications', JSON.stringify(this.unReadNotifications))  
      this.$emit('changeUnreadLength',this.unReadNotifications.length)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.ps {
  height: 290px;
}
.popup-notifications {
  position: absolute;
  right: -83px;
  width: 530px;
  height: 300px;
  top: 47px;
  background: #fff;
  border: 2px solid $primary;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: left;
  z-index: 50;
  @media screen and (max-width: 768px) {
    width: 300px;
  }
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: -11px;
    right: 80px;
    background: #fff;
    border-top: 2px solid $primary;
    border-right: 2px solid $primary;
    box-sizing: border-box;
    transform: rotate(-45deg);
    z-index: 10;
  }
  &-content {
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    // overflow: hidden;
  }
  &__header {
    color: #2e2c2c;
    font-family: "InterBold";
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 8px;
    margin-bottom: 8px;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  &__title {
    display: block;
    width: fit-content;
    margin: auto;
    position: sticky;
    top: 8px;
    font-family: InterBold;
    font-size: 14px;
    color: #acb2be;
    margin-bottom: 8px;
    padding: 4px 10px;
    &--stuck {
      background: #ffffff;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 60px;
    }
  }
  &-item {
    display: grid;
    grid-template-columns: 48px 1fr;
    column-gap: 16px;
    color: #4c5261;
    align-items: center;
    &__avatar {
      object-fit: cover;
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    &__text {
      display: block;
      margin-bottom: 4px;
      font-size: 14px;
    }
    &__time {
      display: block;
      font-size: 12px;
    }
  }
}
</style>