<template>
  <div class="editor-wrapper">
    <editor
      v-model="content"
      @init="editorInit"
      :change="onChangeCode()"
      lang="python"
      :theme = themeName
      width="100%"
      :height="height"
      ref="myEditor">
    </editor>
    <div class="editor-options">
      <dropdown v-if="isDropdown" @toggleContent="toggleContent">
        <template v-slot:trigger>
          <inline-svg class="settings-icon" :src="require('@/assets/svg/editor-settings.svg')"/>
          <inline-svg :class="['settings-icon__arrow',{'rotate': rotate}]" :src="require('@/assets/svg/editor-arrow.svg')"/>
        </template>
        <template v-slot:content>
          <ul class="options-list">
            <li>
              {{$t('pageTask.codeEditor.theme')}}
              <select v-model="themeName" class="select-theme">
                  <option v-for="theme in themes" :key="theme" :value="theme">{{theme}}</option> 
                </select> 
            </li>
            <li>{{$t('pageTask.codeEditor.textSize')}}
              <div>
                <span class="options-btn" @click="decreaseFont()">
                <inline-svg :src="require('@/assets/svg/editor-text-minus.svg')"/>
              </span>
              <span class="options-btn" @click="enlargeFont()">
                <inline-svg :src="require('@/assets/svg/editor-text-plus.svg')"/>
              </span>
              </div>
            </li>
            <li>{{$t('pageTask.codeEditor.wordWrap')}} <switcher @checked="setWordWrap($event)"></switcher></li>
          </ul>
        </template>
      </dropdown>
    </div>
  
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'
import Switcher from '@/components/Switcher.vue'

export default {
  name: 'CodeEditor',
  props: {
     isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    isDropdown: {
      type: Boolean,
      required: false,
      default: true
    },
    themeDefault: {
      type: String,
      required: false,
      default: 'ambiance'
    },
    height: {
      required: false,
    },
    code: String,
  },
  components: {
    editor: require('vue2-ace-editor'),
    Dropdown,
    Switcher
  },
  data() {
    return {
      rotate: false,
      editor: null,
      content: this.code,
      fontSize: 16,
      themeName: 'ambiance',
      themes: ['ambiance','chrome','chaos','clouds','clouds_midnight','cobalt','crimson_editor','dawn','dracula','dreamweaver','eclipse','github','gob','idle_fingers','iplastic','katzenmilch','kr_theme','kuroir','merbivore','merbivore_soft','mono_industrial','pastel_on_dark','solarized_dark','solarized_light','sqlserver','terminal','textmate','tomorrow','tomorrow_night','tomorrow_night_blue','tomorrow_night_bright','tomorrow_night_eighties','twilight','vibrant_ink','xcode'],
    }
  },
  methods: {
    editorInit: function() {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/html");
      require("brace/mode/python"); //language
      require("brace/mode/less");
      require("brace/theme/ambiance");
      require("brace/theme/chrome");
      require("brace/theme/tomorrow_night_eighties");
      require("brace/theme/crimson_editor");
      require("brace/theme/clouds");
      require("brace/theme/dawn");
      require("brace/theme/dracula");
      require("brace/theme/dreamweaver");
      require("brace/theme/eclipse");
      require("brace/theme/github");
      require("brace/theme/gob");
      require("brace/theme/idle_fingers");
      require("brace/theme/iplastic");
      require("brace/theme/katzenmilch");
      require("brace/theme/kr_theme");
      require("brace/theme/kuroir");
      require("brace/theme/merbivore");
      require("brace/theme/merbivore_soft");
      require("brace/theme/mono_industrial");
      require("brace/theme/solarized_light");
      require("brace/theme/terminal");
      require("brace/theme/vibrant_ink");
      require("brace/snippets/python"); //snippet
      this.$refs.myEditor.editor.setReadOnly(this.isReadOnly)
    },
    setFontSize(size) {
      this.editor.setOptions({fontSize: `${size}px`})
    },
    setWordWrap(e) {
      this.editor.setOptions({wrap: e})
    },
    enlargeFont(){
      this.setFontSize(++this.fontSize)
    },
    decreaseFont(){
      this.setFontSize(--this.fontSize)
    },
    onChangeCode(){
      this.$emit('code-change', this.content);
    },
    toggleContent(param) {
      param ? this.rotate = false : this.rotate = !this.rotate
    }
  },
  mounted() {
    // this.$refs.myEditor.editor.setReadonly(true)
    this.editor = this.$refs.myEditor.editor
    // this.editor.setReadonly(true)
    this.setFontSize(this.fontSize);
    this.themeDefault ? this.themeName = this.themeDefault : false
  },
}
</script>

<style lang="scss">
  .rotate {
    transform: rotate(180deg);
  }
  .settings-icon {
    margin-right: 8px;
    &__arrow {
      transition: .3s;
    }
  }
    .ace_scrollbar {
      scrollbar-width: thin;
      scrollbar-color: #9EA1AC transparent;
    }
    .ace_scrollbar::-webkit-scrollbar {
      background-color: #eee
    } 
    .ace_scrollbar::-webkit-scrollbar-thumb{
      background-color: #9EA1AC
    }
    .ace_scrollbar-v {
      width: 16px
    }
    .ace_scrollbar-h {
      height: 16px
    }

  .ace_scrollbar-h::-webkit-scrollbar-thumb, .ace_scrollbar-v::-webkit-scrollbar-thumb{
    border: 5px solid transparent;
    border-radius: 8px;
    background-clip: content-box;
  }
  .ace_scrollbar::-webkit-scrollbar {
      background: transparent;
      opacity: .9;
  } 

  .editor-wrapper {
    display: flex;
    height: 100%;
    position: relative;
  }
  .editor-options {
    position: absolute;
    right: 32px;
    bottom: 24px;
    z-index: 10;
    @media(max-width: 900px) {
      right: 16px;
      bottom: 16px;
    }
  }
  .options-list {
    width: 220px;
    li {
      padding: 16px;
      font-size: 14px;
      border-top: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        border: none
      }
    }
  }
  .options-btn {
    margin-left: 8px;
    cursor: pointer;
  }
  .select-theme {
    width: 120px;
  }
</style>