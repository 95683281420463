<template>
  <div class="login">
    <div class="login-content">
      <span class="login__title">{{$t('auth.login.title')}}</span>
      <div class="login-socials">
        <template v-if="lang === 'ru'">
          <a
              href="https://platform.kodland.org/ru/social/vk/login/?process="
              v-tooltip="$t('auth.login.socials.vk')"
              class="login-socials__element"
              @click="ymGoal('LoginVK')">
            <inline-svg :src="require('@/assets/svg/white-vk.svg')"/>
          </a>
          <a
              href="https://platform.kodland.org/ru/social/yandex/login/?process="
              v-tooltip="$t('auth.login.socials.yandex')"
              class="login-socials__element"
              @click="ymGoal('LoginYandex')">
            <inline-svg :src="require('@/assets/svg/white-yandex.svg')"/>
          </a>
          <a
              href="https://platform.kodland.org/ru/social/google/login/?process="
              v-tooltip="'Google'"
              class="login-socials__element"
              @click="ymGoal('loginGoogle')">
            <inline-svg :src="require('@/assets/svg/white-google.svg')"/>
          </a>
        </template>
        <template v-else>
<!--          <a-->
<!--              href="https://platform.kodland.org/en/social/twitter/login/?process="-->
<!--              v-tooltip="'Twitter'"-->
<!--              class="login-socials__element"-->
<!--              @click="ymGoal('loginTwitter')"-->
<!--          >-->
<!--            <inline-svg :src="require('@/assets/svg/white-twitter.svg')"/>-->
<!--          </a>-->
<!--          <a-->
<!--              href="https://platform.kodland.org/en/social/facebook/login/?process="-->
<!--              v-tooltip="'Facebook'"-->
<!--              class="login-socials__element"-->
<!--              @click="ymGoal('loginFacebook')"-->
<!--          >-->
<!--            <inline-svg :src="require('@/assets/svg/white-facebook.svg')"/>-->
<!--          </a>-->
          <a
              href="https://platform.kodland.org/en/social/google/login/?process="
              v-tooltip="'Google'"
              class="login-socials__element"
              @click="ymGoal('loginGoogle')"
          >
            <inline-svg :src="require('@/assets/svg/white-google.svg')"/>
          </a>
        </template>
      </div>
      <span class="login-or">{{ $t('auth.login.or') }}</span>
      <form @submit.prevent="signin">
        <div class="login-input-container">
          <input
            :class="['input-text', 'login-input', {'input-text--error': errorLogin}]"
            v-model.trim="login"
            type="text"
            name="login"
            id="login"
            :placeholder="$t('auth.login.inputs.placeholderLogin')">
          <span class="input-error-text" v-if="errorLogin">{{$t('auth.errors.validateLogin')}}</span>
        </div>
        <div class="login-input-container">
          <input
            class="input-text login-input"
            v-model.trim="password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            id="password"
            :placeholder="$t('auth.login.inputs.placeholderPassword')">
          <inline-svg
            v-if="showPassword"
            @click="toggleShowPassword"
            class="login-input__toggle-password"
            :src="require('@/assets/svg/visibility-password.svg')"/>
          <inline-svg
            v-else
            @click="toggleShowPassword"
            v-tooltip="$t('auth.login.inputs.showPassword')"
            class="login-input__toggle-password"
            :src="require('@/assets/svg/hidden-password.svg')"/>
        </div>
        <button
          class="btn btn--primary btn--fullwidth login-button-login"
          :disabled="!login || !password || sendRequestSignin">
            <span v-if="!sendRequestSignin">{{$t('auth.login.buttons.signin')}}</span>
            <Loader v-else/>
        </button>
      </form>
      <div class="message message--error" v-if="responseError">
        {{$t('auth.errors.wrongLoginOrPassword')}}
      </div>
      <div class="message message--error" v-if="socialLoginError">
        {{$t('auth.errors.socialLoginError')}}
      </div>
      <span class="link login-recovery-password" @click="recoveryPasswordLink">{{$t('auth.login.links.forgotLoginOrPassword')}}</span>
    </div>
    <button
      class="btn btn--secondary btn--fullwidth login-button-login"
      @click="signup">{{$t('auth.login.buttons.signup')}}</button>
  </div>
</template>

<script>
import apiUser from '@/api/user'
import validations from '@/helpers/validations'
import Loader from '@/components/Loader'

export default {
  name: 'signin',
  components: {
    Loader
  },
  data() {
    return {
      login: '',
      password: '',
      loginAttempt: false,
      showPassword: false,
      sendRequestSignin: false,
      errorLogin: false,
      responseError: false,
    }
  },
  computed: {
    lang() {
      return this.$store.state.user.language
    },
    studentId(){
      return this.$store.state.user.user.studentInfo.student_id
    },
    socialLoginError() {
      if (this.$route.query.socialLoginError) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ymGoal(social) {
      this.$metrika.reachGoal(social)
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    async signin() {
      this.loginAttempt = true;
      this.responseError = false;
      const valid = this.checkValid();
      if (valid) {
        this.sendRequestSignin = true;
        const response = await apiUser.signin(this.login, this.password);
        if (response === 'error') {
          this.responseError = true
        } else {
          if (this.$metrika) {
            this.$metrika.reachGoal('login')
          }
          this.sendRequestSignin = false;
          if (this.$route.query.next && this.$route.query.next.includes('tally.so')) {
            await this.$router.push({ name: 'Iframe', query: { next: this.$route.query.next, student_id: this.studentId } });
          }
          if (this.$route.query.next && !this.$route.query.next.includes('tally.so')) {
            window.location.replace(this.$route.query.next)
          } else if(!this.$route.query.next) {
            window.location.replace(`https://${window.location.hostname}/${this.lang}/courses/`)
          }
        }
        this.sendRequestSignin = false;
      }
    },
    signinSocial(social) {
      switch (social) {
        case 'vk':
          if (this.$metrika) {
            this.$metrika.reachGoal('LoginVK')
          }
          break
        case 'google':
          if (this.$metrika) {
            this.$metrika.reachGoal('loginGoogle')
          }
          break
        case 'yandex':
          if (this.$metrika) {
            this.$metrika.reachGoal('LoginYandex')
          }
          break
      }
    },
    checkValid() {
      if (this.loginAttempt) { // check validation only if were login attempt
        const validationLogin = validations.countSymbols(this.login, 2, 32);
        if (!validationLogin) {
          this.errorLogin = true;
          return false;
        } else {
          this.errorLogin = false;
          return true;
        }
      }
    },
    signup() {
      this.$emit('changeTypeAuth', 'signup')
    },
    recoveryPasswordLink() {
      this.$emit('changeTypeAuth', 'recovery')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.login {
  width: 400px;
  margin: auto;
  &__title {
    font-family: "JetBrainsMonoExtraBold";
    font-size: 32px;
    color: $primary;
    display: block;
    text-align: center;
    margin-bottom: 40px;
  }
  &-socials {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    &__element {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background: $primary;
      margin: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      position: relative;
      &:hover {
        background: $primaryHover;
      }
    }
  }
  &-or {
    display: block;
    text-align: center;
    margin-bottom: 16px;
    color: $grey;
  }
  &-input {
    &-container {
      position: relative;
      margin-bottom: 16px;
    }
    &__toggle-password {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  &-button {
    &-login {
      margin-bottom: 16px;
    }
  }
  &-recovery-password {
    padding-bottom: 40px;
    text-align: center;
  }
}

@media screen and (max-width: 1023px) {
  .login {
    &-socials {
      &__element {
        width: 40px;
        height: 40px;
      }
    }
    &-recovery-password {
      padding-bottom: 20px;
    }
    &-content {
      background: #fff;
      padding: 20px 20px 0 20px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .login {
    width: 100%;
    max-width: 500px;
    &__title {
      font-size: 24px;
    }
    &-socials {
      &__element {
        margin: 0 5px;
      }
    }
  }
}

@media screen and (max-width: 374px) {
  .login {
    &-socials {
      &__element {
        margin: 0 5px;
      }
    }
  }
}
</style>
