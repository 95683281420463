<template>
  <transition>
    <div class="modal" v-show="showModal">
      <div
        v-click-outside="onClickOut"
        :class="[{'modal-box': !outsideModalCloseIcon, 'modal-outside_close-icon': outsideModalCloseIcon,} , {modal_fullscreen: fullscreen, 'modal-box--small': size === 'small', 'modal-box--medium': size === 'medium', }]">
        <div class="modal-btns">
          <i class="modal-btn" @click="fullScreen(false)" v-show="fullscreen && showExpand">
            <inline-svg :src="require('@/assets/svg/modal-rollup.svg')"/>
          </i>
          <i class="modal-btn" @click="fullScreen(true)" v-show="!fullscreen && showExpand">
            <inline-svg :src="require('@/assets/svg/modal-expand.svg')"/>
          </i>
          <i class="modal-btn" @click="hideModal" v-if="showClose">
            <inline-svg :class="['modal-close-icon',{'modal-close-icon_white': outsideModalCloseIcon}]" :src="require('@/assets/svg/modal-close.svg')"/>
          </i>
        </div>
        <div class="modal-wrapper">
          <div class="modal-content">
            <slot name="content"></slot>
          </div>
          <div class="modal-footer" v-if="showFooter">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'Modal',
  props: {
    outsideModalCloseIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true
    },
    showExpand: {
      type: Boolean,
      required: false,
      default: true
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      required: false,
      default: 'big'
    },
    closeOnOverlay: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showModal: false,
      fullscreen: false,
      justOpened: false,
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.justOpened = true;
      setTimeout(()=>{this.justOpened = false},0)
    },
    hideModal() {
      if(this.showModal) this.showModal = false
    },
    onClickOut() {
      // Если разрешено кликать вне модалки
      if (this.closeOnOverlay) {
        if(!this.justOpened) this.hideModal()
      }
    },
    fullScreen(full) {
      this.fullscreen = full
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss">
.modal {
  background: rgba(0,0,0,.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  &-outside_close-icon {
    max-width: 700px;
    min-width: 700px;
    background: #fff;
    border-radius: 5px;
    display: flex;
  }
  &-box {
    max-width: 700px;
    min-width: 700px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    display: flex;
    &--small {
      min-width: 366px;
      max-width: 366px;
      padding: 24px;
    }
    &--medium {
      min-width: 570px;
      max-width: 570px;
      padding: 24px;
    }
  }
  &-wrapper {
      display: flex;
      flex-direction: column;
      min-width: 100%;
  }
  &-content {
    flex: 1;
    // overflow-y: scroll;
  }
  &-footer {
      min-height: 82px;
      border-top: 1px solid #ACB2BE;
      padding: 16px;
      background: #fff;
    }
  &_fullscreen {
    min-height: 97vh;
    max-height: 97vh;
    min-width: 97vw;
  }
  &-btns {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 16px;
    padding-right: 4px;
    z-index: 1;
  }
  &-btn {
        margin: 0 12px;
        cursor: pointer;
    }
  &-close-icon{
    fill: #141F43;
  }
  &-close-icon_white{
    fill: #fff;
  }
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 767px) {
  .modal {
    &-box {
      &--small, &--medium {
        max-width: 90%;
        min-width: 90%;
      }
    }
    &-footer {
      background: #fff
    }
  }
}
</style>