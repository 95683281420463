<template>
  <router-link class="popup-notifications-item_link"  :to="notificationPath">
  <div class="popup-notifications-item" @click="readNotifications(notification)">
    <div>
      <img class="popup-notifications-item__avatar" :src="notification.from_who_data.avatar">
    </div>
    <div>
      <span v-if="isComment" class="popup-notifications-item__text">{{$t('header.notifications.checkTask')}}</span> 
      <span v-else class="popup-notifications-item__text">{{$t('header.notifications.writeComment')}}</span>
      <span class="popup-notifications-item__time">{{notificationDate}} </span>
    </div>
  </div>
  </router-link>
</template>

<script>
import apiNotifications from '@/api/notifications'
import Dates from "@/helpers/Dates";
export default {

  props: {
   notification: Object
  },
  computed: {
    notificationPath() {
      return `/task/${this.notification.payload.project_id || this.notification.payload.task_id || this.notification.payload.comment_id}`
    },
    isComment () {
      return  this.notification.subtype_slug === 'comment_task' ? true : false
    },
    notificationDate () {
      return new Dates(this.notification.created_at).getNotificationFormat()
    }
  },
  methods: {
    async readNotifications(notification) {
      if (notification.userRead || notification.viewed) return 
      else this.notification.userRead = true
      await apiNotifications.setNotificationRead(notification.id)
      this.$emit('setNotificationRead',notification.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";
  .popup-notifications-item {
    display: grid;
    grid-template-columns: 48px 1fr;
    column-gap: 16px;
    color: #4c5261;
    align-items: center;
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
    &_link {
      color: $dark;
    }
    &__avatar {
      object-fit: cover;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      
    }
    &__text {
      display: block;
      margin-bottom: 4px;
      font-size: 14px;
    }
    &__time {
      display: block;
      font-size: 12px;
    }
  }
</style>