<template>
  <div
    :class="['loader', {
      'primary': color === 'primary',
      'center': position === 'center',
      'absolute': typePosition === 'absolute'
      }]"></div>
</template>

<script>
export default {
  name: 'loader',
  props: {
    color: {
      type: String,
      required: false
    },
    position: {
      type: String,
      required: false
    },
    typePosition: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 6px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  // transform: translateZ(0);
  transform: translateY(-15px);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  &.primary {
    color: $primary;
  }
  &.center {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  &.absolute {
    position: absolute;
  }
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
</style>