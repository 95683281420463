<template>
  <div class="rate">
    <span class="rate-smile">{{selectedRate}}</span>
    <div class="rate-stars">
      <inline-svg
        v-for="rate in rates"
        :key="rate.star"
        :src="require('@/assets/svg/star.svg')"
        :class="['rate-star', {'rate-star--selected': rate.selected}]"
        @click="selectRate(rate.star)"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rates: [
        {star: 1, selected: false, smile: '😟'},
        {star: 2, selected: false, smile: '😔'},
        {star: 3, selected: false, smile: '😐'},
        {star: 4, selected: false, smile: '😊'},
        {star: 5, selected: false, smile: '🤩'}
      ]
    }
  },
  computed: {
    selectedRate() {
      const lastIndex = this.rates.map(el => el.selected).lastIndexOf(true)
      if (lastIndex !== -1) {
        this.$emit('saveRate', this.rates[lastIndex].star)
        return this.rates[lastIndex].smile
      } else {
        return '🤔'
      }
    }
  },
  methods: {
    selectRate(id) {
      this.rates.forEach(el => {
        el.selected = false
        if (id >= el.star) {
          el.selected = true
        }
      })

      const lastIndex = this.rates.map(el => el.selected).lastIndexOf(true)
      this.$emit('saveRate', this.rates[lastIndex].star)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.rate {
  &-stars {
    text-align: center;
  }
  &-star {
    cursor: pointer;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    &--selected {
      fill: $primary;
    }
  }
  &-smile {
    display: block;
    text-align: center;
    font-size: 30px;
    margin-bottom: 24px;
  }
}
</style>