import { HTTP } from "@/services/HttpService"
import { SSO } from "@/services/SSOService"
import { SSONew } from "@/services/SSOServiceNew"
import cookies from 'js-cookie'
import store from '../store'
import i18n from '@/plugins/i18n'

export default {
  async signin(username, password) {
    let formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)

    try {
      const response = await SSO.post('/login', formData)
      cookies.set('access', response.data.access_token, {domain: ".kodland.org"})
      cookies.set('refresh', response.data.refresh_token, {domain: ".kodland.org"})
    } catch (error) {
      console.log(error.response);
      return 'error'
    }
  },
  async signup(firstname, lastname, email) {
    try {
      const response = await HTTP.post('/users/register/', {
        first_name: firstname, last_name: lastname, email
      }, {
        headers: {
          "Accept-Language": store.state.user.language
        },
      })
      cookies.set('access', response.data.access_token, {domain: ".kodland.org"})
      cookies.set('refresh', response.data.refresh_token, {domain: ".kodland.org"})
      return 'ok';
    } catch (error) {
      if (error.response.status >= 500) {
        return i18n.t('apiError.serverError')
      } else {
        return error.response.data.user_msg
      }
    }
  },
  async recoveryPassword(email) {
    try {
      await HTTP.post(`users/reset_password/`, {email}, {
        headers: {
          "Accept-Language": store.state.user.language
        }
      })
      return true;
    } catch (error) {
      console.log(error.response);
      return 'error'
    }
  },

  async userInfo() {
    try {
      const response = await SSONew.get('/info')
      let role = null
      if (response.data.groups.includes('student')) role = 'student'
      if (response.data.groups.includes('teacher')) role = 'teacher'
      if (response.data.groups.includes('methodologist')) role = 'methodologist'
      store.commit('user/setUserRole', role)
      store.commit('user/setUserId', response.data.user_id)
    } catch (error) {
      console.log(error.response);
    }
  },

  async studentInfo() {
    let formData = new FormData()
    formData.append('param', 'all')
    formData.append('user_id', store.state.user.user.id)
    try {
      const response = await SSONew.post('/student_info', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      console.log(response.data);
      store.commit('user/setStudentInfo', response.data)
    } catch (error) {
      console.log(error.response);
    }
  }
}