// import Vue from 'vue'
import { HTTP } from "@/services/HttpService"
import router from '../router'
import store from '../store'
import i18n from '@/plugins/i18n'

export default {
  async resetTaskFields(taskId) {
    return await HTTP.post(`/tasks/${taskId}/reset/`)
  },
  
  async deleteTask(taskId) {
    const response = await HTTP.delete(`/task/${taskId}/`)
    return response.data
  },

  async changeTaskFields(taskId, taskFields) {
    const response = await HTTP.put(`/task/${taskId}/`, {
      title: taskFields.taskTitle,
      number: taskFields.taskNumber,
      text_of_task: taskFields.taskText,
      is_theory: taskFields.isTheory,
      is_graded: taskFields.isGraded,
      max_grade: taskFields.maxGrade || null,
      solution: taskFields.decisionCode
    })
    return response.data;
  },
  async gradeTask(progressId, taskGrade, taskComment) {
    const response = await HTTP.put(`/grade/${progressId}/`, {
      grade: taskGrade,
      comment: taskComment
    })
    return response.data;
  },
  getUserId() {
    return store.state.user.user.id
  },
  userRole() {
    return store.state.user.user.role 
  },
  getStudentId() {
    return store.state.user.user.studentInfo.student_id
  },
  async getTask(taskId) {
    try {
      const response = await HTTP.get(`/task/${taskId}/`)
      return response.data
    } catch (err) {
      if (err.response.status === 404) {
        router.push(`/error?code=403&text=${i18n.t('errorPage.dontExistTask')}`)
      }
    }
  },
  async taskCheckFeedback(taskId) {
    try {
      const response = await HTTP.post('/feedbacks/check/', {
        task: taskId
      })
      return response.data.already_exist;
    } catch (err) {
      console.log(err.response);
    }
  },
  async taskFeedback(taskId, value) {
    try {
      const response = await HTTP.post('/feedbacks/', {
        task: taskId,
        is_positive: value
      })
      return response.data
    } catch (err) {
      console.log(err.response);
    }
  },
  async getTempProgressTask(taskId, studentId) {
    try {
      const response = await HTTP.get(`/tasks/${taskId}/temp_progresses/?user_id=${studentId}`)
      return response.data
    } catch (err) {
      console.log(err.response);
    }
  },
  async getProgressTask(taskId, userId) {
    try {
      const response = await HTTP.get(`/progress/?task_id=${taskId}&user_id=${userId}`)
      return response.data
    } catch (err) {
      if (err.response.status === 404) {
        return err.response.status
      }
      if (err.response.status === 403) {
        router.push(`/error?code=403&text=${i18n.t('errorPage.text403')}`)
      }
      console.log(err.response);
    }
  },
  async checkProgressTask(taskId, solution) {
    try {
      await HTTP.post(`/progress/`, {
        student: this.getStudentId(),
        task: taskId,
        solution
      })
    } catch (err) {
      console.log(err.response);
    }
  },
  async saveTempProgressTask(taskId,solution) {
    return await HTTP.post(`/tasks/${taskId}/temp_progresses/?user_id=${this.getUserId()}`,{solution: solution})
  },
  async resetProgressTask(taskId) {
    await HTTP.post(`/tasks/${taskId}/reset/`)
  },
}