function windowConfig(varName) {
  if (window.config) {
    return window.config[varName];
  } else {
    return undefined;
  }
}

export default {
  PLATFORM_API:
    windowConfig("VUE_APP_PLATFORM_API") ||
    "https://backoffice.dev.kodland.org/api/v1/",
  DEV_SSO_API:
    windowConfig("VUE_APP_DEV_SSO_API") || "https://sso.dev.kodland.org/",
  SSO_API: windowConfig("VUE_APP_SSO_API") || "https://sso.dev.kodland.org/",
  NOTIFICATIONS_API:
    windowConfig("VUE_APP_NOTIFICATIONS_API") ||
    "https://notifications.dev.kodland.org/api/v1/",
  UPLOADS_URL:
    windowConfig("VUE_APP_UPLOADS_URL") ||
    "https://backoffice.dev.kodland.org/uploads/",
  PLATFORM_URL:
    windowConfig("VUE_APP_PLATFORM_URL") || "https://platform.dev.kodland.org",
  SELECTEL_CONTAINER:
    windowConfig("VUE_APP_SELECTEL_CONTAINER") ||
    "https://442310.selcdn.ru/test_platform_container/",
  SCRATCH_BACK_URL:
    windowConfig("VUE_APP_SCRATCH_BACK_URL") ||
    "https://test-scratch-back.kodland.org",
  SCRATCH_FRONT_URL:
    windowConfig("VUE_APP_SCRATCH_FRONT_URL") ||
    "https://test-scratch-front.kodland.org",
};
