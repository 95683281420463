import axios from "axios"
import config from "@/config"
import Cookies from "js-cookie"

export const SSONew = axios.create({
  baseURL: config.DEV_SSO_API,
  headers: {
    'Content-Type': 'multipart/form-data',
  }
});

SSONew.interceptors.request.use(async function(config) {
  const token = Cookies.get('access')
  if (token) config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});