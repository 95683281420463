import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VTooltip from 'v-tooltip'
import VueToast from 'vue-toast-notification'
import i18n from './plugins/i18n'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueYandexMetrika from 'vue-yandex-metrika'
import dayjsPlugin from "./helpers/date"
import "@/libs/skulpt.min.js"
import "@/libs/skulpt-stdlib.js"
import Vue2Editor from "vue2-editor";
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import ApiUser from '@/api/user'

import {InlineSvgPlugin} from 'vue-inline-svg'

import 'swiper/swiper-bundle.css';

Vue.config.productionTip = false

Vue.use(Vue2Editor)
Vue.use(InlineSvgPlugin)
Vue.use(VTooltip)
Vue.use(VueToast)
Vue.use(PerfectScrollbar)
Vue.use(VueYandexMetrika, {
  id: 72125035,
  router: router,
  env: process.env.NODE_ENV,
  lickmap:true,
  trackLinks:true,
  accurateTrackBounce:true,
  webvisor:true
})

Vue.use(dayjsPlugin)

let instance = Vue.$toast.open('You did it!')
instance.dismiss()
Vue.$toast.clear()

beforeStart().then(() => {
 new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
 
async function beforeStart() {
  store.commit('user/setLanguageLoadPage')
  await ApiUser.userInfo()
  await ApiUser.studentInfo()
}
