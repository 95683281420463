<template>
<iframe :src="formattedUrl" frameborder="0" style="height: 100vh; width: 100%"/>
</template>

<script >
  export default {
    name: 'IframeForm',
    props: {
      url: String,
      studentId: String
    },
    computed: {
      formattedUrl() {
        return this.url + (this.url.includes('?') ? '&' : '?') + `student_id=${this.studentId}`;
      }
    },
    // Костыль, но пока не нашел еще вариантов рабочих
    mounted() {
      if (!this.studentId) {
        console.log('Student ID is undefined, reloading...');
        window.location.reload();
      }
    }
  }
</script>

