<template>
  <div :class="['chat-container', {'chat-container_tab' : tabContentChat}]">
    <perfect-scrollbar class="chat" ref="chat" :style="{height: `calc(100vh - ${minusHeight}px)`}">
      <div class="chat-scroll">
        <Messages
          v-for="(item, i) of messages"
          :key="i"
          :message="item"/>
      </div>
    </perfect-scrollbar>
    <div class="chat-new" ref="chatMessage">
      <div class="chat-new-container">
        <div
          class="chat-new__text"
          contenteditable="true"
          @input="calculateOfMessageHeight"
          @focusout="inputMessage"
          v-html="messageText"
          :placeholder="this.$t('pageTask.windowTask.chat.writeAnswer')"></div>
        <div class="chat-new-icons">
          <label class="file-explorer-upload-popup__element">
            <img class="chat-new__upload" :src="require('@/assets/svg/upload-icon.svg')">
            <input type="file" ref="files" multiple @change="handleFilesUpload" style="display: none" />
          </label>
          <div v-click-outside="hideEmoji">
            <VEmojiPicker
              :emojiSize="25"
              :showSearch="false"
              :emojisByRow="8"
              @select="selectEmoji"
              v-if="showEmoji"/>
            <img
              class="chat-new__emoji"
              @click="toggleShowEmoji"
              :src="require('@/assets/svg/emoji.svg')">
          </div>
        </div>
      </div>
      <span>
        <img class="chat-new-send" @click="sendMessage"  :src="require('@/assets/svg/send-message.svg')">
      </span>
    </div>
  </div>
</template>

<script>
// import apiChat from '@/api/chat'
import ClickOutside from 'vue-click-outside'
import { VEmojiPicker } from 'v-emoji-picker'
import Dates from "@/helpers/Dates"
import Messages from '@/components/Task/TaskChat/TaskChatMessage'
export default {
  name: 'taskMessage',
  directives: {
    ClickOutside
  },
  components: {
    VEmojiPicker,
    Messages
  },
  data() {
    return {
      isStudent: false,
      files: '',
      messages: [],
      messageHeight: 0,
      messageText: '',
      showEmoji: false
    }
  },
  props: {
    minusHeight: {
      type: Number,
      required: false
    },
    tabContentChat: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleFilesUpload(){
      this.files = this.$refs.files.files;
    },
    async sendMessage() {
      // await apiChat.sendMessage()
      if(!this.messageText) {
        return
      }
      const message = {
        text: this.messageText,
        date: new Dates().getNotificationFormat(),
        files: this.files
      } 
      if(this.isStudent) message.student = true
       this.messages.push(message)
      // await this.submitFiles()
      this.files = []
      this.messageText = ''
    },
    async submitFiles(){
      if(!this.files) return
      let formData = new FormData();
      this.files.forEach((element,index) => {
      console.log(element);
      formData.append('files[' + index + ']', element)
      console.log(formData);
      });
      // await apiChat.sendFiles(formData)
    },
    deleteMessage(index) {
      this.studentMessage.splice(index, 1)
    },
    calculateOfMessageHeight() {
      this.messageHeight = this.$refs.chatMessage.clientHeight
    },
    inputMessage(e) {
      this.messageText = e.target.innerHTML
      this.calculateOfMessageHeight()
    },
    selectEmoji(emoji) {
      this.messageText = this.messageText + emoji.data
    },
    toggleShowEmoji() {
      this.showEmoji = !this.showEmoji
    },
    hideEmoji() {
      this.showEmoji = false
    }
  },
  mounted() {
    // this.calculateOf()
    this.$nextTick(() => {
      const chatContainer = this.$refs.chat.$el
      chatContainer.scrollTop = chatContainer.scrollHeight
    })
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.emoji-picker {
  position: absolute;
  right: 0;
  bottom: 30px;
}
.chat {
  height: 100%;
  padding: 32px;
  padding-bottom: 0px;
  &-scroll {
    display: grid;
    grid-template-columns: 1fr;
    align-items: end;
    grid-template-rows: 1fr;
    height: 100%;
  }
  &-container {
    background-image: url('/images/task-chat-bg.png');
    background-position: right top;
    background-repeat: no-repeat;
    height: 100%;
    background-size: 50%;
    &_tab {
      height: calc(100vh - 230px);
    }
  }
  &-new {
    border-top: 1px solid #D9D9D9;
    padding: 22px 30px 22px 24px;
    background: #fff;
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 29px;
    column-gap: 20px;
    &-container {
      position: relative;
    }
    &__text {
      min-height: 40px;
      border: 1px solid #D9D9D9;
      border-radius: 5px;
      background: #fff;
      font-size: 14px;
      line-height: 16px;
      padding: 12px 90px 16px 12px;
      word-break: break-all;
      &:focus {
        border: 1px solid $primary;
        outline: none;
      }
      &[placeholder]:empty:before {
        content: attr(placeholder);
        color: #555;
      }
    }
    &-icons {
      position: absolute;
      right: 9px;
      bottom: 7px;
      display: flex;
      align-items: center;
      z-index: 3;
    }
    &__emoji {
      margin-left: 11px;
      cursor: pointer;
      display: block;
    }
  }

}
</style>