<template>
  <div class="recovery">
    <div class="recovery-content">
      <span class="recovery__title">{{$t('auth.recovery.title')}}</span>
      <form @submit.prevent="recovery">
        <div class="recovery-input-container">
          <div class="registration-input-container">
            <input
              :class="['input-text', 'registration-input']"
              v-model.trim="email"
              type="text"
              name="email"
              id="email"
              :placeholder="$t('auth.signup.inputs.email')">
          </div>
        </div>
        <div class="message message--error" v-if="errorRecoveryPassword">
          {{$t('auth.errors.accountNotFound')}}
        </div>
        <button
          class="btn btn--primary btn--fullwidth recovery-button"
          :disabled="!validEmail || sendRequestRecovery">
            <span v-if="!sendRequestRecovery">{{$t('auth.recovery.buttons.recovery')}}</span>
            <Loader v-else/>
        </button>
      </form>
      <div class="recovery-description" v-html="$t('auth.recovery.description')"></div>
      <span class="link recovery-back" @click="backInSignIn">{{$t('auth.recovery.linkBack')}}</span>
    </div>
  </div>
</template>

<script>
import apiUser from '@/api/user'
import validations from '@/helpers/validations'
import Loader from '@/components/Loader'

export default {
  name: 'recoveryPassword',
  components: {
    Loader
  },
  data() {
    return {
      email: '',
      sendRequestRecovery: false,
      errorRecoveryPassword: false
    }
  },
  computed: {
    validEmail() {
      if (validations.email(this.email)) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async recovery() {
      if (this.validEmail && !this.sendRequestRecovery) {
        this.errorRecoveryPassword = false;
        this.sendRequestRecovery = true;
        const response = await apiUser.recoveryPassword(this.email);
        this.sendRequestRecovery = false;
        if (response === 'error') {
          this.errorRecoveryPassword = true;
        } else {
          this.$emit('changeTypeAuth', 'recovery-completed')
        }
      }
    },
    backInSignIn() {
      this.$emit('changeTypeAuth', 'signin')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.recovery {
  width: 400px;
  margin: auto;
  &__title {
    font-family: "JetBrainsMonoExtraBold";
    font-size: 32px;
    color: $primary;
    display: block;
    text-align: center;
    margin-bottom: 40px;
  }
  &-input {
    &-container {
      position: relative;
      margin-bottom: 16px;
    }
  }
  &-button {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  &-description {
    text-align: center;
    font-size: 14px;
    margin-bottom: 40px;
  }
  &-back {
    font-family: "InterSemiBold";
    text-align: center;
  }
}

@media screen and (max-width: 1023px) {
  .recovery {
    &-content {
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
    }
    &-text {
      padding-bottom: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .recovery {
    width: 100%;
    max-width: 500px;
    &__title {
      font-size: 24px;
    }
    &-button {
      &-login {
        margin-bottom: 20px;
      }
    }
  }
}
</style>