<template>
  <header class="header">
    <div class="header-left">
      <router-link to="/" class="header-logo">
        <inline-svg :src="require('@/assets/svg/header-logo.svg')"/>
      </router-link>
      <Breadcrumbs
        :breadcrumbs="breadcrumbs"
        :teacherBreadcrumbs="teacherBreadcrumbs"
        :paginatedTask="paginatedTask"
        v-if="breadcrumbs"
        @goToStep="goToStep"
        />
    </div>

    <div class="header-right" v-click-outside="hidePopups">
      <HeaderLang/>
      <div class="header-notifications">
        <div class="header-notifications-icon" @click="togglePopupNotifications">
          <inline-svg :src="require('@/assets/svg/header-notifications.svg')"/>
          <span class="header-notifications-icon__count" v-show="unreadNotificationsCount">{{unreadNotificationsCount}}</span>
        </div>
        <popupNotifications
        :firstPageNotifications="firstPageNotifications"
        :notificationsCount="notificationsCount"
        :currentPage="currentPage"
        @loadNewNotifications="loadNewNotifications"
        @changeUnreadLength="changeUnreadLength"
         v-if="firstPageNotifications.length && showNotifications"/>
      </div>
      <div class="header-profile" @click="togglePopupMenu">
        <span class="header-profile__name">{{studentInfo.first_name}}</span>
        <div class="header-profile__avatar" :style="{backgroundImage: `url(${studentInfo.avatar})`}"/>
      </div>
      <popupMenu 
        v-if="showMenu"
        :name="`${studentInfo.first_name} ${studentInfo.last_name}`"
        :city="studentInfo.city"
        :age="studentInfo.age"/>
    </div>
  </header>
</template>

<script>
import apiNotifications from '@/api/notifications'
import ClickOutside from 'vue-click-outside'
import popupMenu from '@/components/Header/HeaderPopupMenu';
import popupNotifications from '@/components/Header/Notifications/HeaderPopupNotifications';
import Breadcrumbs from '@/components/Header/HeaderBreadcrumbs'
import HeaderLang from '@/components/Header/HeaderLang'

export default {
  name: 'header-component',
  directives: {
    ClickOutside
  },
  components: {
    popupMenu,
    popupNotifications,
    Breadcrumbs,
    HeaderLang
  },
  props: {
    paginatedTask: Number,
    breadcrumbs: {
      type: Array,
      required: false,
      default: () => []
    },
    teacherBreadcrumbs: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  data() {
    return {
      showMenu: false,
      showNotifications: false,
      currentPage: null,
      firstPageNotifications: [],
      unreadNotificationsCount: null,
      notificationsCount: null,
    }
  },
  computed: {
    studentInfo() {
      return this.$store.state.user.user.studentInfo
    }
  },
  methods: {
    goToStep(elementField) {
      this.$emit('goToStep', elementField)
    },
    changeUnreadLength(length) {
      this.unreadNotificationsCount = length
    },
    loadNewNotifications(currentPage) {
      this.currentPage = currentPage
    },
    togglePopupMenu() {
      this.showMenu = !this.showMenu;
      if (this.showNotifications) {
        this.showNotifications = false;
      }
    },
    hidePopups() {
      this.showMenu = false;
      this.showNotifications = false;
    },
    togglePopupNotifications() {
      this.showNotifications = !this.showNotifications;
      if (this.showMenu) {
        this.showMenu = false;
      }
    },
  },
  async mounted() {
    let startPage = 1
    let userId = this.$store.state.user.user.studentInfo.student_id
     const response = await apiNotifications.getNotifications(userId, startPage)
     this.notificationsCount = response.count
    let unReadNotificationsStorage = JSON.parse(localStorage.getItem('unReadNotifications'))
    let unreadNotifications = response.results.filter(el => !el.viewed)
    if (unReadNotificationsStorage) {
      this.unreadNotificationsCount = unReadNotificationsStorage.length
    } else {
       this.unreadNotificationsCount = unreadNotifications.length
    }
     this.firstPageNotifications = response.results
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  z-index: 10;
  background: #fff;
  border-bottom: 1px solid #d7d7d7;
  @media(max-width: 1023px) {
    padding: 0 16px;
  }
  &-left {
    display: flex;
    align-items: center;
  }
  &-right {
    display: flex;
    align-items: center;
    position: relative;
  }
  &-logo {
    margin-right: 32px;
  }
  &-notifications {
    margin-right: 16px;
    position: relative;
    &-icon {
      cursor: pointer;
      position: relative;
      &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        font-size: 9px;
        font-family: "InterSemiBold";
        background: #E55961;
        color: #fff;
        bottom: 0;
        right: -10px;
      }
    }
  }
  &-profile {
    display: flex;
    align-items: center;
    position: relative;
    &__avatar {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-size: cover;
      background-position: center;
      border: 2px solid #ACB2BE;
      cursor: pointer;
    }
    &__name {
      cursor: pointer;
      font-size: 14px;
      font-family: "JetBrainsMonoExtraBold";
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    &-profile {
      &__name {
        display: none;
      }
    }
  }
}
</style>