<template>
  <div class="delete-task">
    <span class="delete-task-title">
      {{$t('createTask.modal.deleteTask')}}
    </span>
    <div class="delete-task-buttons">
      <button  @click="deleteTask" class="btn btn--primary delete-task-button">{{$t('createTask.modal.buttons.delete')}}</button>
      <button @click="cancelDelete" class="btn btn--primary btn--outline delete-task-button">{{$t('createTask.modal.buttons.cancel')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    deleteTask() {
      this.$emit('deleteTask')
    },
    cancelDelete() {
      this.$emit('cancelDelete')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/var.scss";

.delete-task{
  &-title{
    font-family: "InterBold";
    display: block;
    font-size: 24px;
    margin-bottom: 24px;
  }
  &-buttons{
    display: flex;
  }
  &-button{
    width: 112px;
    &:first-child{
      background:$errorPrimary;
      margin-right: 16px;
    }
  }
}
</style>