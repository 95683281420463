<template>
  <div class="dropdown" v-click-outside="hideContent">
    <button class="btn btn-editor btn--small" ref="trigger" @click="toggleContent()">
      <slot name="trigger"></slot>
    </button>
    <div class="dropdown-content" v-if="showContent">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'Dropdown',
  data() {
    return {
      showContent: false
    }
  },
  methods: {
   toggleContent() {
     this.$emit('toggleContent')
       this.showContent = !this.showContent
   },
   hideContent() {
     this.$emit('toggleContent', true)
     if(this.showContent) this.showContent = false
   }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss">
  .btn-editor{
    background: #fff;
  }
  .dropdown {
      &-content {
          position: absolute;
          right: 0;
          bottom: 50px;
          border-radius: 8px;
          background: #f1f1f1;
      }
  }
</style>